import React from 'react'
import SALink from 'components/SALink'
import { PageLinkWrapper, AngleRightIcon, AngleLeftIcon } from './styles'
export type Props = {
  current: number
  total: number
  onClickLink: (...args: Array<any>) => any
  urlWithParam: (...args: Array<any>) => any
}
const initialMaxPagesToShow = 5
const maxPagesToShow = 3
const firstPage = 1
export default (props: Props) => {
  const { current, total, onClickLink, urlWithParam } = props

  if (!current || !total || total <= 1) {
    return null
  }

  const prevPage = current > 1 ? current - 1 : null
  const nextPage = current < total ? current + 1 : null
  let pagesStart = current - 1

  if (pagesStart < 1) {
    pagesStart = 1
  }

  if (pagesStart + (maxPagesToShow - 1) >= total) {
    pagesStart = total - maxPagesToShow + 1

    if (pagesStart < 1) {
      pagesStart = 1
    }
  }

  const pageRange = Array.from(
    Array(maxPagesToShow < total ? maxPagesToShow : total),
    (x, index) => index + pagesStart
  )
  const initialPageRange = Array.from(
    Array(initialMaxPagesToShow < total ? initialMaxPagesToShow : total),
    (x, index) => index + 1
  )
  return (
    <PageLinkWrapper data-section='pagination'>
      {/* Prev Link */}
      <SALink
        href={
          prevPage
            ? urlWithParam({
                page: prevPage === 1 ? 0 : prevPage,
              })
            : null
        }
        onClick={prevPage ? onClickLink : null}
        rel={prevPage ? 'follow' : 'nofollow'}
        data-value={prevPage}
        data-type={prevPage ? 'previous' : 'disable-previous'}
        aria-label='Pagination previous'
        title='Previous'
      >
        <AngleLeftIcon data-type={!prevPage ? 'no-previous' : ''} />
      </SALink>

      {/* when current selected page is <=4 show pages 1,2,3,4,5 */}
      {current <= 4 &&
        initialPageRange.map((thisPage) => (
          <SALink
            key={thisPage}
            active={thisPage === current ? 'true' : ''}
            tabIndex={0}
            role='button'
            href={
              thisPage !== current
                ? urlWithParam({
                    page: thisPage === 1 ? 0 : thisPage,
                  })
                : null
            }
            onClick={thisPage !== current ? onClickLink : null}
            data-value={thisPage}
            data-type={thisPage === current ? 'current-page' : 'not-current-page'}
            aria-label={`Pagination current ${thisPage}`}
            title={`Page ${thisPage}`}
          >
            {thisPage}
          </SALink>
        ))}

      {/* when current selected page is >= show pages 1 ... prev,current, next
         Ex: 1 ... 4,5,6 -> current selected page is 5
         Ex: 1 ... 7,8,9 -> current selected page is 8 */}
      {current >= 5 && (
        <>
          <SALink
            onClick={onClickLink}
            href={urlWithParam({
              page: 0,
            })}
            data-value={1}
            data-type={firstPage === current ? 'current-page' : 'not-current-page'}
            aria-label='Pagination first item'
            title={`Page ${firstPage}`}
          >
            {firstPage}
          </SALink>
          <div data-type='page-ellipses'> ... </div>
          {pageRange.map((thisPage) => (
            <SALink
              key={thisPage}
              active={thisPage === current ? 'true' : ''}
              tabIndex={0}
              role='button'
              href={
                thisPage !== current
                  ? urlWithParam({
                      page: thisPage === 1 ? 0 : thisPage,
                    })
                  : null
              }
              onClick={thisPage !== current ? onClickLink : null}
              data-value={thisPage}
              data-type={thisPage === current ? 'current-page' : 'not-current-page'}
              aria-label={`Pagination current ${thisPage}`}
              title={`Page ${thisPage}`}
            >
              {thisPage}
            </SALink>
          ))}
        </>
      )}

      {/* Next Link */}
      <SALink
        href={
          nextPage
            ? urlWithParam({
                page: nextPage,
              })
            : null
        }
        onClick={nextPage ? onClickLink : null}
        rel={nextPage ? 'follow' : 'nofollow'}
        data-value={nextPage}
        data-type={nextPage ? 'next' : 'disable-next'}
        aria-label='Pagination next'
        title='Next'
      >
        <AngleRightIcon data-type={!nextPage ? 'no-next' : ''} />
      </SALink>
    </PageLinkWrapper>
  )
}