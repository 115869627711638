import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as AppActions } from '../actions'
import { updateFacetValues, urlWithParams, generateLocationUrl } from '../helpers/url'
import { getParamsToGenerateURL } from '../selectors/facets'
import { getUrlPathString } from '../selectors/url'
const mapStateToProps = createStructuredSelector({
  urlWithParams: (state) => urlWithParams(state),
  updateFacetValues: (state) => updateFacetValues(state),
  getHref: (state) => {
    const { queryParams, pathParams, sharedFacetNames } = getParamsToGenerateURL(state)
    // We want a way to create pass in PARAMS and create UNIQUE url
    return (params): string => {
      return generateLocationUrl({
        queryParams: { ...queryParams, ...(params || {}) },
        // Params are OVERRIDES to create URL
        pathParams,
        sharedFacetNames,
      })
    }
  },
  urlPath: getUrlPathString,
})

const mapDispatchToProps = (dispatch) => ({
  updateUrl: (url: string) => dispatch(AppActions.UPDATE_URL(url)),
})

export type ConnectorProps = {
  getHref: (...args: Array<any>) => any
  urlWithParams: (...args: Array<any>) => any
  updateFacetValues: (...args: Array<any>) => any
  updateUrl: (...args: Array<any>) => any
  urlPath: string
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect