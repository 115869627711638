import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { getAlgoliaDataQueryId } from 'selectors/algolia2/data'

import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  hasInitialLoad: Selectors.getHasInitialLoad,
  hasValidPermissions: Selectors.getHasValidPermissions,
  queryID: getAlgoliaDataQueryId,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  hasInitialLoad: boolean
  hasValidPermissions: boolean
  queryID: string
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
