import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import SALink from 'components/SALink'
import { Column, Row } from 'styles/general'
// import { Row, Column } from 'styles/account'
import { Button } from 'styles/algolia/button'
import { Sold as SoldIcon } from 'styles/algolia/artwork/info'
import { FacetsLayout as FacetsLayoutBase } from 'styles/algolia/facets'
import { Header, ActionsLinks } from 'styles/algolia/collection/header'
import { ArtworkItem as AlgoliaArtworkItem } from 'styles/algolia/artwork'
import Plus from 'svg/add-solid.svg'
import Pencil from 'svg/pencil.svg'
import { space } from 'styled-system'
import { InputWithPlaceholder, Input as InputInPlaceholder } from 'components/Input/styles'
export const PlusIcon = styled(Plus)`
  @media (min-width: 992px) {
    margin: 10px 0 15px;
    font-size: 36px;
    height: 80px;
    width: 80px;
  }
  height: 60px;
  width: 60px;
  margin: 0 0 10px;
`
export const FacetsLayout = styled(FacetsLayoutBase)`
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    // CLS TEST
    display: block;
    //display: none;
    ${(props) => props.isVisible && `display: block;`}
  }
`
export const ArtworkItem = styled(AlgoliaArtworkItem)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
  }
`
export const ArtworkImage = styled.div`
  min-height: ${(props) => (props.minheight ? props.minheight : 185)}px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    background: ${(props) => props.theme.colors.borderLight};
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    min-height: 85px;
  }
`
export const ArtworkMainText = styled.h2`
  /*  18px => ${(props) => props.theme.fontSizes[4]}px; */
  font-size: 17px;
  color: ${(props) => props.theme.colors.default};
  margin: 5px 0 0;
  font-weight: normal;
`
export const ArtworkSubText = styled.h4`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.gray};
  margin: 2px 0 0;
  font-weight: normal;
  &[data-type='date'] {
    font-size: ${(props) => props.theme.fontSizes[1]}px;
    font-weight: 100;
    margin-top: -3px;
    text-align: right;
    width: 100%;
  }
  &[data-type='dimensions'] {
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      display: block;
    }
  }
`
export const ArtistMainText = styled.h3`
  /*  18px => ${(props) => props.theme.fontSizes[4]}px; */
  font-size: 17px;
  color: ${(props) => props.theme.colors.default};
  margin: 0;
  font-weight: normal;
`
export const ArtistSubText = styled.h4`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.gray};
  margin: 2px 0 0;
  font-weight: normal;
  &[data-type='date'] {
    font-size: ${(props) => props.theme.fontSizes[1]}px;
    font-weight: 100;
    margin-top: -3px;
    text-align: right;
    width: 100%;
  }
  &[data-type='artistCountry'] {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    &[data-type='artistCountry'] {
      display: block;
    }
  }
`
export const Sold = styled(SoldIcon)`
  margin-bottom: 5px;
`
export const PencilIcon = styled(Pencil)`
  position: absolute;
  fill: #333;
  left: 10px;
  top: 8px;
  :hover {
    fill: ${(props) => props.theme.colors.black};
  }
  width: 19px;
  height: 19px;
`
export const EditPencil = styled(SALink)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  height: 37px;
  padding: 15px 10px;
  width: 37px;
  position: relative;
`
export const ArtworkInfo = styled(Column)`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${(props) => props.theme.fontSizes[4]}px;
  height: 76px;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.default};
    transition: color 0.5s ease 0s;
    &:hover {
      color: ${(props) => props.theme.colors.black};
    }
  }
  h2, h3, h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
  ${Row} {
    ${Column} {
      width: 70%;
      + ${Column} {
        width: 40%;
      }
    }
  }
  [data-type='prices'] {
    p {
      margin: 1px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    ${Row} ${Column} {
      margin-bottom: ${(props) => props.theme.space[2]}px;
    }
    [data-display='short'] {
      width: 86%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='mainInfo'] {
      h2, h3,
      a {
        color: ${(props) => props.theme.colors.default};
        font-size: ${(props) => props.theme.fontSizes[3]}px;
      }
    }
    ${ArtworkSubText} {
      display: none;
    }
    [data-type='prices'] {
      ${ArtworkSubText} {
        display: flex;
      }
      a {
        display: none;
      }
      p {
        margin: 2px;
      }
    }
    p,
    a {
      color: ${(props) => props.theme.colors.lightGray};
      font-size: ${(props) => props.theme.fontSizes[2]}px;
    }
    ${Row} {
      margin: 0 0 5px;
    }
    ${Row} + ${Row} {
      align-items: center;
      flex-direction: column;
      ${Column}, ${Column} + ${Column} {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        &[data-type='prices'] {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
    ${Sold} {
      position: absolute;
      bottom: 150px;
      right: 5px;
    }
  }
  [data-type='account-art-info-row'] {
    [data-type='artwork-price'] {
      @media (min-width: 1024px) {
        min-height: 18px;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
    }
    [data-type='prints'] {
      p {
        margin: 1px;
      }
      @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
        p {
        margin: 1px;
        width: 100%;
        padding-left: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        }
      }
    }
  }
  [data-type='artistInfo'], [data-type='prices'] {
    @media (max-width: 992px) {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  [data-type='artistInfo'] {
    width: 65%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`
export const Layout = styled(Column)`
  //max-width: 1600px;
  padding: 0;
  margin: 0 auto;
  [data-type='artwork-info'] {
    [data-type='main'] {
      max-width: 250px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 0;
  }
  [data-type='info'] {
    [data-type='view-more'] {
      display: none;
    }
  }
  ${Header} {
    justify-content: center;
    align-items: center;
    border-bottom: none;
    h1,
    p {
      width: 100%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      h1 {
        font-size: 26px;
        border-bottom: none;
      }
    }
    ${Row} {
      align-items: center;
      justify-content: space-between;
      @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        flex-direction: column;
        ${ActionsLinks} {
          border-top: 1px solid ${(props) => props.theme.colors.borderLight};
          padding: 10px;
        }
        ${Button} {
          margin: 10px 0;
        }
      }
    }
    ${Button},
    button[data-type="filter"] {
      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        display: flex;
        max-width: 146px;
      }
    }
  }
  ${ArtworkInfo} {
    margin-top: 10px;
  }
`
export const FacetLink = styled(SALink)`
  cursor: pointer;
  margin: 0 0 5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.default};
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  /* Checkbox */
  label {
      outline: none;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 5px 0;
    }
    input + span + p {
      margin: 0 0 0 10px;
    }
  }
  &:focus {
    outline: none;
  }
  ${(props) => props.isHidden && `display: none`};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    &[data-type='country-link'] {
      &:nth-child(even) {
        padding-right: 8px;
      }
      &:nth-child(odd) {
        padding-left: 8px;
      }
    }
  }
`
const Link = styled(SALink)`
  color: ${(props) => props.theme.colors.lightGray};
  padding: 10px 0;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  will-change: color;
  cursor: pointer;
  font-style: normal;
  transition: color 0.2s ease 0s;
  text-decoration: underline;
  ${space};
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`
export const ShowLink = Link
export const ClearAllFilters = styled.button`
  border: none;
  justify-content: flex-start;
  padding: 0;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 12px;
  white-space: nowrap;
  background: transparent;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    &[data-section='mobile-filters'] {
      height: 50px;
      margin: auto;
      background: #fff;
      border: 1px solid #333;
      width: 310px;
      text-transform: none;
      font-weight: normal;
      text-decoration: none;
      font-size: 16px;
      margin-left: 15px;
    }
  }
`
export const Field = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  ${InputWithPlaceholder} {
  }
  ${InputInPlaceholder} {
  }
`
export const Input = styled.input`
  width: 85px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: ${(props) => `${props.theme.space[2]}px ${props.theme.space[2]}px`};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.borderBlue};
    ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  }
`
export const SizeInputs = styled(Column)`
  margin-top: 15px;
  width: 230px;
  ${InputWithPlaceholder} {
    width: 85px;
    display: inline-block;
    margin-right: 5px;
    input {
      width: 85px;
      height: 50px;
    }
    + ${InputWithPlaceholder} {
      margin-left: 5px;
    }
  }
  ${Field} {
    margin-bottom: 10px;
  }
`
export { Column }
