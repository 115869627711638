// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import P from 'components/Serif'
import { Row } from 'styles/account'
export const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.colors.default};
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: 24px;
  }
`
export const HeaderDescription = styled(P)`
  font-size: 17px;
  margin: 20px 0;
`
export const HeaderActions = styled(Row)`
  margin: 0;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
  [data-type='visii'] {
    width: 160px;
    font-size: ${(props) => props.theme.fontSizes[2]}px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      flex-grow: 1;
      font-size: ${(props) => props.theme.fontSizes[1]}px;
    }
  }
  [data-type='filter'] {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: row-reverse;
    [data-type='filter'] {
      display: flex;
      margin-left: 0;
      font-size: ${(props) => props.theme.fontSizes[1]}px;
    }
  }
`
export const Header = styled(Row)`
  align-items: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  ${HeaderTitle} {
    width: 50%;
    flex-grow: 1;
  }
  ${HeaderActions} {
    max-width: 500px;
    flex-shrink: 1;
  }
  [data-app='curation'] & {
    margin: 15px 0;
    padding-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border: none;
    flex-direction: column;
    align-items: flex-start;
    ${HeaderTitle},
    ${HeaderActions} {
      flex-grow: 1;
      width: 100%;
      max-width: initial;
    }
    ${HeaderTitle} {
      padding-bottom: 10px;
      margin: 15px 0 10px;
      border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    [data-type='filter'] {
      padding: 10px 5px;
      min-width: 80px;
      flex-grow: 1;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`