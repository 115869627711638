// CONSTANTS
export const Constants = {
  ALGOLIA_EVENT: 'FIRE_ALGOLIA_EVENT',
  CLIENTSIDE_TRANSITION: 'CLIENTSIDE_TRANSITION_ACCOUNT_ARTWORKS',
  FILTER_EVENT: 'FIRE_FILTER_EVENT',
  UPDATE_ALGOLIA: 'UPDATE_ALGOLIA_ACCOUNT_ARTWORKS',
  UPDATE_HITS_PER_PAGE: 'UPDATE_HITS_PER_PAGE_ACCOUNT_ARTWORKS',
  UPDATE_PAGE: 'UPDATE_PAGE_ACCOUNT_ARTWORKS',
  UPDATE_URL: 'UPDATE_URL_ACCOUNT_ARTWORKS',
  UPDATE_FACET_SELECTION: 'UPDATE_FACET_SELECTION_ACCOUNT_ARTWORKS',
}
// ACTION TYPES
export type FlowTypes =
  | {
      type: typeof Constants.ALGOLIA_EVENT
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.CLIENTSIDE_TRANSITION
      payload: string
    }
  | {
      type: typeof Constants.FILTER_EVENT
    }
  | {
      type: typeof Constants.UPDATE_ALGOLIA
      payload?: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_HITS_PER_PAGE
      payload: number
    }
  | {
      type: typeof Constants.UPDATE_PAGE
      payload: number
    }
  | {
      type: typeof Constants.UPDATE_URL
      payload: string
    }
  | {
      type: typeof Constants.UPDATE_FACET_SELECTION
      payload: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  ALGOLIA_EVENT: (payload: Record<string, any>): FlowTypes => ({
    type: Constants.ALGOLIA_EVENT,
    payload,
  }),
  CLIENTSIDE_TRANSITION: (payload: string): FlowTypes => ({
    type: Constants.CLIENTSIDE_TRANSITION,
    payload,
  }),
  FILTER_EVENT: (): FlowTypes => ({
    type: Constants.FILTER_EVENT,
  }),
  UPDATE_ALGOLIA: (payload?: Record<string, any>): FlowTypes => ({
    type: Constants.UPDATE_ALGOLIA,
    payload,
  }),
  UPDATE_HITS_PER_PAGE: (payload: number): FlowTypes => ({
    type: Constants.UPDATE_HITS_PER_PAGE,
    payload,
  }),
  UPDATE_PAGE: (payload: number): FlowTypes => ({
    type: Constants.UPDATE_PAGE,
    payload,
  }),
  UPDATE_URL: (payload?: string): FlowTypes => ({
    type: Constants.UPDATE_URL,
    payload,
  }),
  UPDATE_FACET_SELECTION: (payload: Record<string, any>): FlowTypes => ({
    type: Constants.UPDATE_FACET_SELECTION,
    payload,
  }),
}