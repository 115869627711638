import type { ActionTypes as ABTestActionTypes } from './abTest'
import { Constants as ABTestConstants, Actions as ABTestActions } from './abTest'
import type { ActionTypes as AccountActionTypes } from './account'
import { Constants as AccountConstants, Actions as AccountActions } from './account'
import type { ActionTypes as CartActionTypes } from './cart'
import { Constants as CartConstants, Actions as CartActions } from './cart'
import type { ActionTypes as CookieActionTypes } from './cookie'
import { Constants as CookieConstants, Actions as CookieActions } from './cookie'
import type { ActionTypes as CollectionActionTypes } from './collection'
import { Constants as CollectionConstants, Actions as CollectionActions } from './collection'
import type { ActionTypes as FavoriteActionTypes } from './favorite'
import { Constants as FavoriteConstants, Actions as FavoriteActions } from './favorite'
import type { ActionTypes as FollowActionTypes } from './follow'
import { Constants as FollowConstants, Actions as FollowActions } from './follow'
import type { ActionTypes as SessionActionTypes } from './session'
import { Constants as SessionConstants, Actions as SessionActions } from './session'
// CONSTANTS
export const Constants = {
  ...ABTestConstants,
  ...AccountConstants,
  ...CartConstants,
  ...CookieConstants,
  ...CollectionConstants,
  ...FavoriteConstants,
  ...FollowConstants,
  ...SessionConstants,
}
// ACTION TYPES
export type ActionTypes =
  | ABTestActionTypes
  | AccountActionTypes
  | CartActionTypes
  | CookieActionTypes
  | CollectionActionTypes
  | FavoriteActionTypes
  | FollowActionTypes
  | SessionActionTypes
// ACTION CALLS
export const Actions = {
  ...ABTestActions,
  ...AccountActions,
  ...CartActions,
  ...CookieActions,
  ...CollectionActions,
  ...FavoriteActions,
  ...FollowActions,
  ...SessionActions,
}