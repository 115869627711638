import { useState, useLayoutEffect } from 'react';

const MOBILE_MAX_WIDTH = 999;

interface UseScreenSize {
  isMobile: boolean;
}

const useScreenSize = (): UseScreenSize => {
  const [isMobile, setIsMobile] = useState<boolean>(typeof window !== 'undefined' ? window.innerWidth <= MOBILE_MAX_WIDTH : false);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return { isMobile };
};

export default useScreenSize;