import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { space } from 'styled-system'
export const InputWithPlaceholder = styled.div`
  ${space};
  position: relative;
  label {
    position: absolute;
    left: ${(props) => props.theme.space[2]}px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: top left;
    transition: 0.1s ease-in-out transform;
    width: 100%;
    color: ${(props) => props.theme.colors.secondary};
    cursor: text;
    pointer-events: none;
  }
  input {
    width: 100%;
    height: 50px;
  }
  ${(prop) =>
    prop.hasValue &&
    `
    label {
      transform: translateY(-100%) scale(0.8);
    }
    input {
      padding-top: 23px;
      padding-bottom: 7px;
    }
  `};

  ${(prop) => prop.styles};
`
export const Input = styled.input`
  height: 50px;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: ${(props) => props.theme.space[2]}px;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.borderBlue};
    ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  }
`