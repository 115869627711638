/*
 * Looking for a better way to handle Data tracking for Algolia
 */
// CONSTANTS
export const Constants = {
  FETCH_DATA: 'FETCH_DATA_ALGOLIA',
  INITIAL_SETUP: 'INITIAL_SETUP_ALGOLIA',
  LOAD_MORE_DATA: 'LOAD_MORE_DATA_ALGOLIA',
  MERGE_HITS_RESULTS: 'MERGE_HITS_RESULTS_ALGOLIA',
  SET_DATA: 'SET_DATA_ALGOLIA',
  SET_FACETS: 'SET_FACETS_ALGOLIA',
  SET_HITS_PER_PAGE: 'SET_HITS_PER_PAGE_ALGOLIA',
  SET_SEARCH_INDICE: 'SET_SEARCH_INDICE_ALGOLIA',
  SET_SELECTED_FACETS: 'SET_SELECTED_FACETS_ALGOLIA',
  SET_SLUGIFY_DICTIONARY: 'SET_SLUGIFY_DICTIONARY_ALGOLIA',
  UPDATE: 'UPDATE_ALGOLIA',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.FETCH_DATA
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.INITIAL_SETUP
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.LOAD_MORE_DATA
    }
  | {
      type: typeof Constants.MERGE_HITS_RESULTS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_DATA
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_FACETS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_HITS_PER_PAGE
      payload: number
    }
  | {
      type: typeof Constants.SET_SEARCH_INDICE
      payload: string
    }
  | {
      type: typeof Constants.SET_SELECTED_FACETS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_SLUGIFY_DICTIONARY
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE
      payload: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  FETCH_DATA: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.FETCH_DATA,
    payload,
  }),
  INITIAL_SETUP: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.INITIAL_SETUP,
    payload,
  }),
  LOAD_MORE_DATA: (): ActionTypes => ({
    type: Constants.LOAD_MORE_DATA,
  }),
  MERGE_HITS_RESULTS: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.MERGE_HITS_RESULTS,
    payload,
  }),
  SET_DATA: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_DATA,
    payload,
  }),
  SET_FACETS: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_FACETS,
    payload,
  }),
  SET_HITS_PER_PAGE: (payload: number): ActionTypes => ({
    type: Constants.SET_HITS_PER_PAGE,
    payload,
  }),
  SET_SEARCH_INDICE: (payload: string): ActionTypes => ({
    type: Constants.SET_SEARCH_INDICE,
    payload,
  }),
  SET_SELECTED_FACETS: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_SELECTED_FACETS,
    payload,
  }),
  SET_SLUGIFY_DICTIONARY: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_SLUGIFY_DICTIONARY,
    payload,
  }),
  UPDATE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE,
    payload,
  }),
}