import React from 'react'
import CollectionTooltip from 'components/Collection/V2/Tooltip'
import type { ConnectorProps as AuthenticationProps } from 'connectors/Authentication'
import AuthenticationConnect from 'connectors/Authentication'
import { ActionItem } from './styles'
type Props = {
  artworkId: number
} & AuthenticationProps

const AddToCollectionAction = (
  props: Props
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { artworkId } = props
  return (
    <ActionItem data-action='collection' data-type='action' title='Add to Collection'>
      <CollectionTooltip artworkId={artworkId} />
    </ActionItem>
  )
}

export default AuthenticationConnect(AddToCollectionAction)