import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { Row } from './account'
export const Breadcrumbs = styled(Row)`
  justify-content: flex-start;
  padding: ${(props) => props.theme.space[1]}px 0 ${(props) => props.theme.space[3]}px;
  margin: ${(props) => props.theme.space[2]}px 0;
  a,
  span {
    font-size: 13px;
    font-weight: 700;
    padding: ${(props) => props.theme.space[1]}px 0 ${(props) => props.theme.space[1]}px
      ${(props) => props.theme.space[1]}px;
    [data-sitecontext='saatchiart'] & {
      font-weight: normal;
    }
  }
  a {
    color: #666666;
    text-decoration: none;
    [data-sitecontext='limited'] & {
      color: ${(props) => props.theme.colors.gray};
    }
    :hover {
      color: #999999;
    }
    + a::before {
      margin-right: ${(props) => props.theme.space[1]}px;
      content: '/';
      display: inline-block;
      [data-sitecontext='saatchiart'] & {
        color: ${(props) => props.theme.colors.default};
      }
    }
    &:first-child {
      padding: ${(props) => props.theme.space[1]}px 0;
    }
  }
  span {
    color: #666666;
  }
`