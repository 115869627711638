import React from 'react'
import { Button } from 'styles/algolia/button'
import { FilterIcon } from 'styles/algolia/icons'
import type { ConnectorProps as ClearFiltersProps } from '../../connectors/ClearFilters'
import ClearFiltersConnector from '../../connectors/ClearFilters'
export default ClearFiltersConnector(
  (props: ClearFiltersProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { updateUrl, urlPath, facetCount } = props
    return (
      <Button
        data-type='clearAll'
        onClick={(ev) => {
          ev.preventDefault()
          updateUrl(urlPath)
        }}
      >
        <FilterIcon />
        Clear All Filters <span>({facetCount || 0})</span>
      </Button>
    )
  }
)