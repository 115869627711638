import React, { useState } from 'react'
import { ShowLink, NoOptions } from 'styles/algolia/facets'
import type { ConnectorProps as FacetListProps } from '../../connectors/FacetList'
import FacetListConnector from '../../connectors/FacetList'
import CheckboxItem from './CheckboxItem'
const maxShowItems = 10
type Props = FacetListProps & {
  facetName: string
  overrideRules?: Record<string, string>
  // Rewrite Label of Facet - AttributeName
  show?: boolean
  // Always Show AttributeName(s)
  orderRules?: Array<string> // Sort/Show AttributeName(s) in ORDER
}
export default FacetListConnector((props: Props): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { facetName, facetNameAttributesList, orderRules, overrideRules, show } = props
  const [isOpen, toggleFacet] = useState(false)

  if (!facetNameAttributesList.length && !show) {
    return <NoOptions>No Options Available</NoOptions>
  }

  return (
    <>
      {(orderRules || facetNameAttributesList).map((attributeName, index) => (
        <CheckboxItem
          {...{
            facetName,
            attributeName,
          }}
          key={`${facetName}-${attributeName}-${index}`}
          isHidden={!isOpen && index >= maxShowItems}
          title={overrideRules ? overrideRules[attributeName] : ''}
        />
      ))}

      {facetNameAttributesList.length > maxShowItems && (
        <ShowLink onClick={() => toggleFacet(!isOpen)}>Show {isOpen ? 'Less' : 'More'}</ShowLink>
      )}
    </>
  )
})