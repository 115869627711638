import React from 'react'

const Component = ({
  originalStatus,
}: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> | null => {
  if (originalStatus !== 'reserved' && originalStatus !== 'sold') {
    return null
  }

  return (
    <div data-style='column' data-type='status'>
      {originalStatus === 'sold' && <div data-status='sold'>Sold</div>}
    </div>
  )
}

export default Component