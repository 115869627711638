import React from 'react'
import { ActionsLinks, ActionLink } from 'styles/algolia/user/header'
import type { ConnectorProps as ActionsProps } from '../../connectors/Actions'
import ActionsConnector from '../../connectors/Actions'
type Props = ActionsProps

const AdminLinks = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { isUsersArtworksAccount } = props

  if (!isUsersArtworksAccount) {
    return null
  }

  // Admin OR Owner of Collection can view and edit collection
  return (
    <ActionsLinks data-type='artworksv2'>
      {isUsersArtworksAccount && (
        <>
          <ActionLink href='/studio' title='manage artworks'>
            Manage Artworks
          </ActionLink>

          <span>|</span>

          <ActionLink href='/studio/upload' title='upload your artwork'>
            Upload Art
          </ActionLink>
        </>
      )}
    </ActionsLinks>
  )
}

export default ActionsConnector(AdminLinks)