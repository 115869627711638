import React from 'react'
import SALink from 'components/SALink'
import { Dimensions } from 'components/MeasurementUnit'
import { getCleanCategory } from 'components/Artworks/Info/helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    shouldOpenNewDesktopPdpTab,
    artworkTitle,
    category,
    depth,
    handleViewProduct,
    hasPrints,
    height,
    isSeoValid,
    originalStatus,
    printUrl,
    productUrl,
    width,
  } = props
  // We need a singular category to display in hover
  const cleanCategory = getCleanCategory(category)
  return (
    <div
      data-style='column'
      sold={originalStatus === 'sold' ? true : null}
      data-type='artwork-info'
    >
      <div data-type='artwork-details'>
        <div data-type='main'>
          <SALink
            alt={`${artworkTitle}, ${category}`}
            href={hasPrints ? printUrl : productUrl}
            onClick={handleViewProduct}
            rel={isSeoValid ? null : 'nofollow'}
            title={`View ${artworkTitle} ${cleanCategory}`}
            target={shouldOpenNewDesktopPdpTab ? '_blank' : ''}
          >
            <SATypography truncateText variant={SATypographyVariant.H6}>
              {artworkTitle}
            </SATypography>
          </SALink>
        </div>
        <div data-type='sub'>
          <SATypography truncateText variant={SATypographyVariant.X_SMALL}>
            {category.replace(/-/g, ' ')}
          </SATypography>
          {!hasPrints && (
            <>
              <SATypography truncateText variant={SATypographyVariant.X_SMALL}>
                {`, `}
                <Dimensions dimensions={{ width, height, depth }} />
              </SATypography>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Component
