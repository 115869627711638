import React from 'react'
import FacetSection from './FacetSection'
import List from './List'
const facetName = 'orientation'
const facetTitle = 'Orientation'
// Override Rules
const overrideRules = {
  landscape: 'Horizontal',
  portrait: 'Vertical',
  square: 'Square',
}
// Order Rules
const orderRules = ['landscape', 'portrait', 'square']

const Component = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <FacetSection
    {...{
      facetName,
      facetTitle,
    }}
    showFacet
  >
    <List
      {...{
        facetName,
        overrideRules,
        orderRules,
        show: true,
      }}
    />
  </FacetSection>
)

export default Component