import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
import { Actions as AppActions } from '../actions'
const mapStateToProps = createStructuredSelector({
  facetCount: Selectors.getSelectedFacetsCount,
  urlPath: Selectors.getUrlPathString,
})

const mapDispatchToProps = (dispatch) => ({
  updateUrl: (url: string) => dispatch(AppActions.UPDATE_URL(url)),
})

export type ConnectorProps = {
  facetCount: number
  updateUrl: (...args: Array<any>) => any
  urlPath: string
}
export default connect(mapStateToProps, mapDispatchToProps)