import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  facetNameAvailable: (state, ownProps) =>
    Selectors.isFacetNameAvailable(ownProps.facetName)(state),
  isSelected: (state, ownProps) => Selectors.isSelectedFacetName(ownProps || {})(state),
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  facetNameAvailable: boolean
  isSelected: boolean
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect