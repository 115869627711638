import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import SALink from 'components/SALink'
import { Row } from 'styles/account'
import { Header as HeaderStyle, HeaderTitle } from 'styles/algolia/header'
import { Breadcrumbs } from 'styles/breadcrumbs'
export { Row }

const MOBILE_WIDTH = '999'

export const ActionLink = styled(SALink)`
  cursor: pointer;
`
export const ActionsLinks = styled(Breadcrumbs)`
  justify-content: flex-end;
  padding-bottom: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: inline-block;
  }
`
export const HeaderActions = styled(Row)`
  margin: 0;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
  [data-type='visii'] {
    flex-grow: 1;
  }
  [data-type='filter'] {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: row-reverse;
    [data-type='filter'] {
      display: flex;
    }
  }
`
export const OwnerInfoSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  margin-right: 50px;
  [data-type='collection-info'] {
    display: flex;
    align-items: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
export const OwnerAvatar = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.space[2]}px;
`
export const OwnerInfoLine = styled.div`
  color: ${(props) => (props.isLimited ? props.theme.colors.gray : props.theme.colors.default)};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  ${(props) =>
    props.titleLine &&
    `
    &::before {
      margin: 0 ${props.theme.space[1]}px;
      content: '${props.theme.linkSeparator}';
      display: inline-block;

      @media (max-width: ${MOBILE_WIDTH}px) {
        content: '';
        margin: 0;
      }
    }
  `}
  a {
    color: ${(props) => (props.isLimited ? props.theme.colors.default : props.theme.colors.red)};
    text-decoration: none;
  }
`
export const Header = styled(HeaderStyle)`
  flex-direction: column;
  padding-bottom: 20px;
  align-items: flex-start;
  ${HeaderActions} {
    max-width: 500px;
    flex-shrink: 1;
  }
  [data-type='filter'] {
    display: none;
  }
  ${HeaderTitle} {
    font-size: 46px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border: none;
    flex-direction: column;
    align-items: flex-start;
    ${HeaderTitle},
    ${HeaderActions} {
      flex-grow: 1;
      width: 100%;
      max-width: initial;
    }
    [data-type='filter'] {
      display: flex;
      width: 100%;
    }
    > ${Row} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  [data-type='header-owner-container'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 24px 0px;
    @media (max-width: ${MOBILE_WIDTH}px) {
      width: 100%;
      align-items: flex-start;
    }
  }
`