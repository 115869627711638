import React, { useEffect, useReducer } from 'react'
import MeasureDimensions from './MeasureDimensions'
import { Collapsable } from './styles'
type Props = {
  animateIn?: boolean;
  children:
    | Array<React.ReactElement<React.ComponentProps<any>, any>>
    | React.ReactElement<React.ComponentProps<any>, any>;
  duration?: string;
  open: boolean;
  styles?: Record<string, string>;
}
type State = {
  duration: string
  height: number | null | undefined
  hidden: boolean
  open: boolean
  ready: boolean
}
const initialState: State = {
  duration: '',
  height: null,
  hidden: false,
  open: false,
  ready: false,
}

const reducer = (state: State, action: Record<string, any>) => {
  switch (action.type) {
    default:
      return { ...state, ...action }
  }
}

const timerDuration = 250
export default (props: Props) => {
  const { animateIn, children, duration, open: isOpen, styles } = props
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ready: !animateIn && isOpen,
    ...{
      duration,
      hidden: animateIn,
      open: isOpen,
    },
  })
  const { hidden, open } = state

  if (open !== isOpen) {
    dispatch({
      open: isOpen,
    })
  }

  useEffect(() => {
    if (hidden) {
      setTimeout(() => {
        dispatch({
          hidden: false,
          open: false,
        })
        setTimeout(() => {
          dispatch({
            open: true,
          })
        }, timerDuration)
      }, timerDuration)
    }

    // Specify how to clean up after this effect:
    return () => {}
  }, [])
  return (
    <Collapsable {...state} className='Collapsable'>
      <MeasureDimensions
        updateDimensions={(update) => {
          // TODO - Fix CLS on animation
          dispatch({
            ready: true,
            ...update,
          })
        }}
        styles={styles}
      >
        {children}
      </MeasureDimensions>
    </Collapsable>
  )
}