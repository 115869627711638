import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { isLimitedContext } from 'selectors/siteContext'
import { Actions as AlgoliaActions } from 'actions/algolia'
import {
  getFavoriteItems,
  getFavoriteMessage,
  isFavorited,
  getFavoriteSelected,
} from 'selectors/user/favorite'
import type { MessagePayload, FavoriteItems } from 'types/User/Favorite'
import { Actions as CollectionActions } from 'actions/user/collection'
import { Actions as FavoriteActions } from 'actions/user/favorite'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { getIsMobileScreenWidth, getScreenIsReady } from 'selectors/screen'
import { getIsMobileTraffic } from 'selectors/shared/mobileTraffic'
import { getIsUsersArtworksAccount } from '../selectors/app'
import { getArtworkProductUrl } from '../selectors'
const mapStateToProps = createStructuredSelector({
  artworkProductUrl: (store, ownProps) =>
    getArtworkProductUrl(ownProps.artwork ? ownProps.artwork.artId : 0)(store),
  isLimited: isLimitedContext,
  isUsersArtworksAccount: getIsUsersArtworksAccount,
  isFavorited: (state, ownProps) => isFavorited(ownProps.artId)(state),
  favorites: getFavoriteItems,
  favoriteMessage: getFavoriteMessage,
  isMobileTraffic: getIsMobileTraffic,
  isMobileScreen: getIsMobileScreenWidth,
  isReady: getScreenIsReady,
  selectedFavorite: getFavoriteSelected,
})

const mapDispatchToProps = (dispatch) => ({
  algoliaEvent: (payload: Record<string, any>) => dispatch(AlgoliaActions.EVENT(payload)),
  favoriteItem: (favorite: { id: number; favorite: boolean }) => {
    dispatch(FavoriteActions.UPDATE(favorite))
  },
  setCurrentArtwork: (id: number) => {
    dispatch(CollectionActions.SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK(id))
  },
  updateDataLayer: (payload: Record<string, any>) =>
    dispatch(DataLayerActions.UPDATE_DATALAYER(payload)),
})

export type ConnectorProps = {
  algoliaEvent: (...args: Array<any>) => any
  artworkProductUrl: string
  isLimited: boolean
  isUsersArtworksAccount: boolean
  favoriteItem: (...args: Array<any>) => any
  favoriteMessage: MessagePayload
  favorites: FavoriteItems
  isFavorited: boolean
  setCurrentArtwork: (...args: Array<any>) => any
  updateDataLayer: (...args: Array<any>) => any
  isMobileTraffic: boolean
  isMobileScreen: boolean
  isReady: boolean
  selectedFavorite: number
}
export default connect(mapStateToProps, mapDispatchToProps)