import {
  observerEpicHelper,
  checkAuthorization,
  dispatchFlashMessage,
  validateLoginStatus,
} from 'epics/helpers'
import {
  // createNewCollection,
  deleteArtworkInMyCollection,
  fetchMyCollections,
  fetchMyCollectionsForArtwork,
  putArtworkInMyCollection,
  postMyCollectionsCreate,
} from 'apis/collection'
import { Actions as AlgoliaActions } from 'actions/algolia'
import { Constants, Actions as CollectionActions } from 'actions/user/collection'
import { Actions as FormActions } from 'actions/shared/form'
import { Actions as ModalActions } from 'actions/shared/modal'
import {
  getCollectionTooltipCurrentArtwork,
  getCollectionTooltipItems,
  getCollectionTooltipSelectedItems,
  getCollectionTooltipSelectedItemsExist,
} from 'selectors/user/collection'
// import { transformNewCollectionPayload } from 'components/Collection/Form/helpers'
export const addArtworkToCollectionEpic = observerEpicHelper(
  [Constants.ADD_ARTWORK_TO_COLLECTION],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      const userCollectionId = action.payload
      const artworkId = getCollectionTooltipCurrentArtwork({ ...store.value })
      const remove = getCollectionTooltipSelectedItemsExist(userCollectionId)({ ...store.value })
      // We want to keep ORIGINAL State so if something fails we can roll back
      const artworkCollections = getCollectionTooltipSelectedItems({ ...store.value })
      // Before we make api call we need to add a LOADER attribute/create temp value
      observer.next(
        CollectionActions.SET_COLLECTIONS_BY_ARTWORKID({
          [artworkId]: [
            ...artworkCollections
              .filter((item) => item.userCollectionId !== userCollectionId)
              .filter(Boolean),
            {
              userCollectionId,
              isUpdating: true,
            },
          ],
        })
      )
      // Use new easel_api endpoints
      const apiResponse = remove
        ? await deleteArtworkInMyCollection({
            artworkId,
            userCollectionId,
          })
        : await putArtworkInMyCollection({
            artworkId,
            userCollectionId,
          })

      if (!apiResponse.success) {
        validateLoginStatus(apiResponse)
        dispatchFlashMessage({
          observer,
          message: `Problem ${remove ? 'removing' : 'adding'} artwork to collection`,
          type: 'error',
        })
        // Rollback to Original State
        observer.next(
          CollectionActions.SET_COLLECTIONS_BY_ARTWORKID({
            [artworkId]: artworkCollections,
          })
        )
      } else {
        dispatchFlashMessage({
          observer,
          message: `Artwork has been  ${remove ? 'removed from' : 'added to'} collection`,
          type: 'success',
        })
        observer.next(
          AlgoliaActions.EVENT({
            eventName: remove ? 'remove from collection' : 'added to collection',
            eventType: 'view',
            objectIDs: [artworkId],
          })
        )
        window.dataLayer.push({
          event: remove ? 'removed from collection' : 'added to collection',
        })
        // If success, we will update the Artwork items data manually, so REMOVE || ADD obect
        const updatedCollections = remove
          ? artworkCollections
              .filter((item) => item.userCollectionId !== userCollectionId)
              .filter(Boolean)
          : [
              ...artworkCollections,
              {
                userCollectionId,
              },
            ]
        observer.next(
          CollectionActions.SET_COLLECTIONS_BY_ARTWORKID({
            [artworkId]: updatedCollections,
          })
        )
      }
    }

    observer.complete()
  }
)
export const fetchCollectionTooltipEpic = observerEpicHelper(
  [Constants.FETCH_COLLECTION_TOOLTIP],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      const { success, payload, loginRequired } = await fetchMyCollections()

      if (!success) {
        validateLoginStatus({
          loginRequired,
        })
        dispatchFlashMessage({
          observer,
          message: 'Problem fetching collections',
          type: 'error',
        })
      } else {
        observer.next(
          CollectionActions.SET_COLLECTION_TOOLTIP({
            items: payload,
            isLoaded: true,
          })
        )
      }

      observer.complete()
    }
  }
)
export const fetchCollectionsByArtworkIdTooltipEpic = observerEpicHelper(
  [Constants.FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      const currentArtwork = getCollectionTooltipCurrentArtwork({ ...store.value })
      // expecting payload of `artworkId` => number
      const { success, payload, loginRequired } = await fetchMyCollectionsForArtwork(currentArtwork)

      if (!success) {
        validateLoginStatus({
          loginRequired,
        })
        observer.next(
          CollectionActions.SET_COLLECTIONS_BY_ARTWORKID({
            [currentArtwork]: [],
          })
        )
        dispatchFlashMessage({
          observer,
          message: 'Problem fetching collections for artwork',
          type: 'error',
        })
      } else {
        observer.next(
          CollectionActions.SET_COLLECTIONS_BY_ARTWORKID({
            [currentArtwork]: payload,
          })
        )
      }

      observer.complete()
    }
  }
)
export const createCollectionEpic = observerEpicHelper(
  [Constants.CREATE_COLLECTION],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      const formData = action.payload
      observer.next(FormActions.IS_SUBMITTING(true))
      const { payload: response } = await postMyCollectionsCreate(formData)
      // The AA Collection and regular collection endpoints send back different payloads.
      const userCollectionId = response.collection_id
        ? response.collection_id
        : response.data.id_user_collection

      if (!userCollectionId) {
        dispatchFlashMessage({
          observer,
          message: 'Problem creating collection',
          type: 'error',
        })
        observer.next(FormActions.IS_SUBMITTING(false))
      } else {
        const tooltipItems = getCollectionTooltipItems(state)
        const payload = [
          {
            userId: 0,
            userCollectionId,
            title: formData.title,
            image: '',
          },
          ...tooltipItems,
        ]
        observer.next(
          CollectionActions.SET_COLLECTION_TOOLTIP({
            items: payload,
          })
        )
        observer.next(FormActions.IS_SUBMITTING(false))
        observer.next(ModalActions.CLOSE_MODAL())
        dispatchFlashMessage({
          observer,
          message: 'New Collection Created. Browse art to add to your collection.',
          type: 'success',
        })
        // We need to Add/Mark ArtworkId to new collection
        observer.next(CollectionActions.ADD_ARTWORK_TO_COLLECTION(userCollectionId))
      }

      observer.complete()
    }
  }
)
export default [
  addArtworkToCollectionEpic,
  createCollectionEpic,
  fetchCollectionTooltipEpic,
  fetchCollectionsByArtworkIdTooltipEpic,
]