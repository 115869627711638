import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getAvatarUrl, getArtistName } from '../selectors/header'
import { getArtistId } from '../selectors/app'
const mapStateToProps = createStructuredSelector({
  avatarUrl: getAvatarUrl,
  artistName: getArtistName,
  artistId: getArtistId,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  avatarUrl: string
  artistName: string
  artistId: number
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect