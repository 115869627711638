import { createSelector } from 'reselect'
import { getPagePath } from 'selectors/page'
import { getHitsPerPage, getPage } from 'selectors/algolia2/url'
export { getAlgoliaUserToken } from 'selectors/user/cookie'
export const getAlgolia = (state: Record<string, any>): Record<string, any> => state.algolia || {}
export const getAlgoliaSearchIndice = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.searchIndice || ''
)
export const getAllFacets = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.facets || {}
)
export const getSelectedFacets = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.selectedFacets || {}
)
export const getSlugifyDictionary = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.slugifyDictionary || {}
)
export const getSharedFacetNames = createSelector(
  getAlgolia,
  (algolia): Record<string, any> => algolia.sharedFacetNames || {}
)
export const getAlgoliaIsLoadingMore = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia?.isLoadingMore || false
)
export const getAlgoliaData = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia?.data || {}
)
export const getAlgoliaDataHits = createSelector(
  getAlgoliaData,
  (data): Record<string, any> => data?.hits || {}
)
export const getAlgoliaDataFacets = createSelector(
  getAlgoliaData,
  (data): Record<string, any> => data?.facets || {}
)
export const getAlgoliaDataHitsResults = createSelector(
  [getAlgoliaDataHits, getPagePath, getPage, getHitsPerPage],
  (hits, path, page, hitsPerPage): Array<Record<string, any>> => {
    return hits.results || [] //
    // if (!results.length) {
    //   return results
    // }
    //
    // // const regex = /^(\/[a-zA-Z]{2}-[a-zA-Z]{2})?\/paintings(\/fine-art)?\/?(\?.*)?$/
    // // const offset = Number((page - 1) * hitsPerPage)
    // //
    // // if (Number.isNaN(offset)) {
    // //   return results
    // // }
    // //
    // // const [, queryString] = path.split('?')
    // // let needsSponsoredAdPositions = false
    // //
    // // if (regex.test(path)) {
    // //   needsSponsoredAdPositions = true
    // // }
    // //
    // // if (queryString?.length) {
    // //   // if there is a query string present
    // //   // we fetch the keys
    // //   const params = new URLSearchParams(queryString)
    // //   const queryKeys = [...params.keys()]
    // //   // retrieve the different keys
    // //   const difference = queryKeys.filter((x) => !['page', 'hitsPerPage'].includes(x))
    // //   // if the resulting array has different keys, meaning it has other things
    // //   // other than page and/or hitsPerPage,
    // //   // we do not show sponsored ad positions
    // //   // TODO: fix issue mentioned below to simplify this logic:
    // //   // https://github.com/saatchiart/easel/pull/1876#discussion_r1515332250
    // //   if (difference.length) {
    // //     needsSponsoredAdPositions = false
    // //   }
    // // }
    // //
    // // if (needsSponsoredAdPositions) {
    // //   const sponsoredAdPositions = [7, 18, 31, 47, 57, 68, 82, 97]
    // //   // eslint-disable-next-line no-restricted-syntax
    // //   for (const sponsoredAdPosition of sponsoredAdPositions) {
    // //     // We retrieve the offset and
    // //     // if the offset is over the current position
    // //     // we just continue going down the list of ad positions
    // //     if (offset > sponsoredAdPosition) {
    // //       // eslint-disable-next-line no-continue
    // //       continue
    // //     }
    // //
    // //     const positionInResults = sponsoredAdPosition - offset
    // //     // We've reached a position (removing the offset) which is over
    // //     // the amount of results we have, no need to continue inserting slots
    // //     if (positionInResults > results.length) {
    // //       break
    // //     }
    // //
    // //     results = [
    // //       ...results.slice(0, positionInResults),
    // //       { type: 'SPONSORED' },
    // //       ...results.slice(positionInResults),
    // //     ]
    // //   }
    // // }
    //
    // return results
  }
)
export const getAlgoliaDataHitsPerPage = createSelector(
  [getAlgoliaDataHits],
  (hits): number => hits.perPage
)
export const getAlgoliaDataHitsTotal = createSelector(
  [getAlgoliaDataHits],
  (hits): number => hits.total || 0
)
export const getAlgoliaDataPagination = createSelector(
  [getAlgoliaData],
  (data): Record<string, any> => data.pagination || {}
)
export const getAlgoliaDataPaginationCurrent = createSelector(
  [getAlgoliaDataPagination],
  (pagination): number => pagination.current || 0
)
export const getAlgoliaDataPaginationTotal = createSelector(
  [getAlgoliaDataPagination],
  (pagination): number => pagination.total || 0
)
export const getAlgoliaDataQuery = createSelector(
  [getAlgoliaData],
  (data): string => data.query || ''
)
export const getAlgoliaDataQueryId = createSelector(
  [getAlgoliaData],
  (data): string => data.queryID || ''
)
export const getAlgoliaDataHitsResultsByArtworkId = (artworkId: number) =>
  createSelector(
    [getAlgoliaDataHitsResults],
    (results): Record<string, any> => results.find((result) => result.artworkId === artworkId) || {}
  )
export const getAlgoliaDataHitsResultsFirstItem = createSelector(
  [getAlgoliaDataHitsResults],
  (results): string => {
    if (results && results[0]) {
      return results[0].imageUrl || ''
    }

    return ''
  }
)
export const getAlgoliaDataHitsIsSeoValidCount = createSelector(
  [getAlgoliaDataHitsResults],
  (results): number => results.reduce((count, { isSeoValid }) => (count += isSeoValid ? 1 : 0), 0)
)
export const getAlgoliaDataHitsAvailableForSaleCount = createSelector(
  [getAlgoliaDataHitsResults],
  (results): number =>
    results.reduce((count, { originalStatus }) => (count += originalStatus === 'avail' ? 1 : 0), 0)
)