"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
/* array-callback-return, consistent-return */
// We use this FILE both SSR and Clientside, so NO FLOW
// We want to have a clean state/reset state
// (['style', 'category', ...]) => { style: [], category: [], ...}
const resetSelectedFacets = (facetNames) => facetNames.reduce((results, facetName) => (Object.assign(Object.assign({}, results), { [facetName]: [] })), {});
// We need to find out what `FacetName` a `AttributeName` belongs to
// Array#some -> escape as soon as TRUE || Return value
const getFacetName = ({ dictionary, name }) => {
    let facetNameMatch = '';
    // Loop through all facets
    Object.entries(dictionary).some(([facetName, attributes]) => {
        // Loop through Attributes and look for a matching attributeName
        Object.keys(attributes).some((attributeName) => {
            if (attributeName === name) {
                // Set the FacetName & Escape
                facetNameMatch = facetName;
                return facetNameMatch;
            }
        });
        if (facetNameMatch) {
            // Found the Match & Escape
            return facetNameMatch;
        }
    });
    return facetNameMatch;
};
// Create a LIST of Shared Facets ** Slugifid Version **
// We use this to make sure we dont create a URL path of `/abstract/abstract`
// We will allow a second parameter for letting us know the order of importance
// We want to know what FacetName should take precedence
// facets: { styles: {'abstract': ...}, subject: {'abstract': ...}, }
// Result => { abstract: ['style', 'subject'] } OR { attibuteName: { facetName, facetName }}
const sharedFacetNameList = (dictionary, pathParams) => {
    const pathKeys = pathParams || Object.keys(dictionary);
    const list = pathKeys.reduce((results, facetName) => {
        const dictionaryValues = dictionary[facetName];
        // Make sure the FacetName Exist - Algolia Indices differ based on ENV
        if (dictionaryValues) {
            // Build out List of Items { abstract: [ 'styles', 'subject' ], paper: [ 'mediums' ], ...}
            Object.keys(dictionaryValues).forEach((attributeName) => {
                if (results[attributeName]) {
                    // If Key VALUE exist, merge values together
                    results[attributeName] = [...results[attributeName], facetName];
                }
                else {
                    results[attributeName] = [facetName];
                }
            });
        }
        return results;
    }, {});
    // Filter List Down to items containing 2 Items
    return Object.entries(list).reduce((filteredList, [attibuteName, facetNames]) => (Object.assign(Object.assign({}, filteredList), (facetNames.length !== 1
        ? {
            [attibuteName]: facetNames,
        }
        : null))), {});
};
// Check Dictionary For Value OR return default (slugify) Value
const dictionaryLookup = ({ dictionary, facetName, facetValue }) => (dictionary[facetName] && dictionary[facetName][facetValue]) || facetValue;
// Turn Slug back to Natural Values in Algolia
const convertSlugifiedFacetsToNaturalValues = ({ dictionary, slugifiedFacets }) => {
    const slugifiedResults = Object.assign({}, slugifiedFacets);
    Object.entries(slugifiedFacets).forEach(([facetName, facetValue]) => {
        // Does the FacetName exist in dictionary, unslugify values
        if (dictionary[facetName]) {
            slugifiedResults[facetName] = Array.isArray(facetValue)
                ? facetValue.reduce((list, name) => [
                    ...list,
                    dictionaryLookup({
                        dictionary,
                        facetName,
                        facetValue: name,
                    }),
                ], [])
                : dictionaryLookup({
                    dictionary,
                    facetName,
                    facetValue,
                });
        }
    });
    return slugifiedResults;
};
module.exports = {
    convertSlugifiedFacetsToNaturalValues,
    getFacetName,
    resetSelectedFacets,
    sharedFacetNameList,
};
