import React from 'react'
import type { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferencesConnector from 'connectors/UserPreferences'

// If user is NOT in the US, but is using US PRICE ( DEFAULT )
// show 'USD' next to price. Only applies to USD as selected currency.
// Ex: User from Russia, currently we don't support Russian Ruble's
// Result: User is from Russia and Selected Currency is US, show USD
const Component = (
  props: ConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    userPreferencesCountry,
    selectedCurrency: { countryCode },
  } = props

  if (userPreferencesCountry !== 'US' && countryCode === 'US') {
    return <span>USD</span>
  }

  return null
}

export default UserPreferencesConnector(Component)