/* Common Flow for `How we manage URL data`
 * Keep in Redux Store -> { page: data: { pageParams: { ... } } }
 */
import { createSelector } from 'reselect'
import { getPageParams } from 'selectors/page'
import 'selectors/'
export { getAlgoliaUserToken } from 'selectors/user/cookie'
export const getSort = createSelector(
  [getPageParams],
  (pageParams): string => pageParams.sort || 'default'
)
export const getHitsPerPage = createSelector([getPageParams], (pageParams): number =>
  Number(pageParams.hitsPerPage || 25)
)
export const getPage = createSelector([getPageParams], (pageParams): number =>
  Number(pageParams.page || 1)
)
export const getSearch = createSelector([getPageParams], (pageParams): string =>
  decodeURI(pageParams.query || '')
)