import React from 'react'
import Image from 'components/Artworks/Image/Basic'
import { getImageProps } from 'components/Artworks/Image/helpers'
import OriginalInfo from 'components/Artworks/Info/Original'
import { getInfoProps } from 'components/Artworks/Info/helpers'
import { algoliaEventsApi } from 'apis/algolia'
import { getDataLayerItemVariant } from 'lib/helpers'
import {
  DESKTOP_COLUMN_WIDTH,
  MOBILE_COLUMN_WIDTH,
  SPAN_HEIGHT,
} from 'components/Artworks/constants'
import PrintInfo from 'components/Artworks/Info/Print'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import type { ConnectorProps as ArtworkProps } from '../../connectors/Artwork'
import ArtworkConnector from '../../connectors/Artwork'
type Props = ArtworkProps & {
  algoliaIndice: string
  algoliaQueryId: string
  algoliaUserToken: string
  hasPrints: boolean
  index: number
  isMobile: boolean
  lazyLoad: boolean
  priority: boolean
  artwork: Record<string, any>
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    algoliaIndice,
    algoliaQueryId,
    algoliaUserToken,
    artwork,
    isMobileTraffic,
    isMobile,
    lazyLoad,
    priority,
    isLimited,
    isUsersArtworksAccount,
  } = props

  if (!artwork) {
    return null
  }

  // PDPs to open in new tabs when in *Desktop* view (NON mobile devices)
  const shouldOpenNewDesktopPdpTab = !isMobileTraffic
  const shouldShowEditIcon = isUsersArtworksAccount
  // We need to figure out Height/SPAN size of Image
  const {
    aspectRatio,
    artworkId,
    positionID,
    category,
    sku,
    artworkTitle,
    firstName,
    lastName,
    listPrice,
  } = artwork
  const columnWidth = isMobile ? MOBILE_COLUMN_WIDTH : DESKTOP_COLUMN_WIDTH
  const imageHeight = parseInt(columnWidth / aspectRatio, 10)
  const imageSpanCount = Math.round(imageHeight / SPAN_HEIGHT)
  // Artwork info is a constant SPAN siz
  const infoSpanCount = isMobile ? 5 : 6
  const totalSpanCount = imageSpanCount + infoSpanCount
  // Helpers to PASS only props NEEDED
  const dataProps = {
    dataApi: 'algolia',
    dataList: 'artist-profile-all-artworks',
    dataListId: 'artist-profile-all-artworks',
    dataListName: `${firstName || ''} ${lastName || ''} Artworks`,
  }
  const imageProps = getImageProps(props)
  const infoProps = getInfoProps(props)
  // Algolia Events
  const algoliaParams = {
    eventType: 'click',
    index: algoliaIndice,
    objectIDs: [artworkId],
    positions: [positionID],
    queryID: algoliaQueryId,
    userToken: algoliaUserToken,
  }
  const actionProps = {
    handleViewArtist: () => {
      algoliaEventsApi({
        events: [{ ...algoliaParams, eventName: 'view artist profile' }],
      })
    },
    handleViewMore: () => {
      algoliaEventsApi({
        events: [{ ...algoliaParams, eventName: 'view more artworks' }],
      })
    },
    handleViewProduct: (ev: React.SyntheticEvent<Record<string, any>>) => {
      algoliaEventsApi({
        events: [{ ...algoliaParams, eventName: 'view product' }],
      })
      const { id, position, list, name } = ev.currentTarget.dataset
      // Delete this dataLayer event in July as part of universal analytics sunset
      id &&
        window.dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: {
                list, // feature-results
              },
              products: [
                {
                  id,
                  // Product sku
                  position,
                  // Location in the results
                  name, // artwork title
                },
              ],
            },
          },
        })
      // This is the new dataLayer event for GA4
      // Clear the previous ecommerce object.
      window.dataLayer.push({
        ecommerce: null,
      })
      id &&
        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            item_list_id: dataProps.dataListId,
            item_list_name: dataProps.dataListName,
            items: [
              {
                item_category: category.replace(/s$/, '').replace(/\sM/, 'm'),
                item_id: sku || '',
                item_name: artworkTitle || '',
                item_brand: `${firstName || ''} ${lastName || ''}` || '',
                item_variant: getDataLayerItemVariant(sku),
                index: positionID,
                affiliation: 'Online Store',
                price: convertFromCentsToDollars(listPrice) || 0,
                quantity: 1,
              },
            ],
          },
        })
    },
  }
  return (
    <figure
      style={{
        gridRowEnd: `span ${totalSpanCount}`,
      }}
    >
      <Image
        shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
        {...imageProps}
        {...dataProps}
        lazyLoad={lazyLoad}
        onClick={actionProps.handleViewProduct}
        priority={priority}
        eventType='artist-profile-all-artworks'
        shouldShowEditIcon={shouldShowEditIcon}
      />

      {isLimited ? (
        <PrintInfo
          {...actionProps}
          {...infoProps}
          shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
        />
      ) : (
        <OriginalInfo
          {...actionProps}
          {...infoProps}
          shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
        />
      )}
    </figure>
  )
}

export default ArtworkConnector(Component)