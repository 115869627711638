import React from 'react'
import { getProductCategoryPagination } from 'lib/category'
import { ResultsCountWrapper } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  hitsTotal: number
  hitsPerPage: number
  category?: string
  currentPage: number
}

const ResultsCount = (props: Props) => {
  const { hitsTotal, hitsPerPage, category, currentPage } = props
  const displayCategory = category ? getProductCategoryPagination(category) : ''
  return (
    <ResultsCountWrapper>
      {/* TODO: fix nested ternary */}
      { }
      {hitsTotal < hitsPerPage ? (
        !hitsTotal ? (
          <SATypography data-type='no-results' variant={SATypographyVariant.MEDIUM}>No Results</SATypography>
        ) : (
          <SATypography data-type='results-count' variant={SATypographyVariant.MEDIUM}>{`1 - ${hitsTotal} ${displayCategory}`}</SATypography>
        )
      ) : (
          <SATypography data-type='results-count' variant={SATypographyVariant.MEDIUM}>
            {(hitsPerPage * currentPage + 1).toLocaleString()} -{' '}
            {hitsTotal < hitsPerPage * (currentPage + 1)
              ? `${hitsTotal.toLocaleString()} ${displayCategory}` // 26-29
              : `${(
                  hitsPerPage *
                  (currentPage + 1)
                ).toLocaleString()} of ${hitsTotal.toLocaleString()} ${displayCategory}`}
          </SATypography>
      )}
    </ResultsCountWrapper>
  )
}

export default ResultsCount