import React, { useEffect, useRef } from 'react'
type Props = {
  children:
    | Array<React.ReactElement<React.ComponentProps<any>, any>>
    | React.ReactElement<React.ComponentProps<any>, any>;
  updateDimensions: (...args: Array<any>) => any;
  styles?: Record<string, string>;
}

/**
 * Use the MutationObserver API for monitoring DOM updates
 */
const mutationObserver = (ref, func) => {
  if (!ref || !ref.current || (!window.MutationObserver && !window.WebKitMutationObserver)) {
    return null
  }

  const ObserverClass = window.MutationObserver || window.WebKitMutationObserver
  // Recompute inner content height on DOM change
  const observer = new ObserverClass(func)
  observer.observe(ref.current, {
    childList: true,
    attributes: true,
    characterData: true,
    subtree: true,
  })
  return observer
}

const killMutationObserver = (observer) => {
  if (observer) {
    observer.disconnect()
  }
}

export default (props: Props) => {
  const { children, updateDimensions, styles } = props
  const ref: Record<string, any> = useRef(null)

  const setDimensions = () => {
    if (ref) {
      updateDimensions({
        height: ref.current.scrollHeight,
        width: ref.current.scrollWidth,
      })
    }
  }

  useEffect(() => {
    const observer = mutationObserver(ref, setDimensions)
    window.addEventListener('resize', setDimensions)
    setDimensions()
    return () => {
      window.removeEventListener('resize', setDimensions)
      killMutationObserver(observer)
    }
  }, [])
  return <div style={styles} ref={ref}>{children}</div>
}