import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import CaretDownSvg from 'svg/caret-down.svg'
import { ContextMenu, ContextMenuItem } from '../ContextMenu'
export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  padding: ${(props) =>
    props.hasLabel
      ? `${props.theme.space[1]}px ${props.theme.space[2]}px`
      : `${props.theme.space[3]}px ${props.theme.space[2]}px`};
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  padding-right: 36px;
  cursor: pointer;
  text-align: left;

  &:focus {
    outline: none;
  }
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: .5;
  `}
  &[data-section='sort-select'] {
    height: 38px;
    font-size: 14px;
    min-width: 180px;
    display: flex;
    padding-top: 10px;
    padding-right: 0;
    [data-type='sort-select'] {
      font-weight: normal;
      text-transform: none;
      font-size: 16px;
      margin-bottom: 0;
      margin-right: 5px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      min-width: 77px;
      height: 34px;
      padding-top: 7px;
      font-size: 16px;
    }
  }
`
export const Icon = styled(CaretDownSvg)`
  position: absolute;
  right: ${(props) => props.theme.space[2]}px;
  position: absolute;
  width: 16px;
  top: 50%;
  margin-top: -4px;

  path {
    stroke: ${(props) => props.theme.colors.secondary};
  }
  &[data-type='sort-value'] {
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }
`
export const Dropdown = styled(ContextMenu)`
  position: absolute;
  top: 102%;
  display: none;
  left: 0;
  padding: ${(props) => props.theme.space[1]}px ${(props) => props.theme.space[2]}px;
  min-width: 100%;
  z-index: 1;
  ${(props) =>
    props.maxHeight &&
    `max-height: ${props.maxHeight};
  overflow: scroll;`}

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (!props.hideWhenInactive || props.active ? 'display: block;' : 'display: none;')};
  }
`
export const DropdownItem = styled(ContextMenuItem)`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
`
export const ShadowSelect = styled.select`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    pointer-events: auto;
    visibility: initial;
  }
`
export const Value = styled.div`
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &[data-type='sort-value'] {
    display: none;
  }
`
export const Label = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  margin-bottom: ${(props) => props.theme.space[1]}px;
`
