// CONSTANTS
export const Constants = {
  LOCK_SCROLL: 'LOCK_SCROLL_WINDOW',
  SCROLL_TO_ELEMENT: 'SCROLL_TO_ELEMENT',
  SCROLL_TO_TOP: 'SCROLL_TO_TOP_OF_WINDOW',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.LOCK_SCROLL
      payload: boolean
    }
  | {
      type: typeof Constants.SCROLL_TO_ELEMENT
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SCROLL_TO_TOP
      payload: Record<string, any> | null | undefined
    }
// ACTION CALLS
export const Actions = {
  LOCK_SCROLL: (payload: boolean): ActionTypes => ({
    type: Constants.LOCK_SCROLL,
    payload,
  }),
  SCROLL_TO_ELEMENT: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SCROLL_TO_ELEMENT,
    payload,
  }),
  SCROLL_TO_TOP: (payload: Record<string, any> | null | undefined): ActionTypes => ({
    type: Constants.SCROLL_TO_TOP,
    payload,
  }),
}