import url from 'url'
import R from 'ramda'
import { slugify } from 'lib/slugify'
import { updateAppUrl } from 'lib/url/route'
import { getUrlStringWithParams, getUrlStringWithoutParams } from '../selectors/url'
import { updateFacetValues as updateFacetValuesSelector } from '../selectors/facets'
import { DEFAULT_APP } from './constants'
import { removeUsedFacets, getFirstFacetValue } from './facets'
// urlParams => ['Photography', 'Fine-Art', 'Portrait']
// Results `/photography/fine-art/portrait`
export const slugifyPath = (urlParams: Array<string>): string =>
  `/${urlParams.map(slugify).join('/')}`
// Params ( page, '25' )
// Result => `page=25`
export const stringQuery = (key: string, value: string): string => {
  if (!key || !value) {
    return ''
  }

  return `${key}=${value}`
}
// Params ( styles, ['Fine Art', 'Figurative', 'Modern' ])
// Result => `styles[]=fine-art&styles[]=figurative&styles[]=modern`
export const arrayQuery = (key: string, values: Array<any>): string => {
  if (!values.length) {
    return ''
  }

  // If only 1 item NO BRACKETS => `styles=fine-art`
  return values.map((value) => `${key}${values.length > 1 ? '[]' : ''}=${slugify(value)}`).join('&')
}
// Params ({ page: 1, styles: ['Fine Art', 'Figurative'], hitsPerPage: 25 })
// Result => `?page=1&styles[]=fine-art&styles[]=figurative&hitsPerPage=25`
export const urlQuery = (obj: Record<string, any>) => {
  const query = Object.entries(obj)
    .filter(([key, value]) => {
      // Remove EMPTY and FALSE values => '', false, 0
      if (!value) {
        return false
      }

      return true
    })
    .map(([key, value]) =>
      Array.isArray(value)
        ? arrayQuery(key, value.filter(Boolean))
        : stringQuery(String(key), String(value))
    )
    .filter(Boolean)
    .join('&')
  return query.length ? `?${query}` : ``
}
// We need a way to pull SPECIAL Query Params for ALGOLIA
// Ex: hitsPerPage, page, sort
export const getAlgoliaQueryParams = (href: string = ''): Record<string, any> => {
  const { pathname, query } = url.parse(href, true)
  const { hitsPerPage, page, sort, width, height } = query || {}
  return {
    printsPath: (pathname || '').search(/\/prints/g) > -1,
    // Check if 'PRINTS' path for different indice
    hitsPerPage: hitsPerPage || 100,
    page,
    sort,
    width,
    height,
  }
}
export const urlWithParams = (redux: Record<string, any>): string =>
  R.curry((reduxState, params) => getUrlStringWithParams(params)(reduxState))(redux)
export const urlWithoutParams = (redux: Record<string, any>): string =>
  R.curry((reduxState, params) => getUrlStringWithoutParams(params)(reduxState))(redux)
export const updateFacetValues = (redux: Record<string, any>): string =>
  R.curry((reduxState, facetName, facetValue) =>
    updateFacetValuesSelector(facetName, facetValue)(reduxState)
  )(redux)
export const updateUrl = (href: string): void => {
  updateAppUrl({
    app: DEFAULT_APP,
    href,
  })
}
// queryParams merged attributes to create url  => ...selectedFacets, ...algoliaQuery, ...dimensionQuery, ...params
// urlPathParams
// sharedFacetNames
export const generateLocationUrl = ({
  queryParams,
  pathParams,
  sharedFacetNames,
}: Record<string, any>): string => {
  const urlPathParams = pathParams.reduce(
    (results, facetName) => ({
      ...results,
      [facetName]: getFirstFacetValue(facetName, queryParams[facetName], {
        sharedFacetNames,
      }),
    }),
    {}
  )

  // Product Note -> restore URL rules for /prints instead of /prints/all
  if (urlPathParams.has_prints && urlPathParams.category === 'All') {
    delete urlPathParams.category
  }

  const pathString = slugifyPath(Object.values(urlPathParams).filter(Boolean).map(String))
  const queryCleanup = removeUsedFacets({
    facets: queryParams,
    usedValues: { ...urlPathParams, has_prints: true }, // Never append `has_prints` to `?` query
  })
  const queryString = urlQuery(queryCleanup)
  return `${pathString}${queryString}`
}