import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Caret from 'svg/caret-right.svg'
import CloseX from 'svg/close-x.svg'
import Minus from 'svg/minus.svg'
import Plus from 'svg/plus.svg'
import Tasks from 'svg/tasks.svg'
import Sort from 'svg/sort.svg'

const IconStyle = (props) => `
  width: 25px;
  height: 20px;
  path {
    stroke: ${props.theme.colors.lightGray};
  }};
  transition: 0.2s ease color, 0.2s ease-in-out transform;
  will-change: color, tranform;
`

export const FilterIcon = styled(Tasks)`
  ${(props) => IconStyle(props)};
  width: 16px;
  height: 16px;
  cursor: pointer;
`
export const SortIcon = styled(Sort)`
  ${(props) => IconStyle(props)};
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 5px;
  path {
    stroke: #333;
  }
`
export const PlusIcon = styled(Plus)`
  ${(props) => IconStyle(props)};
  width: 12px;
  height: 12px;
  cursor: pointer;
  path {
    stroke-width: 0;
  }
`
export const MinusIcon = styled(Minus)`
  ${(props) => IconStyle(props)};
  width: 12px;
  height: 12px;
  cursor: pointer;
  path {
    stroke-width: 0;
  }
`
export const CaretIcon = styled(Caret)`
  ${(props) => IconStyle(props)};
  width: 12px;
  height: 12px;
  cursor: pointer;
`
export const CloseIcon = styled(CloseX)`
  height: 23px;
  width: 23px;
  padding: 5px;
  background: ${(props) => props.theme.colors.backgroundGray};
  border-radius: 50%;
  path {
    stroke: ${(props) => props.theme.colors.default};
  }
`