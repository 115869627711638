import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as AppActions } from '../actions'
import Selectors from '../selectors'
import { urlWithParams, updateFacetValues } from '../helpers/url'
const mapStateToProps = createStructuredSelector({
  isSelected: (state, ownProps) => {
    return Selectors.isSelectedFacetValue(ownProps || {})(state)
  },
  updateFacetValues: (state) => updateFacetValues(state),
  urlWithParams: (state) => urlWithParams(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateUrl: (url: string) => dispatch(AppActions.UPDATE_URL(url)),
})

export type ConnectorProps = {
  isSelected: boolean
  updateFacetValues: (...args: Array<any>) => any
  updateUrl: (...args: Array<any>) => any
  urlWithParams: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)