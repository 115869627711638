import React from 'react'
import { SeeArtSection } from 'styles/algolia/facets'
import { Button } from 'styles/algolia/button'
import type { ConnectorProps as FilterConnectorProps } from '../../connectors/Filter'
import FilterConnector from '../../connectors/Filter'
export default FilterConnector(
  ({
    filterEvent,
    areFiltersAvailable,
  }: FilterConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => (
    <SeeArtSection isVisible={areFiltersAvailable}>
      <Button data-button='dark' data-type='seeArt' onClick={filterEvent}>
        See Art
      </Button>
    </SeeArtSection>
  )
)