type AnimateProps = {
  container?: string
  offset?: number
  duration?: number
  easing?: (t: number, b: number, c: number, d: number) => number
}
export function getScrollTop(): number {
  if (document.documentElement) {
    return document.documentElement.scrollTop
  } else if (document.body) {
    return document.body.scrollTop
  }

  return 0
}
export function setScrollTop(position: number) {
  if (document.documentElement) {
    document.documentElement.scrollTop = position
  }

  if (document.body) {
    document.body.scrollTop = position
  }
}

function scrollTo(element, { offset, duration, easing }) {
  const start = getScrollTop()
  const to = element.offsetTop + offset
  const change = to - start
  const increment = 10

  function animate(elapsedTime) {
    const elapsed = elapsedTime + increment
    const position = easing(elapsed, start, change, duration)
    setScrollTop(position)

    if (elapsed < duration) {
      setTimeout(() => {
        animate(elapsed)
      }, increment)
    }
  }

  animate(0)
}

export function animateScroll(
  animate: AnimateProps | null | undefined,
  target: HTMLElement | null | undefined
) {
  const params = {
    offset: 0,
    duration: 300,
    easing: easeOutQuad,
    ...animate,
  }
  let element = target || document.body

  if (params.container) {
    element = document.getElementById(params.container)
  }

  if (!element) {
    throw new Error(`Unable to find DOM node with id ${params.container}`)
  }

  scrollTo(element, params)
}
// Default easing function
// jQuery easing 'swing'
export function easeOutQuad(t: number, b: number, c: number, d: number): number {
  return -c * (t /= d) * (t - 2) + b
}