// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import { space } from 'styled-system'
import SALink from 'components/SALink'
import * as AccountStyles from 'styles/account'
const { Row, Column } = AccountStyles
export { Row, Column }
const frameColors = {
  Black: `#000000`,
  White: `#f0f0f0`,
  'Natural Wood': `#efe3d2`,
  'Black Canvas': `#000000`,
  'White Canvas': `#f0f0f0`,
}
export const Sold = styled.div`
  ${space};
  background-color: ${(props) => props.theme.colors.darkerRed};
  color: ${(props) => props.theme.colors.white};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 50%;
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
export const Reserved = styled.p`
  color: ${(props) => props.theme.colors.darkerRed};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
export const ReservedBadge = styled.div`
  background-color: #da3b1f;
  color: #ffffff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 64px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-weight: bold;
`
export const ArtworkImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  pointer-events: none;
  min-height: 80px; /* We set the min-height using ASPECT RATIO */
  background: ${(props) => props.theme.colors.borderLight};
`
export const CollectionImage = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 300px;
  max-width: 100%;
  max-height: 300px;
  display: block;
  pointer-events: none;
  min-height: 80px; /* We set the min-height using ASPECT RATIO */
`
export const UploadPanel = styled.div`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
export const UploadImage = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 150px; /* We set the min-height using ASPECT RATIO */
  border: 10px solid #f5f5f5;
  i {
    font-size: 20px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    padding: 20px 21px;
    position: relative;
    color: #fff;
    background-color: #000;
    margin: 0 0 10px;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      margin: 10px 0 15px;
      font-size: 36px;
      height: 80px;
      width: 80px;
      padding: 22px 24px;
    }
  }
  span {
    font-size: 12px;
    font-weight: bold;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      font-size: 16px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    height: 260px;
    width: 260px;
    border: 20px solid #f5f5f5;
  }
`
export const PrintImage = styled.img`
  background: white;
  padding: ${(props) => props.matSize}px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 1px 5px 2px #cccccc;
  ${(props) =>
    props.type === 'Canvas'
      ? `
      border-radius: 0px ${props.frameSize}px;
      border-right: ${props.frameSize}px solid ${frameColors[props.frameColor]};
      border-bottom: ${props.frameSize}px solid ${frameColors[props.frameColor]};
      border-top-color: transparent;
      border-left-color: transparent;
      padding: 0;
      `
      : `
      border: 15px solid ${frameColors[props.frameColor] || 'transparent'};
      border-width: ${props.frameSize || 0}px;
      ${
        props.frameColor
          ? `box-shadow: 0px 1px 5px 2px #cccccc, 2px 2px 2px 0px #cccccc inset;`
          : `border: 15px solid #ededed;`
      };
  `}
`
export const ViewMore = styled(SALink)`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  text-decoration: none;
  display: inline-block;
  color: ${(props) => props.theme.colors.blue};
  transition: 0.5s ease color;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.lightGray};
  }
`
export const ArtworkMainText = styled.p`
  /*  18px => ${(props) => props.theme.fontSizes[4]}px; */
  font-size: 17px;
  color: ${(props) => props.theme.colors.default};
`
export const ArtworkSubText = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.gray};
  &[data-type='date'] {
    font-size: ${(props) => props.theme.fontSizes[1]}px;
    font-weight: 100;
    margin-top: -3px;
    text-align: right;
    width: 100%;
  }
`
export const ArtworkInfo = styled(Column)`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${(props) => props.theme.fontSizes[4]}px;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.default};
    transition: color 0.5s ease 0s;
    &:hover {
      color: ${(props) => props.theme.colors.black};
    }
  }
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
  ${Row} {
    ${Column} {
      width: 60%;
      + ${Column} {
        width: 40%;
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    ${Row} ${Column} {
      margin-bottom: ${(props) => props.theme.space[2]}px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='mainInfo'] {
      p,
      a {
        color: ${(props) => props.theme.colors.default};
        font-size: ${(props) => props.theme.fontSizes[3]}px;
      }
    }
    ${ArtworkSubText} {
      display: none;
    }
    [data-type='prices'] {
      ${ArtworkSubText} {
        display: flex;
      }
      a {
        display: none;
      }
    }
    p,
    a {
      color: ${(props) => props.theme.colors.lightGray};
      font-size: ${(props) => props.theme.fontSizes[2]}px;
    }
    ${Row} {
      margin: 0 0 5px;
    }
    ${Row} + ${Row} {
      align-items: center;
      flex-direction: column;
      ${Column}, ${Column} + ${Column} {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        &[data-type='prices'] {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
    ${Sold} {
      position: absolute;
      bottom: 150px;
      right: 5px;
    }
  }
`
export const ArtworkTitle = styled(Column)`
  display: block;
`