import React, { useState } from 'react'
import { FilterIcon } from 'styles/algolia/icons'
import { FilterButton } from './styles'
import type { ConnectorProps as FilterConnectorProps } from '../../connectors/Filter'
import FilterConnector from '../../connectors/Filter'
export default FilterConnector(
  (props: FilterConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { facetCount, filterEvent } = props
    const [isOpen, handleOpen] = useState(false)
    return (
      <FilterButton
        onClick={() => {
          filterEvent()
          handleOpen(!isOpen)
        }}
        data-type='filter'
        {...(isOpen
          ? {
              isOpen: true,
            }
          : {})}
      >
        <FilterIcon />
        <span>Filters {facetCount ? ` (${facetCount})` : ``}</span>
      </FilterButton>
    )
  }
)