import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { Row, Spacer } from 'styles/account'
export { Spacer }
export const ResultCount = styled.p`
  color: #333333;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  text-align: center;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-bottom: 10px;
  }
`
export const PaginationRow = styled(Row)`
  margin-left: 200px;
  justify-content: center;
  flex-direction: column;
`
export const PaginationHitsPerPage = styled(Row)`
  width: 100%;
  margin-bottom: 80px;
  align-items: flex-start;
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    align-items: center;
    ${PaginationRow} {
      order: -1;
      margin: 0;
      margin-bottom: 20px;
    }
    &[data-type='top'] {
      display: none;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    div:last-child {
      margin-left: 20px;
    }
  }
`