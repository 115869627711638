import { observerEpicHelper } from 'epics/helpers'
import { Constants as AlgoliaActions } from 'actions/algolia'
import { selectedFacetsToAlgoliaQueryString } from 'lib/algolia/v2'
import { Actions as AlgoliaDataActions } from 'actions/algolia2/data'
import { algoliaArtworksApi, algoliaEventsApi } from 'apis/algolia'
import { getMeasurementUnit } from 'selectors/user/session'
import { Actions as ScrollActions } from 'actions/utility/scroll'
import { Actions as PageActions } from 'actions/page'
import { payloadFormatter } from 'epics/algolia'
import { getUserPreferencesDestinationZone } from 'selectors/user/cookie'
import { setWithoutJsonEncoding as setLocalStorage } from 'lib/localStorage'
import { applicationId, searchApi } from '../helpers/constants'
import { Actions as AppActions, Constants } from '../actions'
import Selectors from '../selectors'

const ALGOLIA_QUERY_ID = 'algolia_query_id'

export const updateHitsPerPageEpic = observerEpicHelper(
  [Constants.UPDATE_HITS_PER_PAGE],
  async ({ action, observer, store }) => {
    const url = Selectors.getUrlStringWithParams({
      hitsPerPage: action.payload,
      page: '',
    })({ ...store.value })
    observer.next(AppActions.UPDATE_URL(url))
    observer.next(ScrollActions.SCROLL_TO_TOP())
    observer.complete()
  }
)
export const updatePageEpic = observerEpicHelper(
  [Constants.UPDATE_PAGE],
  async ({ action, observer, store }) => {
    const url = Selectors.getUrlStringWithParams({
      page: action.payload,
    })({ ...store.value })
    observer.next(AppActions.UPDATE_URL(url))
    observer.next(ScrollActions.SCROLL_TO_TOP())
    observer.complete()
  }
)
export const updateAlgoliaEpic = observerEpicHelper(
  [Constants.UPDATE_ALGOLIA],
  async ({ action, observer, store }) => {
    observer.next(PageActions.IS_READY(false))
    // Get Algolia Config Data: applicationId, searchApi, searchIndice
    // Update Sort
    const searchIndice = Selectors.getSortIndice({ ...store.value })
    const hitsPerPage = Selectors.getHitsPerPage({ ...store.value })
    const pageNumber = Selectors.getPaginationCurrent({ ...store.value })
    const selectedFacets = Selectors.getSelectedFacets({ ...store.value })
    const userId = Selectors.getArtistId({ ...store.value })
    const destinationZone = getUserPreferencesDestinationZone({ ...store.value })
    const filters = selectedFacetsToAlgoliaQueryString(
      { ...selectedFacets }, // selected facets
      {
        measurementUnit: getMeasurementUnit({ ...store.value }),
        destinationZone,
      } // Additional params
    )
    const algoliaParams = {
      applicationId,
      searchApi,
      searchIndice,
      filters,
      hitsPerPage,
      page: pageNumber,
      query: `"${userId}"`,
      restrictSearchableAttributes: 'id_user',
      typoTolerance: false,
      // Accept override params => `page`, hitsPerPage``
      ...(action.payload ? action.payload : {}),
      destinationZone,
    }
    const algoliaResults = await algoliaArtworksApi(algoliaParams)
    observer.next(AlgoliaDataActions.SET_DATA({ ...algoliaResults }))
    // store ALGOLIA_QUERY_ID in local storage
    const { queryID } = algoliaResults
    setLocalStorage(ALGOLIA_QUERY_ID, queryID || '')
    observer.next(PageActions.IS_READY(true))
    observer.complete()
  }
)
export const algoliaEventEpic = observerEpicHelper(
  [AlgoliaActions.EVENT],
  async ({ action, observer, store }): Promise<void> => {
    const state = { ...store.value }
    const userToken = Selectors.getAlgoliaUserToken(state)
    const queryID = Selectors.getQueryId(state)
    const searchIndice = Selectors.getSortIndice(state)
    algoliaEventsApi({
      events: [
        {
          index: searchIndice,
          queryID,
          userToken,
          ...payloadFormatter(action.payload),
        },
      ],
    })
    observer.complete()
  }
)
export default [algoliaEventEpic, updateAlgoliaEpic, updateHitsPerPageEpic, updatePageEpic]
