import type { InfoProps, ArtworkProps, ArtistProps, ViewMoreProps } from './Types'
type Props = {
  artwork: InfoProps
  hasPrints: boolean
}
export const getInfoProps = (props: Props): InfoProps => {
  const {
    artwork: {
      artworkId,
      artworkTitle,
      category,
      country,
      depth,
      firstName,
      hasLimitedEditions,
      hasOpenEditions,
      height,
      idUser,
      isSeoValid,
      lastName,
      minPrintPrice,
      originalStatus,
      positionID,
      listPrice,
      printUrl,
      productUrl,
      profileUrl,
      width,
    },
    hasPrints,
  } = props
  return {
    artworkId,
    artworkTitle,
    category,
    country,
    depth,
    firstName,
    hasLimitedEditions,
    hasOpenEditions,
    hasPrints: hasPrints || false,
    height,
    idUser,
    isSeoValid,
    lastName,
    minPrintPrice,
    originalStatus,
    positionID,
    listPrice,
    printUrl,
    productUrl,
    profileUrl,
    width,
  }
}
export const getArtworkProps = (props: Record<string, any>): ArtworkProps => {
  const {
    artworkTitle,
    category,
    depth,
    hasPrints,
    height,
    isSeoValid,
    originalStatus,
    printUrl,
    productUrl,
    width,
  } = props
  return {
    artworkTitle,
    category,
    depth,
    hasPrints,
    height,
    isSeoValid,
    originalStatus,
    printUrl,
    productUrl,
    width,
  }
}
export const getArtistProps = (props: Record<string, any>): ArtistProps => {
  const { country, firstName, isSeoValid, lastName, profileUrl } = props
  return {
    country,
    firstName,
    isSeoValid,
    lastName,
    profileUrl,
  }
}
export const getViewMoreProps = (props: Record<string, any>): ViewMoreProps => {
  const { idUser, isSeoValid, originalStatus } = props
  return {
    idUser,
    isSeoValid,
    originalStatus,
  }
}
export const getCleanCategory = (category: string) => {
  return category.replace(/s+$/, '')
}