const { slugifyTitle } = require('../../../../../lib/slugify')
const { isPriceNotNil } = require('../../../../helpers/geoPricing')

// NON Flow Function - TODO - We should create common HELPERS
const getProductCategoryUrl = (category) => {
  switch (category) {
    case 'Drawings':
      return 'Drawing'
    case 'Paintings':
      return 'Painting'
    default:
      return category
  }
}

// Algolia doesn't HAVE or DISTINGUISH between prints and original products
// This is a helper to create info for different polaroids
const artworkInfo = (data) => {
  const {
    artwork_title: artworkTitle,
    legacy_art_id: legacyArtId,
    artId,
    category,
    id_user: idUser,
    original_status: originalStatus,
    prints_availability: printsAvailability,
  } = data || {}
  const hasPrints = printsAvailability !== 'none'
  const title = slugifyTitle(`${getProductCategoryUrl(category)}-${artworkTitle}`)
  const path = `${title}/${idUser}/${legacyArtId || artId}`
  const originalUrl = `/art/${path}/view`
  const printUrl = `/print/${path}/view`
  let productUrl = originalUrl
  if (hasPrints && originalStatus === 'unavail') {
    productUrl = printUrl
  }
  return {
    originalUrl,
    printUrl,
    productUrl,
  }
}

const whitelistHits = (hitsArray, destinationZone) =>
  hitsArray.map((data) => ({
    artId: data.artId || '',
    artistArtworksUrl: data.artist_artworks_url || '',
    artistRecognition: data.artist_recognition || [],
    artworkId: data.artId || '',
    artworkRank: data.artwork_rank || 0,
    artworkTitle: data.artwork_title || '',
    artworkUrl: data.artwork_url || '',
    aspectRatio: data.aspect_ratio || 1,
    category: data.category || '',
    city: data.city || '',
    color: data.color || null,
    colorProximity: data.color_proximity || null,
    country: data.country || '',
    curatedStatus: data.curated_status || 0,
    depth: data.depth || 0,
    firstName: data.first_name || '',
    hasLimitedEditions: data.prints_availability === 'limited' || false,
    hasOpenEditions: data.prints_availability === 'open' || false,
    height: data.height || 0,
    idUser: data.id_user || 0,
    imageBaseUrl: data.image_url ? data.image_url.replace(/-\d.jpg$/, '') : '', // `...1129409-6.jpg` => `...1129409`
    imageUrl: data.image_url || '',
    isSafe: data.is_safe || false,
    isSeoValid: data.is_seo_valid || false,
    keywords: data.keywords || [],
    lastName: data.last_name || '',
    legacyArtId: data.legacy_art_id || '',
    // legacyUserArtId: data.legacy_user_art_id || '',
    materials: data.materials || [],
    mediums: data.mediums || [],
    minPrintPrice: data.min_print_price || null,
    objectID: data.objectID || '',
    orientation: data.orientation || '',
    originalStatus: data.original_status || '',
    listPrices: data.geo_prices || {},
    price: data.price || 0,
    listPrice: isPriceNotNil(data.geo_prices) ? data.geo_prices[destinationZone] : 0,
    productStatusRank: data.product_status_rank || 0,
    profileUrl: data.profile_url || '',
    size: data.size || '',
    sku: data.sku || '',
    styles: data.styles || [],
    subject: data.subject || '',
    toafCities: data.toaf_cities || [],
    uploadedDate: data.uploaded_date || 0,
    width: data.width || 0,
    // Print Info
    hasPrint: data.has_prints || false,
    printsAvailability: data.prints_availability || 'none',
    printsMaterials: data.prints_materials || [],
    printsSizes: data.prints_sizes || [],
    printsSizeRanges: data.prints_size_ranges || [],
    printsPriceRanges: data.prints_price_ranges || [],
    printsSizesPrices: data.prints_sizes_prices || {},
    // Missing Data we need to Create
    ...artworkInfo(data),
  }))

const whitelistAlgoliaPayload = (json, destinationZone) => {
  const { facets, hits, nbHits, hitsPerPage, nbPages, page, params, query, queryID } = json || {}
  return {
    hits: {
      results: whitelistHits(hits || [], destinationZone).map((hit, index) => ({
        ...hit,
        positionID: hitsPerPage * page + (index + 1), // Position of the click in the list of Algolia search results
      })),
      perPage: hitsPerPage,
      total: nbHits,
    },
    pagination: {
      current: page,
      total: nbPages,
    },
    params,
    query,
    queryID,
    facets,
  }
}

const whitelistArtworksPayload = (json) => {
  const { hits, nbHits, hitsPerPage, nbPages, page, query, queryID } = json || {}
  return {
    hits: {
      results: whitelistHits(hits || []),
      perPage: hitsPerPage,
      total: nbHits,
    },
    pagination: {
      current: page,
      total: nbPages,
    },
    query,
    queryID,
  }
}

const whitelistCollectionHits = (hitsArray, destinationZone) =>
  hitsArray.map((data) => ({
    artId: data.artId || '',
    artistArtworksUrl: data.artist_artworks_url || '',
    artworkId: data.artId || '',
    artworkTitle: data.artwork_title || '',
    artworkUrl: data.artwork_url || '',
    aspectRatio: data.aspect_ratio || 1,
    category: data.category || '',
    collectionId: data.collection_id || '',
    collectionUserId: data.collection_user_id || 0,
    country: data.country || '',
    depth: data.depth || 0,
    firstName: data.first_name || '',
    hasLimitedEditions: data.prints_availability === 'limited' || false,
    hasOpenEditions: data.prints_availability === 'open' || false,
    height: data.height || 0,
    idUserCollection: data.id_user_collection || 0,
    idUser: data.id_user || 0,
    imageBaseUrl: data.image_url ? data.image_url.replace(/-\d.jpg$/, '') : '', // `...1129409-6.jpg` => `...1129409`
    imageUrl: data.image_url || '',
    isSafe: data.is_safe || false,
    isSeoValid: data.is_seo_valid || false,
    lastName: data.last_name || '',
    legacyArtId: data.legacy_art_id || '',
    minPrintPrice: data.min_print_price || null,
    objectID: data.objectID || '',
    originalStatus: data.original_status || '',
    price: data.price || 0,
    listPrices: data.geo_prices || {},
    listPrice: isPriceNotNil(data.geo_prices) ? data.geo_prices[destinationZone] : 0,
    printsAvailability: data.prints_availability || 'none',
    profileUrl: data.profile_url || '',
    size: data.size || '',
    sku: data.sku || '',
    sortOrder: data.sort_order || 0,
    subject: data.subject ?? '',
    styles: data.styles ?? [],
    uploadedDate: data.uploaded_date || 0,
    width: data.width || 0,
    // Missing Data we need to Create
    ...artworkInfo(data),
  }))

const whitelistCollectionPayload = (json, destinationZone) => {
  const { hits, nbHits, facets, hitsPerPage, nbPages, page, query, queryID } = json || {}
  return {
    hits: {
      results: whitelistCollectionHits(hits || [], destinationZone).map((hit, index) => ({
        ...hit,
        positionID: hitsPerPage * page + (index + 1), // Position of the click in the list of Algolia search results
      })),
      perPage: hitsPerPage,
      total: nbHits,
    },
    pagination: {
      current: page,
      total: nbPages,
    },
    query,
    queryID,
    facets,
  }
}

const whitelistThumbs = (imagesArray) =>
  [...Array(3)].map((_, i) =>
    imagesArray[i]
      ? imagesArray[i]
      : '//d3t95n9c6zzriw.cloudfront.net/common/placeholder-square-93.jpg'
  )

const whitelistAllCollectionHits = (hitsArray) =>
  hitsArray.map((data) => ({
    artworkSummary: data.artworks_summary || {},
    aspectRatio: data.aspect_ratio || 1,
    collectionCount: data.artworks_summary.total_artworks || 0,
    collectionDescription: data.description || '',
    collectionId: data.id_user_collection || '',
    collectionKeywords: data.collection_keywords || [],
    collectionOwnerAvatar:
      data.collection_owner_avatar ||
      '//d3t95n9c6zzriw.cloudfront.net/common/profile/avatar_medium_square.jpg',
    collectionOwnerId: data.collection_owner_id || 0,
    collectionOwnerJobTitle: data.collection_owner_job_title || '',
    collectionOwnerName: data.collection_owner_name || '',
    collectionTitle: data.title || '',
    collectionTypes: data.collection_types || [],
    collectionUrl: data.collection_url || '',
    createdAt: data.created_at || 0,
    description: data.description || '',
    idUserCollection: data.id_user_collection || 0,
    isLimited: data.is_limited || false,
    isPrivate: data.is_private || false,
    isVisible: data.is_visible || true,
    isPubliclySearchable: data.is_publicly_searchable || true,
    mainImage:
      data.primary_artwork_image_url ||
      '//d3t95n9c6zzriw.cloudfront.net/common/placeholder-square-93.jpg',
    objectID: data.objectID || '',
    productSummary: data.products_summary || {},
    thumbs: whitelistThumbs(data.secondary_3_artworks_image_urls) || [],
    updatedAt: data.updated_at || 0,
  }))

const whitelistAllCollectionPayload = (json) => {
  const { hits, nbHits, facets, hitsPerPage, nbPages, page, query, queryID } = json || {}
  return {
    hits: {
      results: whitelistAllCollectionHits(hits || []).map((hit, index) => ({
        ...hit,
        positionID: hitsPerPage * page + (index + 1), // Position of the click in the list of Algolia search results
      })),
      perPage: hitsPerPage,
      total: nbHits,
    },
    pagination: {
      current: page,
      total: nbPages,
    },
    query,
    queryID,
    facets,
  }
}

module.exports = {
  whitelistAlgoliaPayload,
  whitelistArtworksPayload,
  whitelistCollectionPayload,
  whitelistAllCollectionPayload,
}
