import React, { useEffect } from 'react'

import HR from 'components/HR'
import TrustPilotWidget from 'components/TrustPilot/Widget'
import { MainContent } from 'styles/algolia'
// import Error from 'apps/error/components/Error'
import { setWithoutJsonEncoding as setLocalStorage } from 'lib/localStorage'
import LayoutConnector, { type ConnectorProps as LayoutProps } from '../connectors/Layout'
import Header from '../components/Header'
import FacetsLayout from './Facets'
import ModalLayout from './Modal'
import PaginationHitsPerPageLayout from './PaginationHitsPerPage'
import { ArtworksList } from '../components/artworks'
import { Layout } from '../components/styles'

const ALGOLIA_QUERY_ID = 'algolia_query_id'

const Component = (props: LayoutProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { queryID } = props
  useEffect(() => {
    // store ALGOLIA_QUERY_ID in local storage
    setLocalStorage(ALGOLIA_QUERY_ID, queryID || '')
  })
  return (
    <>
      <Layout>
        <Header />
        <MainContent>
          <FacetsLayout />
          <ArtworksList />
        </MainContent>
      </Layout>
      <HR />
      <PaginationHitsPerPageLayout />
      <TrustPilotWidget />
      <ModalLayout />
    </>
  )
}

export default LayoutConnector(Component)