//@ts-nocheck
import { slugify } from 'lib/slugify'
import {
  getSelectedFacets,
  getAlgoliaFacets,
  getPreviousAlgoliaFacets as selectorGetPreviousAlgoliaFacets,
} from './facets'
export const validSharedFacet = ({
  sharedFacetNames,
  facetValue,
  facetName,
}: Record<string, any>): boolean => {
  // We need to SLUGIFY the Value
  const value = slugify(facetValue)
  // Skip items that SHARE Facet Values Ex: abstract: ['styles', 'subject'],
  // We will abstract for `styles`, but `subject` we will skip
  // EXIT, return if FIRST ITEM is !== FacetName
  return !(sharedFacetNames && sharedFacetNames[value] && sharedFacetNames[value][0] !== facetName)
}
export const getFirstFacetValue = (
  facetName: string,
  facetValues: Array<string>,
  options: Record<string, any>
): string => {
  const [facetValue] = facetValues || [] // Get First Item/Value

  if (facetName === 'has_prints') {
    return facetValue ? 'prints' : ''
  }

  if (facetName === 'category') {
    return facetValue || 'All'
  }

  const { sharedFacetNames } = options

  if (
    !validSharedFacet({
      sharedFacetNames,
      facetValue,
      facetName,
    })
  ) {
    return ''
  }

  return facetValue || ''
}

/*
Helper to remove values from Object
Used specifically for URL creation
Params ({
  facets: {category: [], styles: ['Abstract', 'Fine Art'], medium: ...},
  usedValues: { styles: 'Abstract'}
})
Will only return Values ( Non Empty Arrays )
Result: {styles: ['Fine Art']}
*/
export const removeUsedFacets = ({
  facets,
  usedValues,
}: Record<string, any>): Record<string, any> => {
  const facetResults = { ...facets }
  Object.entries(usedValues).forEach(([facetName, attributeValue]) => {
    // We want to loop through and remove URL Params USED
    if (attributeValue && facets[facetName] && facets[facetName].length) {
      if (Array.isArray(facets[facetName])) {
        facetResults[facetName] = facets[facetName].filter((i) => i !== attributeValue)
      } else if (facetResults[facetName] === attributeValue) {
        facetResults[facetName] = ''
      }
    }
  })
  return facetResults
}

/*
Helper to track last MUTATED facet
Artworks have only 1 Subject, Product would like to be able to select MULTIPLE Subjects
We need a way to track last changed fields to add FACETNAME ( don't care about attributes changed )
Params ( { styles: 'Abstract', subject: ['Photography']}, { styles: 'Abstract'})
Result: [ 'subject' ]
*/
export const getChangedFacets = (
  facets: Record<string, any>,
  usedValues: Record<string, any>
): Array<string> => {
  const changedFacets = []
  // Remove Duplicates so we have all FacetNames
  const facetNameList = Array.from(new Set([...Object.keys(facets), ...Object.keys(usedValues)]))
  // Loop over Every Facet Name and JSON.Stringify and compare VALUES
  facetNameList.forEach((facetName) => {
    // do NOT append CATEGORY ( singular value only )
    if (facetName === 'category') {
      return
    }

    // Do both OBJECT contain the FacetName &&
    if (facets[facetName] && usedValues[facetName]) {
      // Do they MATCH
      if (JSON.stringify(facets[facetName]) === JSON.stringify(usedValues[facetName])) {
        // Do NOT do anything
        return
      }
    }

    // Add facetName to List, there has been a change
    changedFacets.push(facetName)
  })
  return changedFacets
}

/*
We want a way to keep Facet Available even when changing Facets
Algolia may return a thin down list of FACETS, when you Select a facet
Ex: subject: { 'Abstract', 'Aerial' } => Select `Abstract` => Algolia Returns subject: { 'Abstract' }
This is due to the fact Artworks only have one `subject` attached to them, so algolia is correct
We want to keep the full list, until a user selects a new Facet name
Ex: Select `subject` => keep full `subject` list => Select `style` => keep full `style` & update `subject`
*/
export const getPreviousAlgoliaFacets = ({
  selectedFacets,
  store,
}: Record<string, any>): Record<string, any> => {
  // Lets figure out what FacetNames have changed
  const previousFacets = getSelectedFacets({ ...store.value })
  const changedFacets = getChangedFacets(previousFacets, selectedFacets)
  // We need to get and create list of previous Algolia Facets
  const currentAlgoliaFacets = getAlgoliaFacets({ ...store.value })
  const previousAlgoliaFacets = selectorGetPreviousAlgoliaFacets({ ...store.value })
  const changedFacetsFilters = changedFacets.reduce((data, facetName) => {
    if (previousAlgoliaFacets[facetName]) {
      // Use existing `previousAlgoliaFacets`
      data[facetName] = previousAlgoliaFacets[facetName]
    } else {
      // Add new `selectedFacets`
      data[facetName] = currentAlgoliaFacets[facetName]
    }

    return data
  }, {})
  // We know what has changed, but lets make sure its because we added items ( not removed )
  const updateChangedFacets =
    JSON.stringify(previousFacets).length < JSON.stringify(selectedFacets).length
  return updateChangedFacets
    ? {
        changedFacets,
        previousAlgoliaFacets: changedFacetsFilters,
      }
    : {}
}
