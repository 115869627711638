import React, { useReducer } from 'react'
import { InputWithPlaceholder } from 'components/Input'
import type { ConnectorProps as SizeInputsProps } from '../../connectors/SizeInputs'
import SizeInputsConnector from '../../connectors/SizeInputs'
import type { ConnectorProps as URLProps } from '../../connectors/Url'
import URLConnector from '../../connectors/Url'
import { updateUrl } from '../../helpers/url'
import { Field, Input, SizeInputs } from '../styles'
type State = {
  currentSize: string
  heightMax: number | null | undefined
  heightMin: number | null | undefined
  heightError: boolean
  widthMax: number | null | undefined
  widthMin: number | null | undefined
  widthError: boolean
}
const sizePresets = {
  default: {
    heightMin: 0,
    heightMax: 0,
    widthMin: 0,
    widthMax: 0,
  },
  small: {
    title: 'Small',
    cm: {
      heightMin: 0,
      heightMax: 51,
      widthMin: 0,
      widthMax: 51,
    },
    in: {
      heightMin: 0,
      heightMax: 20,
      widthMin: 0,
      widthMax: 20,
    },
  },
  medium: {
    title: 'Medium',
    cm: {
      heightMin: 51,
      heightMax: 97,
      widthMin: 51,
      widthMax: 97,
    },
    in: {
      heightMin: 20,
      heightMax: 38,
      widthMin: 20,
      widthMax: 38,
    },
  },
  large: {
    title: 'Large',
    cm: {
      heightMin: 97,
      heightMax: 152,
      widthMin: 97,
      widthMax: 152,
    },
    in: {
      heightMin: 38,
      heightMax: 60,
      widthMin: 38,
      widthMax: 60,
    },
  },
  oversized: {
    title: 'Oversized',
    cm: {
      heightMin: 152,
      heightMax: 2540,
      widthMin: 152,
      widthMax: 2540,
    },
    in: {
      heightMin: 60,
      heightMax: 1000,
      widthMin: 60,
      widthMax: 1000,
    },
  },
}

const validateNumber = (value: string): number => {
  const validNumber = Number(value)
  return isNaN(validNumber) ? 0 : validNumber
}

const validWidthState = (
  state: State // Valid if NO Min & Max || Min & Min=0, Max, Min < Max
) =>
  (!state.widthMin && !state.widthMax) ||
  ((state.widthMin || state.widthMin === 0) && state.widthMax && state.widthMin <= state.widthMax)

const validHeightState = (
  state: State // Valid if NO Min & Max || Min & Min=0, Max, Min < Max
) =>
  (!state.heightMin && !state.heightMax) ||
  ((state.heightMin || state.heightMin === 0) &&
    state.heightMax &&
    state.heightMin <= state.heightMax)

const validateState = (state: State) => {
  const widthError = !validWidthState(state)
  const heightError = !validHeightState(state)
  return {
    widthError,
    heightError,
  }
}

const getDimension = (size: string, measurementUnit: string): Record<string, any> =>
  !size || !measurementUnit || !sizePresets[size] || !sizePresets[size][measurementUnit]
    ? sizePresets.default
    : {
        currentSize: size, // ...sizePresets[size][measurementUnit], // Prefill with selected value small => 20 x 20
      }

const initialState: State = {
  currentSize: 'custom',
  heightMax: null,
  heightMin: null,
  heightError: false,
  widthMax: null,
  widthMin: null,
  widthError: false,
}

const reducer = (state: State, data: Record<string, any>) => ({ ...state, ...data })

let timer
const timerDuration = 1500
type Props = SizeInputsProps & URLProps

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { measurementUnit, sizeFacet, sizeMinMax, getHref, urlPath } = props
  const [size] = sizeFacet // Array<string> => ['large']

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...sizeMinMax,
    ...(size ? getDimension(size, measurementUnit) : {}),
  })
  const { heightMin, heightMax, widthMin, widthMax, widthError, heightError } = state

  const inputUpdate = (data) => {
    clearTimeout(timer)
    const newState = { ...state, ...data, currentSize: 'custom' }
    const errors = validateState(newState)
    dispatch({ ...data, ...errors })

    if (!errors.widthError && !errors.heightError) {
      timer = setTimeout(() => {
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: 'filter',
            label: `Size - Custom - Width ${newState.widthMin || ''}-${
              newState.widthMax || ''
            } X Height ${newState.heightMin || ''}-${newState.heightMax || ''}`,
          })
        }

        const href = getHref({
          size: '',
          width:
            (newState.widthMin || 0) >= 0 && newState.widthMax
              ? `${newState.widthMin || 0}-${newState.widthMax}`
              : '',
          height:
            (newState.heightMin || 0) >= 0 && newState.heightMax
              ? `${newState.heightMin || 0}-${newState.heightMax}`
              : '',
          page: '', // Reset Page
        })
        const sizeHref = `${urlPath}${href}`
        updateUrl(sizeHref)
      }, timerDuration)
    }

    return timer
  }

  return (
    <SizeInputs>
      <Field>
        <InputWithPlaceholder placeholder='W Min' value>
          <Input
            value={widthMin || widthMin === 0 ? widthMin : ''}
            type='number'
            min='0'
            error={widthError}
            onChange={(ev) =>
              inputUpdate({
                widthMin: validateNumber(ev.target.value),
              })
            }
          />
        </InputWithPlaceholder>{' '}
        -{' '}
        <InputWithPlaceholder placeholder='W Max' value>
          <Input
            value={widthMax || ''}
            type='number'
            min='0'
            error={widthError}
            onChange={(ev) =>
              inputUpdate({
                widthMax: validateNumber(ev.target.value),
              })
            }
          />
        </InputWithPlaceholder>{' '}
        {measurementUnit}
      </Field>

      <Field>
        <InputWithPlaceholder placeholder='H Min' value>
          <Input
            value={heightMin || heightMin === 0 ? heightMin : ''}
            type='number'
            min='0'
            error={heightError}
            onChange={(ev) =>
              inputUpdate({
                heightMin: validateNumber(ev.target.value),
              })
            }
          />
        </InputWithPlaceholder>{' '}
        -{' '}
        <InputWithPlaceholder placeholder='H Max' value>
          <Input
            value={heightMax || ''}
            type='number'
            min='0'
            error={heightError}
            onChange={(ev) =>
              inputUpdate({
                heightMax: validateNumber(ev.target.value),
              })
            }
          />
        </InputWithPlaceholder>{' '}
        {measurementUnit}
      </Field>
    </SizeInputs>
  )
}

export default SizeInputsConnector(URLConnector(Component))