"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
const facetNameToQueryOperator = {
    'artist.country_name': 'OR',
    'artist.iso2_country_code': 'OR',
    'color.color': 'OR',
    'dimensions.depth': 'OR',
    'dimensions.height': 'OR',
    'dimensions.width': 'OR',
    artist_recognition: 'OR',
    bookmark: 'GREATER_OR_EQUAL',
    category: 'OR',
    city: 'OR',
    collection_id: 'OR',
    collection_types: 'OR',
    collection_types_ids: 'OR',
    color: 'OR',
    country_name: 'OR',
    country: 'OR',
    curated_status: 'OR',
    depth: 'OR',
    editions: 'OR',
    has_limited_editions: 'BOOLEAN',
    has_open_editions: 'BOOLEAN',
    has_prints: 'OR',
    height: 'TO',
    is_private: 'OR',
    is_visible: 'OR',
    is_publicly_searchable: 'OR',
    is_safe: 'OR',
    iso2_country_code: 'OR',
    materials: 'OR',
    mediums: 'OR',
    min_print_price: 'TO',
    orientation: 'OR',
    original_available: 'OR',
    original_status: 'OR',
    packaging_option: 'OR',
    price: 'TO',
    prints_availability: 'OR',
    prints_materials: 'OR',
    prints_price_ranges: 'OR',
    prints_size_ranges: 'OR',
    recommended_frame_id: 'OR',
    size: 'OR',
    status_name: 'OR',
    styles: 'OR',
    subject: 'OR',
    toaf_cities: 'OR',
    us_state: 'OR',
    width: 'TO',
};
/*
 * Every CASE in switch needs to return a STRING to build QUERY
 */
const queryInstruction = (facetName, attributeNames, options = {}) => {
    const { measurementUnit, destinationZone } = options;
    try {
        const type = facetNameToQueryOperator[facetName];
        switch (type) {
            // We want to Filter on a Specific ATTRIBUTE ( first item ) => TRUE (only)
            case 'BOOLEAN':
                return (attributeNames || [])
                    .filter(Boolean)
                    .map((attributeName) => (attributeName ? `${facetName}:"${attributeName}"` : ''))
                    .join('');
            case 'AND':
                return (attributeNames || [])
                    .filter(Boolean)
                    .map((attributeName) => `${facetName}:"${attributeName}"`)
                    .join(' AND ');
            case 'TO': {
                const values = Array.isArray(attributeNames) ? attributeNames : attributeNames.split('-');
                if (values.length !== 2) {
                    throw new Error('TO operator accepts exactly 2 attributeNames');
                }
                // Price & MinPrintPrice, need to convert to CENTS
                // TODO - Geo Based
                if (facetName.search(/price/g) > -1) {
                    return `geo_prices.${destinationZone}:${values[0] * 100} TO ${values[1] * 100}`;
                }
                // Height & Width Manipulation: CM to IN
                if (facetName.search(/width|height/g) > -1 && measurementUnit !== 'cm') {
                    return `${facetName}:${(Number(values[0]) * 2.54).toFixed(0)} TO ${(Number(values[1]) * 2.54).toFixed(0)}`;
                }
                // Normal Case
                return `${facetName}:${values[0]} TO ${values[1]}`;
            }
            case 'OR':
                if (Array.isArray(attributeNames)) {
                    return (attributeNames || [])
                        .filter(Boolean)
                        .map((attributeName) => `${facetName}:"${attributeName}"`)
                        .join(' OR ');
                }
                else {
                    return `${facetName}:"${attributeNames}"`;
                }
            case 'GREATER_OR_EQUAL':
                if (attributeNames && typeof attributeNames === 'string') {
                    // Specifically used for Curation Portal and Date Uploaded
                    return `uploaded_date >= ${Number(attributeNames)}`;
                }
            default:
                return '';
        }
    }
    catch (error) {
        console.error(`queryInstruction`, { facetName, attributeNames, error });
        return '';
    }
};
// Options are for additional data needed to parse FACETS
// Ex: MeasurementUnit, we need to Format Size from CM to IN based on user preferences
const selectedFacetsToAlgoliaQueryString = (facets, options) => Object.entries(facets)
    .reduce((query, [facetName, attributeNames]) => [
    ...query,
    // Don't Try Parsing Undefined Items
    facetName && attributeNames ? queryInstruction(facetName, attributeNames, options) : '',
], [])
    .filter(Boolean)
    .filter((item) => typeof item === 'string') // Only build Query with STRING(s)
    .map((facetQuery) => `(${facetQuery})`)
    .join(' AND ');
const selectedFacetsToURLQueryString = (facets) => Object.entries(facets).join(' AND ');
module.exports = {
    selectedFacetsToAlgoliaQueryString,
    selectedFacetsToURLQueryString,
};
