// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import * as AccountStyles from 'styles/account'
import { ArtworkSubText } from './info'  

import MinusCircle from 'svg/minus-circle.svg'
import CheckCircle from 'svg/check-circle.svg'
const { Row, Column } = AccountStyles
export const MasonryWidth = 280
export const gridRowGap = 0
export const gridColumnGap = 80
export const gridAutoRows = 20
export const MinusCircleIcon = styled(MinusCircle)`
  width: 72px;
  height: 72px;
  fill: #fff;
`
export const CheckCircleIcon = styled(CheckCircle)`
  width: 72px;
  height: 72px;
  fill: #fff;
`
export const NoResults = styled.h2`
  color: ${(props) => props.theme.colors.default};
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: 50px auto;
  a {
    color: ${(props) => props.theme.colors.default};
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: 24px;
    margin: 50px auto;
  }
`
export const ArtworkFrame = styled.div`
  padding: 15px 15px 5px 15px;
  background-color: #f0f0f0;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 5px;
  }
`
export const ArtworkItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin: 0 auto 50px;
  &:focus,
  > :focus {
    outline: none;
  }
  ${Row} {
    align-items: flex-start;
    ${Column} {
      flex: 1;
    }
    ${Column} + ${Column} {
      align-items: flex-end;
      ${ArtworkSubText} {
        font-size: ${(props) => props.theme.fontSizes[1]}px;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${Row}[data-type='actions'] {
      align-items: center;
    }
  }
`
export const ArtworksList = styled.div`
  display: grid;
  grid-column-gap: ${gridColumnGap}px;
  grid-row-gap: ${gridRowGap}px;
  grid-template-columns: repeat(auto-fill, minmax(${MasonryWidth}px, 1fr));
  grid-auto-rows: ${gridAutoRows}px;
  grid-row-end: span 1;
  /* transition: grid-template 2s ease, height 2s ease; */
  width: 100%;

  /* Masonry Item */
  ${ArtworkItem} {
    /* transition: grid-template 2s ease, height 2s ease; */
    grid-row-end: span 10;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
  }
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    grid-column-gap: ${gridColumnGap / 2}px;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
`
export const ArtworkOverlayMessage = styled(Column)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s ease opacity;
  opacity: 0;
  backface-visibility: hidden;
  padding: ${(props) => props.theme.space[3]}px;
  color: ${(props) => props.theme.colors.white};
  pointer-events: none;
  ${(props) => props.show && `opacity: 1;`};
  i {
    margin: 20px 0;
    font-size: ${(props) => props.theme.fontSizes[8]}px;
  }
  p {
    margin: 0;
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    text-align: center;
  }
`
export const ArtworkContent = styled(Column)`
  position: relative;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.default};
  }
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
  }
`