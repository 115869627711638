// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import { space, width } from 'styled-system'
import { A } from 'components/A'
import { H2, Serif } from 'components/Serif'

const flexParams = (props) => `
  ${props.justify && `justify-content: ${props.justify};`};
  ${props.align && `align-items: ${props.align};`};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => flexParams(props)};
  ${space};
  ${(props) => props.click && `cursor: pointer;`}
`
export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => flexParams(props)};
  ${width};
  ${space};
`
export const Title = styled(H2)`
  display: block;
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  color: ${(props) => props.theme.colors.default};
`
export const P = styled(Serif)`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.5em;
  color: ${(props) => props.theme.colors.default};
  ${(props) => props.bold && `font-weight: bold;`};
  ${(props) => props.underline && `cursor: pointer; text-decoration: underline;`}
`
export const Bold = styled.p`
  margin: 0;
  ${space};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.3em;
  color: ${(props) => props.theme.colors.default};
  font-weight: bold;
`
export const Link = styled(A)`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.default};
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  @media (max-width: ${(props) => props.theme.maxWidth}) {
    padding: 0 ${(props) => props.theme.space[4]}px;
  }
  width: 100%;
  max-width: ${(props) => `${props.theme.maxWidth}`};
  @media (max-width: 770px) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0 ${(props) => props.theme.space[3]}px;
  }
  fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }
  label {
    min-width: 250px;
  }
`
export const Errors = styled.div`
  color: ${(props) => props.theme.colors.darkRed};
  background: #f2dede;
  width: 100%;
  padding: ${(props) => props.theme.space[3]}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 100%;
  border: 1px solid ${(props) => props.theme.colors.darkRed};
`
export const Label = styled.label`
  ${space};
  font-size: 20px;
  color: ${(props) => props.theme.colors.default};
  margin: ${(props) => props.theme.space[1]}px 0;
`
export const Button = styled.button`
  ${space};
  width: 125px;
  height: 50px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.default};
  text-align: center;
  align-self: flex-end;
  font-weight: 700;
  cursor: pointer;
  will-change: background-color;
  text-decoration: none;
  padding: ${(props) => props.theme.space[2]}px;
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  outline: none;
  ${(props) => props.disabled && `background-color: ${props.theme.colors.backgroundMediumGray};`};
`
export const Textarea = styled.textarea`
  width: 100%;
  min-height: 300px;
  margin: 0;
  padding: 0;
  outline: none;
  border: solid 1px ${(props) => props.theme.colors.borderLight};
  &:focus {
    border-color: ${(props) => props.theme.colors.borderBlue};
  }
  ${space};
`