import React from 'react'
import SALink from 'components/SALink'
import { ArtworksList, NoResults } from 'components/Artworks/styles/list'
import type { ConnectorProps as ListProps } from '../../connectors/List'
import ListConnector from '../../connectors/List'
import Artwork from './Artwork'

const Component = (props: ListProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    algoliaIndice,
    algoliaQueryId,
    algoliaUserToken,
    artworks,
    isMobileScreen,
    isScreenReady,
    artistName,
    isUsersArtworksAccount,
    facetsCount,
  } = props
  const isMobile = isMobileScreen && isScreenReady

  if (isUsersArtworksAccount && !artworks.length) {
    return (
      <NoResults>
        Build your portfolio. Start uploading artworks <SALink href='/upload'>now</SALink>.
      </NoResults>
    )
  }

  if (facetsCount > 0 && !artworks.length) {
    return (
      <NoResults>
        Sorry, there are no artworks that meet your selected criteria. Try to filter for something
        else, or clear your filters to see the selection.
      </NoResults>
    )
  }

  if (!artworks.length) {
    return (
      <NoResults>
        No artworks by {artistName} match your filters. Browse <SALink href='/all'>now</SALink> for
        other artworks.
      </NoResults>
    )
  }

  return (
    <ArtworksList data-device={isMobile ? 'mobile' : 'desktop'}>
      {artworks.map((artwork, index) => {
        const priority = index === 0
        const lazyLoad = index > 3
        return (
          <Artwork
            algoliaIndice={algoliaIndice}
            algoliaQueryId={algoliaQueryId}
            algoliaUserToken={algoliaUserToken}
            artworkId={artwork.artworkId}
            hasPrints={false}
            index={index}
            isMobile={isMobile}
            key={`artwork-${artwork.artworkId}`}
            priority={priority}
            lazyLoad={lazyLoad}
            artwork={artwork}
          />
        )
      })}
    </ArtworksList>
  )
}

export default ListConnector(Component)