import React from 'react'
import { defaultCurrencyRate } from 'lib/i18'
import { formatPrice } from './helpers'
type Props = {
  price: number
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { price } = props
  return <>{formatPrice(price, defaultCurrencyRate)}</>
}

export default Component