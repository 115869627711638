import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import SALink from 'components/SALink'
import { Row } from 'styles/account'
import { Header as HeaderStyle, HeaderTitle } from 'styles/algolia/header'
export { Row }
export const ActionLink = styled(SALink)`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.default};
  text-decoration: none;
  :hover {
    color: ${(props) => props.theme.colors.black};
  }
  :first-child {
    margin-right: 5px;
  }
  :last-child {
    margin-left: 5px;
  }
`
export const ActionsLinks = styled(Row)`
  justify-content: flex-end;
  padding-bottom: 15px;
  span {
    color: ${(props) => props.theme.colors.blue};
  }
  &[data-type='artworksv2'] {
    margin-top: -17px;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-top: 0;
    }
  }
`
export const HeaderActions = styled(Row)`
  margin: 0;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
  [data-type='visii'] {
    flex-grow: 1;
  }
  [data-type='filter'] {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: row-reverse;
    [data-type='filter'] {
      display: flex;
    }
  }
`
export const Header = styled(HeaderStyle)`
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 20px;
  align-items: flex-start;
  ${HeaderActions} {
    max-width: 500px;
    flex-shrink: 1;
  }
  [data-type='filter'] {
    display: none;
  }
  ${HeaderTitle} {
    font-size: 46px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border: none;
    flex-direction: column;
    align-items: flex-start;
    ${HeaderTitle},
    ${HeaderActions} {
      flex-grow: 1;
      width: 100%;
      max-width: initial;
    }
    [data-type='filter'] {
      display: flex;
      width: 100%;
    }
    > ${Row} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
export const OwnerInfoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  > div {
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      flex-direction: column;
    }
  }
`
export const OwnerAvatar = styled.img`
  width: 93px;
  height: 93px;
  margin-right: 20px;
  border-radius: 50%;
`
export const OwnerInfoLine = styled.div`
  color: ${(props) => (props.isLimited ? props.theme.colors.gray : props.theme.colors.default)};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  ${(props) =>
    props.titleLine &&
    `
    &::before {
      margin: 0 ${props.theme.space[1]}px;
      content: '${props.theme.linkSeparator}';
      display: inline-block;
      @media (max-width: ${props.theme.breakpoints[0]}) {
        content: '';
        margin: 0;
      }
    }
  `}
  a {
    color: ${(props) => (props.isLimited ? props.theme.colors.default : props.theme.colors.red)};
    text-decoration: none;
  }
`