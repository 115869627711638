import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
import { hasPrintsSelected } from '../selectors/facets'
const mapStateToProps = createStructuredSelector({
  isSelected: (state, ownProps) => Selectors.isSelectedFacetValue(ownProps || {})(state),
  hasPrints: hasPrintsSelected,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  hasPrints: boolean
  isSelected: boolean
}
export default connect(mapStateToProps, mapDispatchToProps)