import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getSelectedFacetNameAttributes } from 'selectors/algolia2/facets'
import { getMeasurementUnit } from 'selectors/user/session'
import type { MeasurementUnit } from 'types/User/Session'
import 'types/User/Session'
import { getSelectedFacetsSizeMinMax } from '../selectors/facets'
const mapStateToProps = createStructuredSelector({
  measurementUnit: getMeasurementUnit,
  sizeFacet: getSelectedFacetNameAttributes('size'),
  sizeMinMax: getSelectedFacetsSizeMinMax,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  measurementUnit: MeasurementUnit
  sizeFacet: Array<string>
  sizeMinMax: Record<string, any>
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect