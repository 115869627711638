import R from 'ramda'
import React from 'react'
import { withTheme } from 'styled-components'
import { SortIcon } from 'styles/icons/facets'
import { Container, Icon, Dropdown, DropdownItem, ShadowSelect, Value, Label } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  children: Array<React.ReactElement<React.ComponentProps<any>, any>>
  className?: string
  disabled?: boolean
  hideWhenInactive?: boolean
  label?: string
  maxHeight?: string
  onChange: (arg0: unknown) => any
  theme?: Record<string, any>
  value: unknown
  dataSection?: string
}
type State = {
  open: boolean
}
const shadowSelectProps = R.omit(['className', 'label', 'onChange', 'theme'])
export class Select extends React.Component<Props, State> {
  state = {
    open: false,
  }
  getValueText = () => {
    const { children, value } = this.props
    const selectedChild = React.Children.toArray(children).find(
      (child) => child.props.value === value
    )

    if (selectedChild) {
      return selectedChild.props.children
    }

    return value || undefined
  }
  open = () => {
    this.setState(() => ({
      open: true,
    }))
  }
  close = () => {
    this.setState(() => ({
      open: false,
    }))
  }
  handleItemClick = (value: unknown) => () => {
    this.props.onChange(value)

    if (this.container) {
      this.container.blur()
    }
  }
  handleChangeSelect = () => {
    this.props.onChange(this.shadowSelect.value)
  }
  container: HTMLElement | null | undefined
  shadowSelect: HTMLSelectElement

  render() {
    const {
      className,
      children,
      disabled,
      value,
      label,
      hideWhenInactive,
      maxHeight,
      dataSection,
    } = this.props
    const { open } = this.state
    // for browse sort dropdown we need different styling
    // so we pass in attribute called dataSection
    const sortDropdown = dataSection === 'sort-select'
    return (
      <Container
        {...(disabled
          ? {
              disabled,
            }
          : {})}
        className={className}
        hasLabel={!!label}
        ref={(ref) => (this.container = ref)}
        onBlur={this.close}
        onFocus={this.open}
        tabIndex={0}
        data-type='dropdown-container'
        data-section={dataSection}
      >
        {label && (
          <>
            {sortDropdown && <SortIcon />}
            <Label data-type={dataSection} htmlFor='true'>
              {label}
            </Label>
          </>
        )}

          <SATypography variant={SATypographyVariant.SMALL}>
            <Value data-type={sortDropdown ? 'sort-value' : ''}>{this.getValueText()}</Value>
          </SATypography>

        <Dropdown
          active={open}
          {...{
            hideWhenInactive,
            maxHeight,
          }}
        >
          {React.Children.map(children, (option) => (
            <DropdownItem
              active={option.props.value === value}
              onClick={this.handleItemClick(option.props.value)}
            >
              {option.props.children}
            </DropdownItem>
          ))}
        </Dropdown>

        <Icon data-type={sortDropdown ? 'sort-value' : ''} />

        <ShadowSelect
          {...shadowSelectProps(this.props)}
          ref={(ref) => (this.shadowSelect = ref)}
          onChange={this.handleChangeSelect}
          value={value}
          aria-label='hidden'
        >
          {children}
        </ShadowSelect>
      </Container>
    )
  }
}
export default withTheme(Select)