import styled from 'styled-components'
// Icons
export const Collapsable = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  transition: ${(props) => props.duration || `.5`}s ease height;
  will-change: height;
  overflow: hidden;
  ${(props) =>
    props.hidden &&
    `
    position: absolute;
    visibility: hidden;
    height: 0;
  `}
  ${(props) =>
    !props.ready &&
    `
    position: absolute;
    visibility: hidden;
  `}
  ${(props) =>
    props.ready &&
    !props.open &&
    `
    height: 0;
  `}
`