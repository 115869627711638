import { parseUrlFacets } from 'lib/algolia/v2'
import { observerEpicHelper } from 'epics/helpers'
import { Constants as Client } from 'actions/shared/client'
import { Actions as PageActions } from 'actions/page'
import { Actions as AlgoliaDataActions } from 'actions/algolia2/data'
import { Actions as FavoritesActions } from 'actions/user/favorite'
import { updateAppUrl } from 'lib/url/route'
import { Actions as ScrollActions } from 'actions/utility/scroll'
import { isAuthenticated } from 'selectors/user/session'
import { getScreenWidth } from 'selectors/screen'
import { getAlgoliaQueryParams } from '../helpers/url'
import { DEFAULT_APP } from '../helpers/constants'
import { Actions as AppActions, Constants } from '../actions'
import Selectors from '../selectors'
export const getClientSideDataEpic = observerEpicHelper(
  [Client.NEW_CLIENT_COMPLETE],
  async ({ action, observer, store }) => {
    if (isAuthenticated({ ...store.value })) {
      observer.next(FavoritesActions.FETCH())
    }

    observer.complete()
  }
)
export const updateUrlEpic = observerEpicHelper(
  [Constants.UPDATE_URL],
  async ({ action, observer, store }) => {
    const currentPage = Selectors.getPagePath({ ...store.value })

    // Update If Page has CHANGED/Different
    if (currentPage !== action.payload) {
      const href = action.payload
      const app = DEFAULT_APP
      updateAppUrl({
        app,
        href,
      })
    }

    observer.complete()
  }
)
export const clientsideTransitionsEpic = observerEpicHelper(
  [Constants.CLIENTSIDE_TRANSITION],
  async ({ action, observer, store }) => {
    const href = action.payload || ''
    observer.next(PageActions.SET_PATH(href))
    const slugifyDictionary = Selectors.getSlugifyDictionary({ ...store.value })
    const selectedFacets = parseUrlFacets({
      dictionary: slugifyDictionary,
      href,
    })
    // Pull Query Variables ()
    const algoliaParams = getAlgoliaQueryParams(action.payload || '')
    observer.next(PageActions.SET_PARAMS(algoliaParams))
    observer.next(AlgoliaDataActions.SET_SELECTED_FACETS({ ...selectedFacets }))
    observer.next(AppActions.UPDATE_ALGOLIA(algoliaParams))
    observer.complete()
  }
)
export const updateFilterButtonEpic = observerEpicHelper(
  [Constants.FILTER_EVENT],
  async ({ action, observer, store }) => {
    const filtersAvailable = Selectors.areFiltersAvailable({ ...store.value })
    const screenWidth = getScreenWidth({ ...store.value })
    const isMobile = screenWidth < 992
    observer.next(ScrollActions.LOCK_SCROLL(!filtersAvailable && isMobile))
    observer.next(
      PageActions.SET_DATA({
        filtersAvailable: !filtersAvailable,
      })
    )
    observer.complete()
  }
)
export default [
  clientsideTransitionsEpic,
  getClientSideDataEpic,
  updateFilterButtonEpic,
  updateUrlEpic,
]