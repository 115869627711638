"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hitsPerPage = [25, 50, 100];
const facetNameOrder = [
    'category',
    'styles',
    'subject',
    'mediums',
    'materials',
    'price',
    'size',
    'orientation',
    'color',
    'country',
    'print_materials',
    'artist_recognition',
];
const facetNameLabels = {
    category: 'Category',
    styles: 'Style',
    subject: 'Subject',
    mediums: 'Medium',
    materials: 'Material',
    price: 'Price',
    size: 'Size',
    orientation: 'Orientation',
    color: 'Color',
    country: 'Artist Country',
    print_materials: 'Prints For Sale',
    artist_recognition: 'Featured Artist',
};
module.exports = {
    hitsPerPage,
    facetNameOrder,
    facetNameLabels,
};
