// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import { space } from 'styled-system'
import { Row, Column } from 'styles/account'
export { Column, Row, P, Link, Spacer, Title } from 'styles/account'
// Elemnts
export const Bold = styled.p`
  margin: 0;
  ${space};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.3em;
  color: ${(props) => props.theme.colors.default};
  font-weight: bold;
`
export const InfiniteScroller = styled(Row)`
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`
export const InitialLoad = styled.h2`
  color: ${(props) => props.theme.colors.default};
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: ${(props) => props.theme.space[5]}px auto;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
    margin: ${(props) => props.theme.space[5]}px auto;
  }
`
export const MainContent = styled(Row)`
  align-items: flex-start;
  margin: 20px 0 ${(props) => props.theme.space[5]}px;
`
export const ResultCount = styled.p`
  color: ${(props) => props.theme.colors.default};
  color: rgb(102, 102, 102);
  font-size: ${(props) => props.theme.fontSizes[2]}px;
`
export const AppLayout = styled(Column)`
  position: relative;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  min-height: 400px;
  padding: 0 30px;

  @media (max-width: ${(props) => props.theme.maxWidth}) {
    padding: 0 15px;
  }
  [role='button'] {
    outline: none;
  }
  hr {
    margin-bottom: ${(props) => props.theme.space[3]}px;
    background: ${(props) => props.theme.colors.borderLight};
  }
`