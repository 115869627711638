import React from 'react'
import Pagination from 'components/Pagination'
import ResultsCount from 'components/Pagination/ResultsCount'
import { animateScroll } from 'components/ScrollTo'
import { PaginationHitsPerPage, PaginationRow } from 'styles/algolia/pagination'
import type { ConnectorProps as UrlProps } from '../connectors/Url'
import UrlConnector from '../connectors/Url'
import type { ConnectorProps as AlgoliaProps } from '../connectors/Algolia'
import AlgoliaConnector from '../connectors/Algolia'
import SelectHitsPerPage from '../components/SelectHitsPerPage'
type Props = UrlProps & AlgoliaProps
export default UrlConnector(
  AlgoliaConnector((props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
    const {
      hitsPerPage,
      hitsTotal,
      currentPage,
      totalPages,
      updateUrl,
      urlWithParams, // Enable when facets are enabled
    } = props
    return (
      <PaginationHitsPerPage>
        <PaginationRow>
          <Pagination
            current={currentPage + 1}
            total={totalPages}
            onClickLink={(ev) => {
              ev.preventDefault()
              const { href } = ev.currentTarget
              updateUrl(href)
              animateScroll({
                offset: 0,
                duration: 200,
              })
            }}
            urlWithParam={urlWithParams}
          />
          <ResultsCount
            hitsTotal={hitsTotal}
            hitsPerPage={hitsPerPage}
            category='Artwork'
            currentPage={currentPage}
          />
        </PaginationRow>

        <SelectHitsPerPage />
      </PaginationHitsPerPage>
    )
  })
)