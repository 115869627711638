import { createSelector } from 'reselect'
import * as algolia from './algolia'
import * as app from './app'
import * as artworks from './artworks'
import * as facets from './facets'
import * as header from './header'
import * as url from './url'
export const getArtworkProductUrl = (artId: number) =>
  createSelector([algolia.findHitResult(artId)], (artwork): string => {
    const { productUrl, printUrl, originalStatus, hasLimitedEditions, hasOpenEditions } = artwork

    if (originalStatus === 'sold' && printUrl !== '' && (hasLimitedEditions || hasOpenEditions)) {
      return printUrl
    }

    return productUrl || ''
  })
export default { ...algolia, ...app, ...artworks, ...facets, ...header, ...url }