import type { ActionTypes as Action } from 'actions/algolia2/data'
import { Constants } from 'actions/algolia2/data'
export const defaultState = {
  changedFacets: [],
  // list of UPDATED facets
  data: {},
  // Response from Algolia => { hits, pagination, params, query, queryId, facets }
  facets: {},
  // All Facets for a Indice
  hitsPerPage: 0,
  // Used for req query
  isLoadingMore: false,
  // Boolean flag
  previousFacets: {},
  // Keep a list of Facets, until user changes FACET type
  searchIndice: '',
  // Indice to hit
  selectedFacets: {},
  // Users selected Facets
  sharedFacetNames: {},
  // Track shared Facets => { abstract: ['styles', 'subject']}
  slugifyDictionary: {}, // Used to rebuild URL to Facets => { artist_recognition: { saatchi-art-catalog: "Saatchi Art Catalog" } }
}
type State = Record<string, any>

const Reducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.INITIAL_SETUP:
    case Constants.UPDATE:
      return { ...state, ...action.payload }

    case Constants.LOAD_MORE_DATA:
      return { ...state, isLoadingMore: true }

    case Constants.MERGE_HITS_RESULTS: {
      // We accept all new DATA Results, but merge/append PREVIOUS hits together
      // Part of Lazy Loading/Infinite Scroll strategy
      const {
        data: {
          hits: { results: oldResults },
        },
      } = state
      const { hits } = action.payload
      const { results: newResults } = hits
      const mergeHits = { ...hits, results: [...oldResults, ...newResults] }
      return { ...state, data: { ...action.payload, hits: mergeHits }, isLoadingMore: false }
    }

    case Constants.SET_DATA:
      return { ...state, data: action.payload, isLoadingMore: false }

    case Constants.SET_FACETS:
      return { ...state, facets: action.payload }

    case Constants.SET_HITS_PER_PAGE:
      return { ...state, hitsPerPage: action.payload }

    case Constants.SET_SELECTED_FACETS:
      return { ...state, selectedFacets: action.payload }

    case Constants.SET_SLUGIFY_DICTIONARY:
      return { ...state, slugifyDictionary: action.payload }

    default:
      return state
  }
}

export default Reducer