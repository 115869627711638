// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import SALink from 'components/SALink'
import MinusCircle from 'svg/minus-circle.svg'
import CheckCircle from 'svg/check-circle.svg'
import Pencil from 'svg/pencil.svg'
export const MinusCircleIcon = styled(MinusCircle)`
  width: 48px;
  height: 48px;
  fill: #fff;
`
export const CheckCircleIcon = styled(CheckCircle)`
  width: 48px;
  height: 48px;
  fill: #fff;
`
export const PencilIcon = styled(Pencil)`
  position: absolute;
  fill: #333;
  :hover {
    fill: #000000;
  }
  width: 19px;
  height: 19px;
`
export const EditPencil = styled(SALink)`
  background-color: #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
`
export const ArtworkImage = styled.div`
  position: relative;
  display: inline-block;
  a {
    display: flex;
  }
  picture {
    width: 100%;
    align-content: flex-start; /* Safari Bug */
    img {
      height: 100%;
      width: 100%;
      display: block;
      pointer-events: none;
      background: #cccccc;
      visibility: inherit;
    }
  }

  [data-type='overlay'],
  [data-type='actions'] {
    position: absolute;
    opacity: 0;
    transition: 0.2s ease-in-out opacity;
  }

  [data-type='overlay'] {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 21px;
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    p {
      margin: 10px 0;
    }
    &[data-visible] {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  [data-type='actions'] {
    top: 15px;
    right: 15px;
    display: flex;
    a + a {
      margin-left: 5px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      position: relative;
      top: initial;
      right: initial;
      margin: 4px 0px 8px;
      opacity: 1;
      gap: 12px;
      a + a {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  &:hover {
    [data-type='actions'],
    [data-type='overlay'] {
      opacity: 1;
    }
  }
`
