import React, { useState } from 'react'
import Checkbox from 'components/Form/Checkbox'
import type { ConnectorProps as FacetItemProps } from '../../connectors/FacetItem'
import FacetItemConnector from '../../connectors/FacetItem'
import FacetSection from './FacetSection'
import SizeInputs from './SizeInputs'
import { FacetLink, ShowLink } from '../styles'
import type { ConnectorProps as URLProps } from '../../connectors/Url'
import URLConnector from '../../connectors/Url'
import { updateUrl } from '../../helpers/url'
const facetName = 'size'
const facetTitle = 'Size'
const overrideRules = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  oversized: 'Oversized',
}
type Props = {
  title?: string
  // Override Option for VALUE( attributeName )
  attributeName: string
  isHidden: boolean
  onClick: (...args: Array<any>) => any
} & FacetItemProps &
  URLProps
const SizeItem = FacetItemConnector(
  URLConnector(
    (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
      const {
        attributeName,
        getHref,
        isSelected,
        onClick,
        title,
        updateFacetValues,
        urlPath,
      } = props
      const href = getHref({
        [facetName]: updateFacetValues(facetName, attributeName),
        width: '',
        height: '',
        page: '', // Reset Page
      })
      const sizeHref = `${urlPath}${href}`
      return (
        <FacetLink
          role='button'
          tabIndex='0'
          href={href}
          onClick={(ev) => {
            ev.preventDefault()
            onClick()
            updateUrl(sizeHref)
          }}
          {...(isSelected
            ? {
                'data-selected': isSelected,
              }
            : {})}
          data-gtm-event='filter'
          data-gtm-info={`${facetName} - ${attributeName}`}
        >
          <Checkbox checked={isSelected}>
            <p>{title || attributeName}</p>
          </Checkbox>
        </FacetLink>
      )
    }
  )
)

const Component = (
  props: FacetItemProps & URLProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { getHref, hasPrints, urlPath } = props
  const [isOpen, toggleCustom] = useState(false)

  if (hasPrints) {
    return null
  }

  return (
    <FacetSection
      {...{
        facetName,
        facetTitle,
      }}
      showFacet
    >
      {Object.keys(overrideRules).map((attributeName, index) => (
        <SizeItem
          {...{
            attributeName,
            hasPrints,
            facetName,
            // Need for `isSelected` selector
            key: `${facetName}-${attributeName}-${index}`,
            onClick: () => toggleCustom(false),
            title: overrideRules[attributeName],
          }}
        />
      ))}

      <ShowLink
        onClick={() => {
          if (isOpen) {
            // Reset on `REMOVE Custom Size`
            const href = getHref({
              width: '',
              height: '',
              page: '', // Reset Page
            })
            const sizeHref = `${urlPath}${href}`
            updateUrl(sizeHref)
          }

          return toggleCustom(!isOpen)
        }}
      >
        {isOpen ? `Remove` : `Select`} Custom Size
      </ShowLink>

      {isOpen && <SizeInputs />}
    </FacetSection>
  )
}

export default URLConnector(Component)