const getListPriceByGeoZone = (
  listPricesByGeoZone /*: {[destinationZone: string]: number} */,
  destinationZone /*: string */
) /*: ?number */ => {
  const listPrice = listPricesByGeoZone[destinationZone]

  return listPrice ?? null
}

const getFreightAmountByGeoZone = (
  freightAmountsByGeoZone /*: {[destinationZone: string]: number} */,
  destinationZone /*: string */
) /*: ?number */ => {
  const freightAmount = freightAmountsByGeoZone[destinationZone]

  return freightAmount ?? null
}

const isPriceNotNil = (value /*: {[destinationZone: string]: number} */) /* boolean */ =>
  value !== undefined && value !== null

module.exports = { getListPriceByGeoZone, getFreightAmountByGeoZone, isPriceNotNil }
