import { createSelector } from 'reselect'
import { getPageParams } from 'selectors/page'
import { getAlgoliaDataPaginationCurrent } from 'selectors/algolia2/data'
import { searchIndice, sortOptions } from '../helpers/constants'
export { getAlgoliaUserToken } from 'selectors/user/cookie'
export const getAlgolia = (state: Record<string, any>): Record<string, any> => state?.algolia || {}
export const getAlgoliaResults = createSelector(
  getAlgolia,
  (algolia): Record<string, any> => algolia?.data || {}
)
export const getHits = createSelector(
  getAlgoliaResults,
  (algoliaResults): Record<string, any> => algoliaResults?.hits || {}
)
export const getHitsResults = createSelector(
  getHits,
  (hits): Array<Record<string, any>> => hits?.results || []
)
export const findHitResult = (artId: number) =>
  createSelector(
    getHitsResults,
    (results): Record<string, any> => results.find((result) => result.artId === artId) || {}
  )
export const getHitsPerPage = createSelector(getHits, (hits): number => hits.perPage || 100)
export const getHitsTotal = createSelector(getHits, (hits): number => hits.total || 0)
export const getPagination = createSelector(
  getAlgoliaResults,
  (algoliaResults): Record<string, any> => algoliaResults.pagination || {}
)
export const getFriendlyPage = createSelector(
  [getPagination],
  (pagination): number => pagination.current + 1 || 1
)
export const getPaginationCurrent = createSelector(
  [getPagination, getPageParams],
  (pagination, pageParams): number => pagination.current || Number(pageParams.page - 1) || 0
)
export const getPaginationTotal = createSelector(
  getPagination,
  (pagination): number => pagination.total || 0
)
export const getQueryId = createSelector(
  getAlgoliaResults,
  (algoliaResults): string => algoliaResults.queryID || ''
)
export const getSort = createSelector(
  getPageParams,
  (pageParams): string => pageParams.sort || 'default'
)
export const getSortIndice = createSelector([getSort], (sort): string => {
  const currentOption = sortOptions.find((option) => option.value === sort)
  return currentOption ? currentOption.indice : searchIndice
})
export const getAlgoliaQuery = createSelector(
  [getSort, getHitsPerPage, getAlgoliaDataPaginationCurrent],
  (sort, hitsPerPage, page): Record<string, any> => ({
    ...(sort !== 'default'
      ? {
          sort,
        }
      : {}),
    ...(hitsPerPage !== 100
      ? {
          hitsPerPage,
        }
      : {}),
    ...(page !== 0
      ? {
          page: page + 1,
        }
      : {}),
  })
)
export const getFirstResultsArtwork = createSelector([getHitsResults], (results): string => {
  if (results && results[0]) {
    return results[0].imageUrl || ''
  }

  return ''
})
export const getHitsIsSeoValidCount = createSelector([getHitsResults], (results): number =>
  results.reduce((count, { isSeoValid }) => (count += isSeoValid ? 1 : 0), 0)
)
export const getHitsAvailableForSaleCount = createSelector([getHitsResults], (results): number =>
  results.reduce((count, { originalStatus }) => (count += originalStatus === 'avail' ? 1 : 0), 0)
)