import { observerEpicHelper, dispatchFlashMessage } from 'epics/helpers'
import copyToClipboard from 'lib/copyToClipboard'
import { Constants } from 'actions/utility/clipboard'
export const clipboardEpic = observerEpicHelper(
  [Constants.COPY_TEXT],
  async ({ action, observer, store }) => {
    copyToClipboard({
      copyText: action.payload,
      callback: () =>
        dispatchFlashMessage({
          observer,
          message: 'Copied!',
          type: 'success',
        }),
    })
    observer.complete()
  }
)
export default [clipboardEpic]