import React from 'react'
import type { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { getCurrencySymbol } from './helpers'

const Component = (props: ConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { selectedCurrency } = props
  return <>{getCurrencySymbol(selectedCurrency)}</>
}

export default UserPreferencesConnector(Component)