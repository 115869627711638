import React from 'react'
import { filterObject } from 'lib/helpers'
import { Checkbox, Icon, CheckMark, Text } from './Styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = Record<string, any>

const Component = (props: Props) => {
  const { checked, readOnly, text, children } = props
  return (
    <Checkbox readOnly={!!readOnly} data-type='checkbox'>
      <input
        onChange={
          () => {}
          /* React Warning - when no Function */
        }
        type='checkbox'
        {...filterObject(props, ['children'])}
        aria-checked={checked}
      />

      <Icon aria-hidden='true'>{checked && <CheckMark checked={!!checked} />}</Icon>

      {text && (
        <SATypography name='checkbox-text' variant={SATypographyVariant.TINY}>
          {text}
        </SATypography>
      )}

      {children || ''}
    </Checkbox>
  )
}

export default Component
