import React from 'react'
import { ActionItem } from './styles'
import { PencilIcon, EditPencil } from '../Image/styles'
type Props = {
  artworkId: number
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { artworkId } = props
  return (
    <ActionItem data-action='edit' data-type='action' title-='Edit Artwork'>
      <EditPencil
        href={`/studio/art/${artworkId}/image`}
        onClick={() => {
          if (window && window.dataLayer) {
            window.dataLayer.push({
              event: 'edit - start',
            })
          }
        }}
      >
        <PencilIcon />
      </EditPencil>
    </ActionItem>
  )
}

export default Component