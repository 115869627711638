import React from 'react'
import { formatPrice } from 'components/FormatPrice/helpers'
import { FacetLink } from 'styles/algolia/facets'
import type { ConnectorProps as PriceFacetProps } from '../../connectors/PriceFacet'
import PriceFacetConnector from '../../connectors/PriceFacet'
import FacetSection from './FacetSection'
const facetName = 'price'
const facetTitle = 'Price'
const pricePresets = [
  {
    label: 'Under $500',
    presetMin: 0,
    presetMax: 500,
    format: 'under',
  },
  {
    label: '$500 - $1,000',
    presetMin: 500,
    presetMax: 1000,
    format: 'divider',
  },
  {
    label: '$1,000 - $2,000',
    presetMin: 1000,
    presetMax: 2000,
    format: 'divider',
  },
  {
    label: '$2,000 - $5,000',
    presetMin: 2000,
    presetMax: 5000,
    format: 'divider',
  },
  {
    label: '$5,000 - $10,000',
    presetMin: 5000,
    presetMax: 10000,
    format: 'divider',
  },
  {
    label: 'Over $10,000',
    presetMin: 10000,
    presetMax: 500000,
    format: 'over',
  },
]

const createLabel = ({ presetMin, presetMax, format, selectedCurrency }): string => {
  switch (format) {
    case 'under':
      return `Under ${formatPrice(presetMax, selectedCurrency)}`

    case 'over':
      return `Over ${formatPrice(presetMin, selectedCurrency)}`

    case 'divider':
    default:
      return `${formatPrice(presetMin, selectedCurrency)} - ${formatPrice(
        presetMax,
        selectedCurrency
      )}`
  }
}

export default PriceFacetConnector(
  ({
    selectedCurrency,
    updateUrl,
    urlWithParams,
    selectedPrice,
  }: PriceFacetProps): React.ReactElement<React.ComponentProps<any>, any> => (
    <FacetSection
      {...{
        facetName,
        facetTitle,
        customFacet: true,
      }}
      showFacet
    >
      {pricePresets.map(({ label, presetMin, presetMax, format }, index) => {
        const title = createLabel({
          presetMin,
          presetMax,
          format,
          selectedCurrency,
        })
        const isSelected =
          !selectedPrice || (selectedPrice && selectedPrice === `${presetMin}-${presetMax}`)
        const priceHref = urlWithParams({
          price: selectedPrice && isSelected ? '' : `${presetMin}-${presetMax}`,
          page: '', // Reset Page
        })
        return (
          <FacetLink
            role='button'
            tabIndex='0'
            href={priceHref}
            onClick={(ev) => {
              ev.preventDefault()
              updateUrl(priceHref)
            }}
            {...(isSelected
              ? {
                  'data-selected': isSelected,
                }
              : {})}
            key={`${facetName}-${facetTitle}-${index}`}
            data-type='price'
            data-gtm-event='filter'
            data-gtm-info={`${facetName} - ${title}`}
          >
            <p>{title}</p>
          </FacetLink>
        )
      })}
    </FacetSection>
  )
) //