import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  facetNameAttributesList: (state, ownProps) =>
    Selectors.getAvailableFacetNameAttributesList(ownProps.facetName)(state),
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  facetNameAttributesList: Array<string>
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect