import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
export const getAvatarUrl = createSelector(getPageData, (data): string => {
  const sizedAvater = data.avatar
    ? data.avatar.replace('_medium_', '_tiny_')
    : '//d3t95n9c6zzriw.cloudfront.net/common/profile/avatar_tiny_square.jpg'
  return sizedAvater
})
export const getArtistName = createSelector(
  getPageData,
  (data): string => `${data.firstName} ${data.lastName}` || ''
)