import { createSelector } from 'reselect'
import { getPageParams } from 'selectors/page'
import { getAlgoliaQuery } from './algolia'
import { getSelectedFacets } from './facets'
import { urlQuery } from '../helpers/url'
export const getCollectionId = createSelector(
  getPageParams,
  (params): string => params.collectionId || 0
)
export const getOwnerId = createSelector(getPageParams, (params): string => params.ownerId || '')
export const getUserId = createSelector(getPageParams, (params): string => params.userId || '')
export const getTitle = createSelector(getPageParams, (params): string => params.title || '')
export const getUrlPathString = createSelector(
  [getUserId],
  (userId): string => `/account/artworks/${userId}`
)
export const getUrlQueryString = createSelector(
  [getSelectedFacets, getAlgoliaQuery],
  (selectedFacets, algoliaQueryParams): string =>
    urlQuery({ ...selectedFacets, ...algoliaQueryParams })
)
export const getUrlString = createSelector(
  [getUrlPathString, getUrlQueryString],
  (urlPathString, urlQueryString): string => `${urlPathString}${urlQueryString}`
)
export const getUrlStringWithParams = (params: Record<string, any>) =>
  createSelector(
    [getUrlPathString, getSelectedFacets, getAlgoliaQuery],
    (urlPathString, selectedFacets, algoliaQuery): string => {
      const queryParams = { ...selectedFacets, ...algoliaQuery, ...params }
      const queryString = urlQuery(queryParams)
      return `${urlPathString}${queryString}`
    }
  )
export const getUrlStringWithoutParams = (params: Record<string, any>) =>
  createSelector(
    [getUrlPathString, getSelectedFacets, getAlgoliaQuery],
    (urlPathString, selectedFacets, algoliaQuery): string => {
      const queryParams = { ...params }
      const queryString = urlQuery(queryParams)
      return `${urlPathString}${queryString}`
    }
  )