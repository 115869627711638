import React from 'react'
import NavigationLayout from 'layouts/Navigation'
import Layout from './layouts'

const ArtworksLayout = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <NavigationLayout dataApp='userartworks'>
    <Layout />
  </NavigationLayout>
)

export default ArtworksLayout