import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import SALink from 'components/SALink'
import { Button } from 'styles/algolia/button'
import { Row } from 'styles/general'
export const ArtistInfoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  [data-type='artist-row'] {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      flex-direction: column;
      align-items: flex-start;
    }
    [data-type='artist-avatar'] {
      width: 93px;
      height: 93px;
      margin-right: 20px;
      border-radius: 50%;
    }
    [data-type='artist-column'] {
      display: flex;
      flex-direction: column;
      [data-type='artist-name'] {
        margin-top: 0;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
          font-size: 26px;
          white-space: normal;
        }
      }
      [data-type='profile-link'] {
        font-size: 12px;
        color: ${(props) => props.theme.colors.default};
        text-decoration: none;
        font-weight: bold;
        z-index: 2;
        width: 110px;
        svg {
          margin-right: 6px;
          margin-left: 2px;
          height: 9.5px;
          path {
            stroke: ${(props) => props.theme.colors.default};
            fill: ${(props) => props.theme.colors.default};
          }
        }
      }
    }
  }
`
export const FilterButton = styled(Button)`
  ${(props) =>
    props.isOpen &&
    `
    &[data-type='filter'] {
      opacity: 1;
    }
  `}
`
export const FilterButtonRow = styled(Row)`
  // CLS TEST
  //margin-top: 25px;
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-top: 0;
    display: block;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) and (max-width: ${(props) =>
  props.theme.breakpoints[1]}) {
    margin-top: 20px;
    display: block;
  }
`
export const BackToProfile = styled(SALink)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.default};
  text-decoration: none;
  font-weight: bold;
  svg {
    margin-right: 6px;
    margin-left: 2px;
    height: 9.5px;
    path {
      stroke: ${(props) => props.theme.colors.default};
      fill: ${(props) => props.theme.colors.default};
    }
  }
`
export const NavLink = styled(SALink)`
  display: flex;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.default};
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  padding: 14px 20px 14px;
  min-width: 327px;
  width: 33%;
  height: 48px;
  &[data-type='artworks'] {
    background-color: ${(props) => props.theme.colors.default};
    color: ${(props) => props.theme.colors.white};
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 100%;
    margin-bottom: 10px;
    align-self: flex-start;
    min-width: initial;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) and (max-width: ${(props) =>
  props.theme.breakpoints[1]}) {
    min-width: unset;
  }
`