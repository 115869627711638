import React from 'react'
import FacetSection from './FacetSection'
import List from './List'
const facetName = 'category'
const facetTitle = 'Category'

const Category = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <FacetSection facetName={facetName} facetTitle={facetTitle} showFacet>
    <List facetName={facetName} />
  </FacetSection>
)

export default Category