"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
This should contain all helpers/functions to work with parsing URLs
*/
const url = require('url');
const { getFacetName, convertSlugifiedFacetsToNaturalValues, resetSelectedFacets, } = require('./facets');
const { restrictDuplicateValues } = require('./helpers');
/*
This function is meant to find all FacetValues and matching them back to FacetNames
Ex: '/painting/abstract' => { category: ['painting'], style: ['abstract']}
** Make sure no duplicates for a FacetName ( can't have multiple items '/paintings/drawings/' )
** Make sure we handle `sharedFacetNames` to match correct Value to FacetName
*/
const getPathnameFacets = ({ dictionary, pathname, routeParams }) => {
    if (!dictionary || !pathname) {
        // Missing Data - Return empty Object
        return {};
    }
    let emptySelectedFacets = [];
    let dictionaryLookup;
    if (routeParams && Array.isArray(routeParams)) {
        // We KNOW what values to look for, and in what ORDER
        emptySelectedFacets = resetSelectedFacets(routeParams);
        // Custom Dictionary with ONLY routeParams
        dictionaryLookup = routeParams.reduce((data, facetName) => {
            data[facetName] = dictionary[facetName] || {};
            return data;
        }, {});
    }
    else {
        // Create Empty Selected Values - All Values
        emptySelectedFacets = resetSelectedFacets(Object.keys(dictionary));
        // Use default Dictionary
        dictionaryLookup = dictionary;
    }
    // Remove Empty ITEMS Ex: '/some/url///thing' => ['', 'some', '', '', 'thing']
    const list = pathname.split('/').filter(Boolean);
    const paramFacets = list.reduce((results, item) => {
        // Escape and Set Prints
        if (item === 'prints') {
            return Object.assign(Object.assign({}, results), { has_prints: [true] });
        }
        // Find Values => FacetName
        const facetName = getFacetName({
            dictionary: dictionaryLookup,
            name: item,
        });
        // Make sure it doesn't already exist, exit early/move to next param
        // Also don't create a entry if doesn't match a facetName
        if (!facetName || results[facetName]) {
            return results;
        }
        // Add to Results
        return Object.assign(Object.assign({}, results), { [facetName]: [item] });
    }, {});
    return Object.assign(Object.assign({}, emptySelectedFacets), paramFacets);
};
/*
We want to merge params and query together to create our Current Selected Facets
Params are ALWAYS types of Arrays and should also be EMPTY ARRAYS if not set
Ex: Params => { category: ['painting'], styles: [] }, Query => { styles: [abstract],  is_framed: 'true'}
Results => { category: ['painting'], styles: [abstract],  is_framed: 'true' }
*/
const mergeParamsQueryFacets = ({ params, query }) => {
    if (!params || !query) {
        return {};
    }
    const selectedFacets = Object.assign({}, params);
    // merge parameters existing in two or more of the same query objects into an array
    Object.entries(query).forEach(([dirtyFacetName, facetValue]) => {
        // Lets also fix an issue with Parsing Queries and Remove Brackets
        // PROBLEM: We have to convert to create urls styles => ?styles[]=
        // Ex: query = { 'styles[]': ['abstract'] } => { styles: ['abstract'] }
        // CODE => `.replace(/\[\]/g, '')`
        const facetName = dirtyFacetName.replace(/\[\]/g, ''); // CLEAN Version
        // Value exists already
        if (selectedFacets[facetName]) {
            if (Array.isArray(facetValue)) {
                // If query value is already an array, concatenate array values
                selectedFacets[facetName] = [...selectedFacets[facetName], ...facetValue];
            }
            else {
                // Otherwise, make it an array, then concatenate values
                selectedFacets[facetName] = [...(selectedFacets[facetName] || []), facetValue];
            }
        }
        else {
            // New value, add it
            selectedFacets[facetName] = facetValue;
        }
    });
    // Sanity Check, No Duplicate FacetValues
    Object.entries(selectedFacets).forEach(([facetName, facetValue]) => (selectedFacets[facetName] = restrictDuplicateValues(facetValue)));
    return selectedFacets;
};
// Lets Keep FacetsValues in the same Format ARRAY
// Ex: { styles: 'abstract', category: 'painting' price: '500-1000' }
// Results { styles: ['abstract'], category: ['painting'], price: '500-1000' }
const formatFacetsValues = ({ dictionary, selectedFacets }) => {
    Object.keys(dictionary).forEach((facetName) => {
        const facetValue = selectedFacets[facetName];
        if (!Array.isArray(facetValue)) {
            return [facetValue];
        }
    });
    return selectedFacets;
};
const parseUrlFacets = ({ dictionary, href, routeParams }) => {
    const { pathname, query } = url.parse(href, true);
    // Query will already be PRETTY & Parsed, still need to parse pathname
    const paramsFacets = getPathnameFacets({
        dictionary,
        pathname,
        routeParams,
    });
    const mergedFacets = mergeParamsQueryFacets({
        params: paramsFacets,
        query,
    });
    const naturalValues = convertSlugifiedFacetsToNaturalValues({
        dictionary,
        slugifiedFacets: mergedFacets,
    });
    // formatFacetsValues({ dictionary, selectedFacets: naturalValues })
    return naturalValues;
};
module.exports = {
    getPathnameFacets,
    mergeParamsQueryFacets,
    parseUrlFacets,
};
