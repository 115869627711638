import React from 'react'
import { Header } from 'styles/algolia/collection/header'
import { FilterButtonRow } from './styles'
import FilterButton from './FilterButton'
import ArtistInfo from './ArtistInfo'

const HeaderComponent = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <Header>
    <ArtistInfo />

    <FilterButtonRow>
      <FilterButton />
    </FilterButtonRow>
  </Header>
)

export default HeaderComponent