import React, { useState } from 'react'
import Collapsable from 'components/Animations/Collapsable'
import HR from 'components/HR'
import { FacetNameHeader, MinusIcon, PlusIcon } from 'styles/algolia/facets'
import FacetSectionConnector, {
  type ConnectorProps as FacetSectionProps,
} from '../../connectors/FacetSection'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = FacetSectionProps & {
  children: React.ReactElement<React.ComponentProps<any>, any>
  facetTitle: string
  showFacet: boolean
  customFacet: boolean
}
export default FacetSectionConnector((props: Props): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { children, facetTitle, showFacet, isSelected } = props
  const [isOpen, toggleFacet] = useState(showFacet || isSelected || false)
  return (
    <>
      <FacetNameHeader
        {...(isOpen
          ? {
              isOpen: true,
            }
          : {})}
        onClick={() => toggleFacet(isOpen === false)}
      >
        <SATypography variant={SATypographyVariant.H6}>
          {facetTitle}
        </SATypography>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </FacetNameHeader>

      <Collapsable open={isOpen} duration='.3' styles={{marginTop: '16px'}}>
        {children}
      </Collapsable>

      <HR styles={{ margin: '24px 0px !important' }} />
    </>
  )
})