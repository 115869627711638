import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.default};
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  min-width: 100px;
  min-height: 46px;
  padding: 5px 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out opacity;
  outline: none;
  background: transparent;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    outline: none;
  }
  &[data-type='apply'] {
    margin-bottom: 15px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }

  &[data-button='simple'] {
    margin: 0;
    border: none;
    text-decoration: underline;
    padding: 0;
    opacity: 0.7;
    font-size: ${(props) => props.theme.fontSizes[0]}px;
    text-transform: uppercase;
    font-weight: 700;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    &:hover {
      opacity: 1;
    }
  }
  &[data-button='dark'] {
    transition: 0.2s ease color, 0.2s ease-in-out border, 0.2s ease-in-out opacity;
    background: ${(props) => props.theme.colors.default};
    color: ${(props) => props.theme.colors.white};
    border: none;
  }
  &[data-button='blue']:hover {
    transition: 0.2s ease color, 0.2s ease-in-out border, 0.2s ease-in-out opacity;
    opacity: 1;
    color: ${(props) => props.theme.colors.blue};
    border: 1px solid ${(props) => props.theme.colors.blue};
  }
  &[data-type='filter'] {
    flex: 1;
    svg {
      margin-right: 10px;
    }
  }
  &[data-type='seeArt'] {
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-top: 30px;
    }
  }
  &[data-type='clearAll'] {
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      border: none;
      text-decoration: underline;
      margin: 40px 0 0;
      padding: 0;
      opacity: 0.5;
      font-size: ${(props) => props.theme.fontSizes[0]}px;
      text-transform: uppercase;
      font-weight: 700;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      &:hover {
        opacity: 1;
      }
      span {
        display: none;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: flex;
    }
    svg {
      display: none;
    }
    [data-app='curation'] & {
      display: none;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: flex;
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      flex: 1;
      display: flex;
      svg {
        display: block;
        margin-right: 10px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
`