import fetch from 'isomorphic-fetch'
import {
  createFormData,
  getOptions,
  postOptions,
  putOptions,
  deleteOptions,
  parseJSON,
} from 'apis/helpers'
export async function fetchMyCollections(): Promise<Record<string, any>> {
  const url = `/easel_api/my-collections`
  const response = await fetch(url, getOptions)
  const json = await parseJSON(response, 'fetchMyCollections')
  return json
}
export async function fetchMyCollectionsForArtwork(
  artworkId: number
): Promise<Record<string, any>> {
  const url = `/easel_api/my-collections/artwork/${artworkId}`
  const response = await fetch(url, getOptions)
  const json = await parseJSON(response, 'fetchMyCollectionsForArtwork')
  return json
}
export async function postMyCollectionsCreate(
  formData: Record<string, any>
): Promise<Record<string, any>> {
  const url = `/easel_api/my-collections/create`
  const response = await fetch(url, { ...postOptions, body: JSON.stringify(formData) })
  const json = await parseJSON(response, 'postMyCollectionsCreate')
  return json
}
export async function putArtworkInMyCollection({
  artworkId,
  userCollectionId,
}: Record<string, any>): Promise<Record<string, any>> {
  const url = `/easel_api/my-collections/collection/${userCollectionId}/artwork/${artworkId}`
  const response = await fetch(url, putOptions)
  const json = await parseJSON(response, 'putArtworkInMyCollection')
  return json
}
export async function deleteArtworkInMyCollection({
  artworkId,
  userCollectionId,
}: Record<string, any>): Promise<Record<string, any>> {
  const url = `/easel_api/my-collections/collection/${userCollectionId}/artwork/${artworkId}`
  const response = await fetch(url, deleteOptions)
  const json = await parseJSON(response, 'deleteArtworkInMyCollection')
  return json
}
export async function deleteCollection(idUserCollection: number): Promise<Record<string, any>> {
  const url = `/easel_api/my-collection/delete`
  const response = await fetch(url, {
    ...deleteOptions,
    body: JSON.stringify({
      idUserCollection,
    }),
  })
  const json = await parseJSON(response, 'deleteCollection')
  return json
}
export async function duplicateCollection(idUserCollection: number): Promise<Record<string, any>> {
  const url = `/easel_api/my-collection/duplicate`
  const response = await fetch(url, {
    ...postOptions,
    body: JSON.stringify({
      idUserCollection,
    }),
  })
  const json = await parseJSON(response, 'duplicateCollection')
  return json
}
// TODO - OLD LEGACY - DELETE - Need all NEW apis, before we can
const legacyOptions = {
  credentials: 'include',
  method: 'GET',
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // For legacy isXmlHttpRequest check
  },
}
export async function fetchCollectionTooltipApi(): Promise<Record<string, any>> {
  const url = `/collections/get-art-collections`
  const response = await fetch(url, legacyOptions)
  const json = await parseJSON(response, 'fetchCollectionTooltipApi', {
    result: false,
    collections: [],
  })
  return json
}
export async function fetchArtworkCollectionTooltipApi(
  artworkId: number
): Promise<Record<string, any>> {
  const url = `/collections/get-collections-art-is-in/${artworkId || 0}`
  const response = await fetch(url, legacyOptions)
  const json = await parseJSON(response, 'fetchArtworkCollectionTooltipApi')
  return json
}
export async function addArtworkToCollectionApi({
  artworkId,
  collectionId,
  remove,
}: Record<string, any>): Promise<Record<string, any>> {
  // /collections/add-to-collection-by-id-user-art
  const url = remove
    ? `/collections/remove-art-from-collection/${artworkId}/${collectionId}`
    : `/collections/add-art-to-collection?id_user_art=${artworkId}&collection_id=${collectionId}`
  const response = await fetch(url, legacyOptions)
  const json = await parseJSON(response, 'addToCollectionTooltipApi', {
    result: false,
  })
  return json
}
export async function searchCollectionTooltipApi(search: string): Promise<Record<string, any>> {
  const url = `/collections/get-art-collections?title_search=${search}`
  const response = await fetch(url, legacyOptions)
  const json = await parseJSON(response, 'searchCollectionTooltipApi')
  return json
}
export async function createNewCollection(
  formPayload: Record<string, any>
): Promise<Record<string, any>> {
  const formData = createFormData(formPayload)
  const url = `/collections/create`
  const formOptions = { ...legacyOptions, method: 'POST', body: formData }
  const response = await fetch(url, formOptions)
  const json = await parseJSON(response, 'createNewCollection')
  return json
}