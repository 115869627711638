// eslint-disable jsx-a11y/label-has-for
import React, { PureComponent } from 'react'
import { InputWithPlaceholder, Input } from './styles'
type Props = {
  type?: string
  placeholder?: string | React.ReactElement<React.ComponentProps<any>, any>
  value?: string | number | boolean | null
  onChange?: (...args: Array<any>) => any
  children?: React.ReactElement<React.ComponentProps<any>, any>
  autoComplete?: string
  styles?: string
  name?: string
}
type State = {
  value?: string | number | boolean | null
}
// Ensure each instance has a unique id
let uniqId = 0
export default class extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: props.value || '',
    }
  }

  componentWillMount() {
    this.id = uniqId++
  }

  componentWillReceiveProps(nextProps: Props) {
    const { value } = this.props

    // allow setting the value via props
    if (typeof value !== 'undefined') {
      this.setState({
        value,
      })
    }
  }

  onChange = (e: Event) => {
    // $FlowBug ignore this NULL throws errors
    const { value } = e.target
    this.setState({
      value,
    })
    const { onChange } = this.props

    if (onChange) {
      onChange(e)
    }
  }
  getId = () => `input-${this.id}`
  id = 0
  inputEventPollInterval = 0

  render() {
    const { autoComplete, type, placeholder, children, styles, name } = this.props
    const id = this.getId()
    const { value } = this.state
    return (
      <InputWithPlaceholder hasValue={value ? true : null} styles={styles}>
        <label htmlFor={id}>{placeholder}</label>

        {/* Allow passing in of own input element */}
        {!children && (
          <Input
            autoComplete={autoComplete}
            id={id}
            name={name}
            onChange={this.onChange}
            placeholder=''
            type={type}
            value={value}
          />
        )}

        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            id,
            onChange: (e: Event) => {
              this.onChange(e)

              if (child.props.onChange) {
                child.props.onChange(e)
              }
            },
          })
        )}
      </InputWithPlaceholder>
    )
  }
}