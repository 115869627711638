import React from 'react'
import FacetSection from './FacetSection'
import List from './List'
const facetName = 'original_status'
const facetTitle = 'Availability'
const overrideRules = {
  avail: 'For Sale',
  unavail: 'Not For Sale',
  sold: 'Sold',
}
const orderRules = ['avail', 'unavail', 'sold']

const OriginalStatus = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <FacetSection facetName={facetName} facetTitle={facetTitle} showFacet>
    <List
      facetName={facetName}
      overrideRules={overrideRules}
      orderRules={orderRules}
      {...{
        show: true,
      }}
    />
  </FacetSection>
)

export default OriginalStatus