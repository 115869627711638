const whitelistThumbs = (artworks) =>
  [...Array(4)].map((_, i) =>
    artworks[i]
      ? { image: artworks[i].image_url, url: artworks[i].artwork_url }
      : { image: '//d3t95n9c6zzriw.cloudfront.net/common/placeholder-square-93.jpg', url: '' }
  )

const whitelistArtistHits = (hitsArray) =>
  hitsArray.map((data) => ({
    artistAvatar:
      data.avatar_url || '//d3t95n9c6zzriw.cloudfront.net/common/profile/avatar_medium_square.jpg',
    artistId: data.user_id || 0,
    artistCountry: data.country || '',
    artistURL: data.artist_url || '',
    artistFirstName: data.first_name || '',
    artistLastName: data.last_name || '',
    artistFullName: data.full_name || '',
    objectID: data.objectID || '',
    thumbs: whitelistThumbs(data.recent_artworks) || [],
  }))

const whitelistArtistPayload = (json) => {
  const { hits, nbHits, facets, hitsPerPage, nbPages, page, query, queryID } = json || {}
  return {
    hits: {
      results: whitelistArtistHits(hits || []).map((hit, index) => ({
        ...hit,
        positionID: hitsPerPage * page + (index + 1),
      })),
      perPage: hitsPerPage,
      total: nbHits,
    },
    pagination: {
      current: page,
      total: nbPages,
    },
    query,
    queryID,
    facets,
  }
}

module.exports = {
  whitelistArtistPayload,
}
