import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import type { LocaleCurrency } from 'types/Shared/Locale'
import 'types/Shared/Locale'
import { getSelectedCurrency } from 'selectors/user/cookie'
import { Actions as AppActions } from '../actions'
import Selectors from '../selectors'
import { urlWithParams } from '../helpers/url'
const mapStateToProps = createStructuredSelector({
  priceMinMax: Selectors.getSelectedFacetsPrice,
  selectedCurrency: getSelectedCurrency,
  urlWithParams: (state) => urlWithParams(state),
  selectedPrice: Selectors.getSelectedFacetsPrice,
})

const mapDispatchToProps = (dispatch) => ({
  updateUrl: (url: string) => dispatch(AppActions.UPDATE_URL(url)),
})

export type ConnectorProps = {
  priceMinMax: Record<string, any>
  selectedCurrency: LocaleCurrency
  updateUrl: (...args: Array<any>) => any
  urlWithParams: (...args: Array<any>) => any
  selectedPrice: string | null | undefined
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect