import { observerEpicHelper, dispatchFlashMessage } from 'epics/helpers'
import { fetchSession, updateUserMeasurementUnit } from 'apis/session'
import { Actions as SessionActions, Constants } from 'actions/user/session'
import { getSession, isAuthenticated, isSessionSet } from 'selectors/user/session'
export const addSessionDataLayerEpic = observerEpicHelper(
  [Constants.SESSION_DATALAYER],
  async ({ action, observer, store }): Promise<void> => {
    // Add session data if available
    const state = { ...store.value }

    if (isAuthenticated(state)) {
      const { email, userRole, userId, checkInTimerStartedAt } = getSession(state)
      window.dataLayer.push({
        event: 'get_gtm_vars',
        gtm_vars: {
          checkin_date: checkInTimerStartedAt,
        },
      })
      window.dataLayer.push({
        'user category': userRole || 'guest',
        'user email': email || '',
        'user id': userId || '',
      })
    }

    observer.complete()
  }
)
export const fetchSessionEpic = observerEpicHelper(
  [Constants.FETCH_SESSION],
  async ({ action, observer, store }): Promise<void> => {
    const state = { ...store.value }

    // Session has NOT been setup CLIENTSIDE, lets get it
    if (!isSessionSet(state)) {
      const { success, payload } = await fetchSession()

      if (success) {
        observer.next(SessionActions.SET_SESSION(payload))
      }
    }

    observer.complete()
  }
)
export const updateMeasurementUnitAPIEpic = observerEpicHelper(
  [Constants.UPDATE_MEASUREMENT],
  async ({ action, observer, store }): Promise<void> => {
    // TODO - Need API Complete ( Stubbing out for NOW )
    console.log('epic UPDATE_MEASUREMENT')
    const { payload, success } = await updateUserMeasurementUnit(action.payload)

    if (!success) {
      dispatchFlashMessage({
        observer,
        message: 'Problem Updating your Preferences for Measurement Units',
        type: 'error',
      })
    } else {
      dispatchFlashMessage({
        observer,
        message: 'Successfully updated your preferences for measurement units',
        type: 'success',
      })
      observer.next(SessionActions.UPDATE_SESSION(payload))
    }

    observer.complete()
  }
)
export default [addSessionDataLayerEpic, fetchSessionEpic, updateMeasurementUnitAPIEpic]