import { observerEpicHelper } from 'epics/helpers'
import { Constants, Actions as SharedActions } from 'actions/shared'
import { Actions as UserActions } from 'actions/user'
import { Actions as DataLayerActions } from 'actions/shared/dataLayer'
import { fetchClientData } from 'apis/client'
import { getCookie, decodeCookie } from 'lib/cookies'
export const setupClientReadyEpic = observerEpicHelper(
  [Constants.CLIENT_SETUP],
  async ({ action, observer, store }): Promise<void> => {
    /* Lets Fetch All Data
     * Needed for USER because of AKAMAI we do NOT want any USER data
     * In theory we maybe double fetching, but better then not having ENOUGH data
     */
    const { success, payload } = await fetchClientData()

    if (success) {
      const { isBotTraffic, isMobileTraffic, session } = payload
      observer.next(SharedActions.SET_BOT_TRAFFIC(isBotTraffic))

      // STOP setup if `isBotTraffic`
      if (isBotTraffic) {
        observer.complete()
        return
      }

      // Normal USER Flow //
      // Update Session
      observer.next(UserActions.SET_SESSION(session))
      // Update Cart Count
      observer.next(UserActions.GET_CART())

      // Update Mobile Traffic Logic
      if (isMobileTraffic) {
        observer.next(SharedActions.SET_MOBILE_TRAFFIC(isMobileTraffic))
      }

      // Update AB Test Cookie
      const abTestCookie =
        getCookie({
          name: 'saatchi_abtests',
        }) || ''
      const abTestValue = decodeCookie(abTestCookie) // "01.02%2C05.02" => "01.02,05.02"

      observer.next(UserActions.SET_AB_TEST(abTestValue))
      // Update Cookies for Algolia Personalization
      const visitorCookie =
        getCookie({
          name: 'saatchivc',
        }) || ''
      observer.next(
        UserActions.SET_COOKIE({
          saatchivc: visitorCookie,
        })
      )
      // Fetch Locales
      observer.next(SharedActions.FETCH_LOCALES())
      // Document Setup
      observer.next(UserActions.SESSION_DATALAYER())
      observer.next(SharedActions.SET_LOCALE_IN_DATALAYER())
      // via Product -> 3rd parties (eg attentive, BX, BC) key off of that so they don't fire their campaigns
      // window.dispatchEvent(new Event('userLoaded'))
      observer.next(
        DataLayerActions.UPDATE_DATALAYER({
          event: 'userLoaded',
        })
      )
      observer.next(SharedActions.INSTANA_USER_SETUP())
    }

    // Setup Complete
    observer.next(SharedActions.CLIENT_COMPLETE())
    observer.complete()
  }
)
export default [setupClientReadyEpic]