import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import SALink from 'components/SALink'
import AngleRight from 'svg/angle-right.svg'
import AngleLeft from 'svg/angle-left.svg'
import AngleDoubleLeft from 'svg/angle-double-left.svg'
import AngleDoubleRight from 'svg/angle-double-right.svg'
export const PageLinkWrapper = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
    justify-content: center;
  }
  [data-type='disable-previous'],
  [data-type='disable-next'] {
    background-color: #eeeeee;
  }
  [data-type='page-ellipses'] {
    height: 40px;
    margin: 5px 12px 20px 31px;
    padding: 11px 0px 10px 0px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      display: none;
    }
  }
  [data-type='current-page'] {
    background-color: #333333;
    color: #ffffff;
  }
  [data-type='not-current-page'],
  [data-type='previous'],
  [data-type='next'],
  [data-type='disable-previous'],
  [data-type='disable-next'] {
    background-color: #eeeeee;
    color: #333333;
  }
  [data-type='previous'],
  [data-type='current-page'],
  [data-type='not-current-page'],
  [data-type='next'],
  [data-type='disable-previous'],
  [data-type='disable-next'] {
    width: auto;
    height: 40px;
    margin: 5px 0px 20px 18px;
    padding: 11px 15px 10px 16px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.2s ease color;
    text-decoration: none;
    text-align: center;
    &:focus {
      outline: 0;
    }
    [data-type='no-previous'],
    [data-type='no-next'] {
      path {
        stroke: #cccccc;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='current-page'],
    [data-type='not-current-page'] {
      display: none;
    }
    [data-type='previous'],
    [data-type='next'] {
      background-color: #333333;
    }
    [data-type='previous'],
    [data-type='disable-previous'] {
      margin-left: 0px;
      &::after {
        margin-left: 10px;
        content: 'Back';
      }
    }
    [data-type='next'],
    [data-type='disable-next'] {
      &::before {
        margin-right: 10px;
        content: 'Next';
      }
    }

    [data-type='previous'],
    [data-type='next'],
    [data-type='disable-previous'],
    [data-type='disable-next'] {
      display: flex;
      margin-top: 0px;
      flex-direction: row;
      width: 100%;
      max-width: 156px;
      height: 40px;
      padding: 11px 47px 10px;
      font-size: 16px;
      font-weight: bold;
      transition: 0.2s ease color;
      text-decoration: none;
      text-align: center;
      color: #ffffff;
      -webkit-text-fill-color: #ffffff;
      -webkit-appearance: none;
      &:focus {
        outline: 0;
      }
      @media (max-width: 320px) {
        max-width: 137px;
        padding: 11px 35px 10px;
      }
      @media (max-width: 280px) {
        max-width: 120px;
        padding: 11px 25px 10px;
      }
      svg {
        margin-top: -7px;
        width: 15px;
        height: 30px;
        path {
          fill: #ffffff;
          stroke: #ffffff;
          stroke-width: 0px;
        }
      }
    }
  }
`
export const MobilePageLink = styled(SALink)`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 156px;
  height: 40px;
  padding: 11px 47px 10px;
  background-color: #333333;
  font-size: 16px;
  font-weight: bold;
  transition: 0.2s ease color;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
  [data-type='no-previous'],
  [data-type='no-next'] {
    path {
      stroke: #cccccc;
      fill: #cccccc;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
  @media (max-width: 320px) {
    max-width: 137px;
    padding: 11px 35px 10px;
    &:first-child {
      margin-right: 16px;
    }
  }
  @media (max-width: 280px) {
    max-width: 120px;
    padding: 11px 25px 10px;
    &:first-child {
      margin-right: 10px;
    }
  }
  &:first-child {
    margin-right: 23px;
  }
  svg {
    margin-top: -7px;
    width: 15px;
    height: 30px;
    path {
      fill: #ffffff;
      stroke: #ffffff;
      stroke-width: 0px;
    }
  }
`
export const AngleRightIcon = styled(AngleRight)`
  pointer-events: none;
  width: 9px;
  height: 19px;
  path {
    stroke: #333333;
  }
`
export const AngleLeftIcon = styled(AngleLeft)`
  pointer-events: none;
  width: 9px;
  height: 19px;
  path {
    stroke: #333333;
  }
`
export const AngleDoubleLeftIcon = styled(AngleDoubleLeft)`
  width: 9px;
  height: 19px;
  path:hover {
    stroke: #000;
  }
`
export const AngleDoubleRightIcon = styled(AngleDoubleRight)`
  width: 16px;
  height: 16px;
  path:hover {
    stroke: #000;
  }
`
export const ResultsCountWrapper = styled.div`
  [data-type='no-results'],
  [data-type='results-count'] {
    color: #333333;
    text-align: center;
    margin: 0;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-bottom: 10px;
    }
  }
`