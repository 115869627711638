"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
// Flow is to problematic using document.body[attributes || functions]
// type Params = { copyText: string, callback: Function }
const copyToClipboard = ({ copyText, callback }) => {
    try {
        if (!copyText || typeof document === 'undefined') {
            return;
        }
        const copyInput = document.createElement('input');
        document.body.appendChild(copyInput);
        copyInput.setAttribute('id', 'copyInput');
        document.getElementById('copyInput').value = copyText;
        copyInput.select();
        try {
            document.execCommand('copy');
            document.body.removeChild(copyInput);
            if (callback) {
                callback();
            }
        }
        catch (err) {
            console.error({
                err,
            });
        }
    }
    catch (err) {
        console.error('copyToClipboard Error', err);
    }
};
exports.default = copyToClipboard;
