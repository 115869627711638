import { observerEpicHelper, checkAuthorization } from 'epics/helpers'
import { getUserID } from 'selectors/user/session'
import { fetchFavoritesApi, updateFavoriteApi } from 'apis/favorites'
import { Actions as AlgoliaActions } from 'actions/algolia'
import { Constants, Actions as FavoriteActions } from 'actions/user/favorite'
export const fetchFavoritesEpic = observerEpicHelper(
  [Constants.FETCH],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      observer.next(FavoriteActions.FETCH_COMPLETE(await fetchFavoritesApi()))
    }

    observer.complete()
  }
)
export const updateFavoriteEpic = observerEpicHelper(
  [Constants.UPDATE],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      const userId = getUserID(state)
      updateFavoriteApi({ ...action.payload, userId }).then((resp, err) => {
        observer.next(
          AlgoliaActions.EVENT({
            eventName: !action.payload.favorite ? 'favorite' : 'unfavorite',
            eventType: 'view',
            objectIDs: [String(action.payload.id)],
          })
        )
        observer.next(FavoriteActions.SHOW_MESSAGE(action.payload))
        observer.complete()
      })
    }
  }
)
export const updateCompleteFavoriteEpic = observerEpicHelper(
  [Constants.UPDATE_COMPLETE],
  async ({ action, observer, store }): Promise<void> => {
    window.dataLayer.push({
      event: !action.payload.favorite ? 'favorite success' : 'unfavorite success',
    })
    observer.complete()
  }
)
let timer
export const showFavoriteMessageEpic = observerEpicHelper(
  [Constants.SHOW_MESSAGE],
  async ({ action, observer, store }): Promise<void> => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      observer.next(FavoriteActions.CLEAR_MESSAGE())
      observer.complete()
    }, 5000)
  }
)
export default [
  fetchFavoritesEpic,
  updateFavoriteEpic,
  updateCompleteFavoriteEpic,
  showFavoriteMessageEpic,
]