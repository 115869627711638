import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getScreenWidth, getScreenIsReady } from 'selectors/screen'
import {
  getAlgoliaDataPaginationCurrent,
  getAlgoliaDataPaginationTotal,
} from 'selectors/algolia2/data'
import { Actions } from '../actions'
import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  artworks: Selectors.getHitsResults,
  getArtwork: (state, ownProps) => Selectors.findHitResult(ownProps.artId)(state),
  hitsPerPage: Selectors.getHitsPerPage,
  hitsTotal: Selectors.getHitsTotal,
  isReady: getScreenIsReady,
  currentPage: getAlgoliaDataPaginationCurrent,
  totalPages: getAlgoliaDataPaginationTotal,
  queryId: Selectors.getQueryId,
  userId: Selectors.getUserId,
  screenWidth: getScreenWidth,
  sort: Selectors.getSort,
})

const mapDispatchToProps = (dispatch) => ({
  updateHitsPerPage: (payload: number) => dispatch(Actions.UPDATE_HITS_PER_PAGE(payload)),
})

export type ConnectorProps = {
  artworks: Array<Record<string, any>>
  getArtwork: Record<string, any>
  hitsPerPage: number
  hitsTotal: number
  isReady: boolean
  currentPage: number
  totalPages: number
  queryId: string
  userId: number
  sort: string
  screenWidth: number
  updateHitsPerPage: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect