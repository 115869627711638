// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
const FLEX = `
  display: flex;
  flex-direction: row;
  justify-contend: center;
  align-items: flex-start;
`
const ELLIPSE = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`
export const Item = styled.div`
  ${FLEX};
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    gap: 4px;
  }

  [data-style='row'] {
    ${FLEX};
    width: 100%;
  }

  [data-style='column'] {
    ${FLEX};
    flex-direction: column;
    &[data-type='prices'] {
      align-items: flex-end;
      gap: 4px;
    }
  }

  a {
    ${ELLIPSE};
    text-decoration: none;
    color: #333333;
    transition: color 0.5s ease 0s;

    &:hover {
      color: #000000;
    }
  }

  [data-type='artwork-info'] {
    [data-type='artwork-details'] {
      [data-type='main'] {
        width: 100%;
      }
    }
  }

  [data-type='artwork-info'],
  [data-type='artist-info'],
  [data-type='prices'] {
    [data-type='sub'] {
      display: flex;
      flex-direction: row;
    }
  }

  [data-type='artist-info'] {
    [data-type='artwork-details'] {
      [data-type='main'] {
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          width: 100%;
        }
      }
    }
  }

  [data-style='row'] {
    [data-style='column'] {
      width: 60%;
      + [data-style='column'] {
        width: 40%;
      }
    }
  }

  a[data-type='view-more'] {
    font-size: ${(props) => props.theme.fontSizes[2]}px;
    text-decoration: none;
    display: inline-block;
    transition: 0.5s ease color;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.lightGray};
    }
  }

  [data-type='status'] {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    [data-status='sold'] {
      background-color: ${(props) => props.theme.colors.darkerRed};
      color: ${(props) => props.theme.colors.white};
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 50%;
      height: 37px;
      width: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        position: absolute;
        top: -85px;
        right: 5px;
      }
    }
    [data-status='reserved'] {
      color: ${(props) => props.theme.colors.darkerRed};
      font-size: ${(props) => props.theme.fontSizes[2]}px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
  }

  /* MOBILE */
  /* &[data-device='mobile'] { */
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: 0px;
    position: relative;
    overflow: visible;

    [data-type='info'] {
      margin: 0;
      gap: 4px;
    }

    [data-type='artwork-info'] {
      margin: 0;
      p,
      a {
        color: #333333;
      }
    }

    [data-type='sub'],
    [data-type='view-more'] {
      display: none !important;
    }

    p,
    a {
      color: #1E1E1E;
    }

    [data-type='artwork-details'] {
      display: flex;
      flex-direction: column;
    }

    [data-style='row'] {
      align-items: center;
      flex-direction: column;
      [data-style='column'],
      [data-style='column'] + [data-style='column'] {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  [data-type='artwork-details'] {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`
export const ArtworkTitle = styled.div`
  ${FLEX};
  flex-direction: column;
  display: block;
`
