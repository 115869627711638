import React from 'react'
import SALink from 'components/SALink'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyColor, SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { country, firstName, handleViewArtist, isSeoValid, lastName, profileUrl } = props
  const { isMobile } = useScreenSize()

  return (
    <div data-style='column' data-type='artist-info'>
      <div data-type='artwork-details'>
        <div data-type='main'>
          <SALink
            alt={profileUrl}
            data-type='profile-url'
            href={profileUrl}
            onClick={handleViewArtist}
            rel={isSeoValid ? null : 'nofollow'}
            title={`View artist ${firstName} ${lastName} profile`}
          >
            <SATypography data-type='artist-name' color={SATypographyColor.GUNMETAL} truncateText variant={isMobile ? SATypographyVariant.X_SMALL : SATypographyVariant.H6}>
              {firstName} {lastName}
            </SATypography>
          </SALink>
        </div>
        <div data-type='sub'>
          <SATypography truncateText variant={SATypographyVariant.X_SMALL}>
            {country}
          </SATypography>
        </div>
      </div>
    </div>
  )
}

export default Component
