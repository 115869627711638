import algoliasearch from 'algoliasearch'
import fetch from 'isomorphic-fetch'
import {
  whitelistAlgoliaPayload,
  whitelistAllCollectionPayload,
} from 'server/apis/algolia/endpoints/artworks/whitelist'
import { whitelistArtistPayload } from 'server/apis/algolia/endpoints/artist/whitelist'
const { ALGOLIA_APPLICATION_ID, ALGOLIA_ENV, ALGOLIA_SEARCH_API, APP_ENV } = process.env
const algoliaEnv: string = ALGOLIA_ENV || APP_ENV || 'production'
const defaultSearchIndice: string = `${algoliaEnv}_all_artworks`
const browseSearchIndice: string = `${algoliaEnv}_for_sale_artworks`

// Internal Express Route to dispatch Algolia Events - Just Fire, don't worry about RESPONSE
// Minimal Payload - { eventName: string, eventType: string, index: string, userToken: string }
export const algoliaEventsApi = (payload: Record<string, any>): void => {
  fetch(`/easel_api/algolia/events`, {
    body: JSON.stringify({ ...payload }),
    headers: {
      'Content-Type': 'application/json',
    },
    // keepalive: true, TODO - more research, not an issue currently
    method: 'POST',
    credentials: 'include',
  })
}
// API is meant to be used with all SETUP being done outside (NO Redux Store)
// No Parsing/Joining instructions
// Use lib/browse/api/algolia if using a Redux Store
export const algoliaApi = (
  data: Record<string, any>,
  cb?: (...args: Array<any>) => any
): Promise<Record<string, any>> => {
  try {
    const {
      applicationId = ALGOLIA_APPLICATION_ID,
      facets = '*',
      filters,
      hitsPerPage = 25,
      isBotTraffic,
      page = 0,
      query,
      restrictSearchableAttributes,
      // Limit certain attributes to SEARCH against
      searchApi = ALGOLIA_SEARCH_API,
      searchIndice,
      userToken,
      typoTolerance, // Specificity of typo tolerance
      isBrowseSearch,
    } = data
    const options = {
      analytics: !isBotTraffic,
      analyticsTags: ['web'],
      clickAnalytics: true,
      enablePersonalization: false,
      // TODO: Add in personalization
      facetingAfterDistinct: true,
      facets,
      filters,
      hitsPerPage,
      page: page > 0 ? page - 1 : 0,
      // Fix for Algolia Indexing of Pages [1=>0,2=>1,etc]
      query: decodeURI(query || ''),
      userToken,
      typoTolerance,
      ...(restrictSearchableAttributes
        ? {
            restrictSearchableAttributes,
          }
        : {}),
    }
    const fallbackSearchIndex = isBrowseSearch ? browseSearchIndice : defaultSearchIndice
    const client = algoliasearch(applicationId, searchApi)
    const index = client.initIndex(searchIndice || fallbackSearchIndex)
    return index.search(options, cb)
  } catch (error) {
    console.error('Algolia API Failed', {
      error,
    })
    return Promise.resolve({
      algoliaResults: {
        exhaustiveFacetsCount: false,
        exhaustiveNbHits: false,
        facets: {},
        hits: [],
        hitsPerPage: 25,
        nbHits: 0,
        nbPages: 0,
        page: 0,
        params: '',
        query: '',
      },
    })
  }
}
export const algoliaBrowseApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi({ ...params, isBrowseSearch: true })
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaFeatureApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaCollectionApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaArtworksApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaCurationApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaAASelectionArtworksApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const { destinationZone } = params
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAlgoliaPayload(algoliaResults, destinationZone)
  return data
}
export const algoliaAccountCollectionApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAllCollectionPayload(algoliaResults)
  return data
}
export const algoliaCollectionsBrowseApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAllCollectionPayload(algoliaResults)
  return data
}
export const algoliaArtistSearchApi = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const algoliaResults = await algoliaApi(params)
  const data = whitelistArtistPayload(algoliaResults)
  return data
}
export const algoliaAAQuizCollectionsAPI = async (
  params: Record<string, any>
): Promise<Record<string, any>> => {
  const algoliaResults = await algoliaApi(params)
  const data = whitelistAllCollectionPayload(algoliaResults)
  return data
}
export default algoliaApi
