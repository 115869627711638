import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'

/* -------------------------------------- */
export const getCollectionsTotal = createSelector(
  getPageData,
  (data): number => data.collectionsTotal || 0
)
export const getArtworksCount = createSelector(
  getPageData,
  (data): number => data.artworksCount || 0
)
export const getFavoritesTotal = createSelector(
  getPageData,
  (data): number => data.favoritesTotal || 0
)