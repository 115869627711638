import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  hasValidPermissions: Selectors.getHasValidPermissions,
  isUsersArtworksAccount: Selectors.getIsUsersArtworksAccount,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  hasValidPermissions: boolean
  isUsersArtworksAccount: boolean
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect