import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
export const getDatalayer = createSelector(getPageData, (data) => {
  const layerData = {
    pagetype: 'artistProfile',
    subpagetype: 'artworks',
    subsubpagetype: '',
  }
  layerData.subsubpagetype = `${data.firstName} ${data.lastName}` || ''
  return layerData
})