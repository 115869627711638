import React from 'react'
import withRedux from 'next-redux-wrapper'
import Theme from 'components/Theme'
import { App, Store } from 'apps/user/artworks'
import { Actions as AppActions } from 'apps/user/artworks/actions'
import { Actions as AlgoliaActions } from 'actions/algolia2/data'
import { Actions as PageActions } from 'actions/page'
import { storeSetup } from 'lib/pageSetup'
import { getMetaData } from 'apps/user/artworks/selectors/app'
import { getDatalayer } from 'apps/user/artworks/selectors/dataLayer'
type Props = {
  dataLayer: Record<string, any>
  error: boolean
}

function AccountArtworks(props: Props): React$ElementRef<any> {
  return (
    <Theme>
      <App />
    </Theme>
  )
}

AccountArtworks.clientSideTransitions = async ({ store, query }) => {
  if (window) {
    store.dispatch(AppActions.CLIENTSIDE_TRANSITION(window.location.href))
    window.dataLayer.push({
      event: 'pageview',
    })
  }
}

AccountArtworks.getInitialProps = async ({ req, store, res, query }) => {
  // Client-Side
  if (!req) {
    AccountArtworks.clientSideTransitions({
      store,
      query,
    })
    const state = { ...store.getState() }
    const metaData = getMetaData(state)
    return {
      metaData,
    }
  }

  // SSR
  storeSetup(store, req)
  const { page, algolia } = query
  store.dispatch(PageActions.SET(page))
  store.dispatch(AlgoliaActions.INITIAL_SETUP(algolia))
  // Akamai tag
  const {
    params: { userId },
  } = query.page
  res.set(
    'Edge-Cache-Tag',
    `page-accountArtworks, userId-${userId}, page-accountArtworks-${userId}`
  )
  // Metadata & Datalayer
  const state = { ...store.getState() }
  const metaData = getMetaData(state)
  const dataLayer = getDatalayer(state)
  return {
    dataLayer,
    metaData,
  }
}

export default withRedux(Store)(AccountArtworks)