import React from 'react'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import { LocaleCurrency } from 'components/FormatPrice'
import ArtworkInfo from './Artwork'
import ViewMore from './ViewMore'
import OriginalStatus from './Status'
import ArtistInfo from './Artist'
import { getArtworkProps, getArtistProps, getViewMoreProps } from './helpers'
import { Item } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    shouldOpenNewDesktopPdpTab,
    handleViewArtist,
    handleViewMore,
    handleViewProduct,
    hasLimitedEditions,
    hasOpenEditions,
    minPrintPrice,
    originalStatus,
    listPrice,
  } = props
  const artworkProps = getArtworkProps(props)
  const artistProps = getArtistProps(props)
  const viewMoreProps = getViewMoreProps(props)
  const shouldShowPrice = !['unavail', 'reserved'].includes(originalStatus)
  return (
    <Item data-type='original'>
      <div data-style='row' data-type='info'>
        <ArtworkInfo
          handleViewProduct={handleViewProduct}
          {...artworkProps}
          shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
        />

        <OriginalStatus originalStatus={originalStatus} />
      </div>

      <div data-style='row' data-type='info'>
        <ArtistInfo handleViewArtist={handleViewArtist} {...artistProps} />

        <div data-style='column' data-type='prices'>
          {shouldShowPrice && (
            <div data-type='main'>
              <SATypography truncateText variant={SATypographyVariant.H6}>
                <LocaleCurrency price={convertFromCentsToDollars(listPrice)} />
              </SATypography>
            </div>
          )}

          {hasOpenEditions && (
            <SATypography data-type='sub' variant={SATypographyVariant.X_SMALL}>
              Prints from <LocaleCurrency price={convertFromCentsToDollars(minPrintPrice)} />
            </SATypography>
          )}

          {hasLimitedEditions && (
            <SATypography data-type='sub' variant={SATypographyVariant.X_SMALL}>
              Limited Editions from&nbsp;
              <LocaleCurrency price={convertFromCentsToDollars(minPrintPrice)} />
            </SATypography>
          )}
          <ViewMore handleViewMore={handleViewMore} {...viewMoreProps} />
        </div>
      </div>
    </Item>
  )
}

export default Component