import { observerEpicHelper } from 'epics/helpers'
import { algoliaEventsApi } from 'apis/algolia'
import { Constants } from 'actions/algolia'
import { getIndice } from 'selectors/algolia'
import { getAlgoliaUserToken } from 'selectors/user/cookie'
export const payloadFormatter = (data: Record<string, any>): Record<string, any> => {
  // Algolia Object IDs must be Array<string>
  const { objectIDs } = data

  if (objectIDs) {
    data.objectIDs = objectIDs.map(String)
  }

  // Algolia Object must contain an Indice
  return data
}
export const algoliaEventEpic = observerEpicHelper(
  [Constants.EVENT],
  async ({ action, observer, store }): Promise<void> => {
    const state = { ...store.value }
    const searchIndice = getIndice(state)
    const userToken = getAlgoliaUserToken(state)
    algoliaEventsApi({
      events: [
        {
          index: searchIndice,
          userToken,
          ...payloadFormatter(action.payload),
        },
      ],
    })
    observer.complete()
  }
)
export default [algoliaEventEpic]