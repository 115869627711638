import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getScreenWidth, getScreenIsReady, getIsMobileScreenWidth } from 'selectors/screen'
import { getAlgoliaUserToken } from 'selectors/user/cookie'
import Selectors from '../selectors'
const mapStateToProps = createStructuredSelector({
  artworks: Selectors.getHitsResults,
  isReady: getScreenIsReady,
  screenWidth: getScreenWidth,
  artistName: Selectors.getArtistName,
  facetsCount: Selectors.getSelectedFacetsCount,
  isUsersArtworksAccount: Selectors.getIsUsersArtworksAccount,
  algoliaIndice: Selectors.getSortIndice,
  algoliaQueryId: Selectors.getQueryId,
  algoliaUserToken: getAlgoliaUserToken,
  isMobileScreen: getIsMobileScreenWidth,
  isScreenReady: getScreenIsReady,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artworks: Array<Record<string, any>>
  isReady: boolean
  screenWidth: number
  artistName: string
  facetsCount: number
  isUsersArtworksAccount: boolean
  algoliaIndice: string
  algoliaQueryId: string
  algoliaUserToken: string
  isMobileScreen: boolean
  isScreenReady: boolean
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect