import React from 'react'
import { FacetActions, CloseIcon } from 'styles/algolia/facets'
import { FacetsLayout } from '../components/styles'
import type { ConnectorProps as FilterProps } from '../connectors/Filter'
import FilterConnector from '../connectors/Filter'
import {
  CategoryFacet,
  ClearAllFilters,
  MobileSeeArt,
  OrientationFacet,
  OriginalStatusFacet,
  PriceFacet,
  SizeFacet,
} from '../components/facets'
export default FilterConnector(
  (props: FilterProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { areFiltersAvailable, filterEvent } = props
    return (
      <FacetsLayout isVisible={areFiltersAvailable} offset={113}>
        <FacetActions>
          <ClearAllFilters />
          <CloseIcon onClick={filterEvent} />
        </FacetActions>
        <OriginalStatusFacet />
        <CategoryFacet />
        <PriceFacet />
        <SizeFacet />
        <OrientationFacet />
        <MobileSeeArt />
      </FacetsLayout>
    )
  }
)