import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Selectors from '../selectors'
import { Actions as AppActions } from '../actions'
const mapStateToProps = createStructuredSelector({
  areFiltersAvailable: Selectors.areFiltersAvailable,
  facetCount: Selectors.getSelectedFacetsCount,
})

const mapDispatchToProps = (dispatch) => ({
  filterEvent: () => dispatch(AppActions.FILTER_EVENT()),
})

export type ConnectorProps = {
  areFiltersAvailable: boolean
  facetCount: number
  filterEvent: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect