import { createSelector } from 'reselect'
import type { AlgoliaState, AlgoliaResults } from 'types/Algolia'
type State = { [key in 'algolia' | 'config']?: AlgoliaState }
// Redux should store everything in ALGOLIA,
// but browse has NOT been updated so check CONFIG for browse
export const getAlgolia = (state: State): AlgoliaState => state.algolia || state.config || {}
export const getIndice = createSelector(
  [getAlgolia],
  (algolia: AlgoliaState): string => algolia.searchIndice || 'unknown_indice'
)
export const getUrlFacets = createSelector(
  [getAlgolia],
  (algolia: AlgoliaState): Array<string> => algolia.urlFacets || []
)
export const getAlgoliaResults = createSelector(
  [getAlgolia],
  (algolia: AlgoliaState): AlgoliaResults => algolia.algoliaResults || {}
)