const { ALGOLIA_APPLICATION_ID, ALGOLIA_ENV, ALGOLIA_SEARCH_API, APP_ENV } = process.env
export const applicationId: string = ALGOLIA_APPLICATION_ID || ''
export const searchApi: string = ALGOLIA_SEARCH_API || ''
const algoliaEnv: string = ALGOLIA_ENV || APP_ENV || 'production'
export const searchIndice: string = `${algoliaEnv}_all_artworks_artist_order`
export const DEFAULT_APP = '/user/artworks'
export const DEFAULT_URL = ''
// Will add additional sort options in part 2.
export const sortOptions: Array<{
  value: string
  title: string
  indice: string
}> = [
  {
    value: 'default',
    title: 'Default',
    indice: searchIndice,
  },
]
export const DESKTOP_COLUMN_WIDTH = 320 // max width of Artwork Data

export const DESKTOP_COLUMN_GAP = 30 // max width of Artwork Data

export const MOBILE_COLUMN_WIDTH = 140 // 2 Column Experience Mobile

export const MOBILE_COLUMN_GAP = 0 // 2 Column Experience Mobile

export const SPAN_HEIGHT = 25 // grid-auto-rows: 25px;