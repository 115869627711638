import React from 'react'
import SALink from 'components/SALink'

const Component = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { handleViewMore, idUser, isSeoValid, originalStatus } = props

  if (originalStatus !== 'reserved' && originalStatus !== 'sold') {
    return null
  }

  return (
    <SALink
      alt={`/account/artworks/${idUser}`}
      data-type='view-more'
      href={`/account/artworks/${idUser}`}
      onClick={handleViewMore}
      rel={isSeoValid ? null : 'nofollow'}
      title='View more works'
    >
      View more works
    </SALink>
  )
}

export default Component