import fetch from 'isomorphic-fetch'
import type { PromisePayload } from 'apis/helpers'
import { getOptions, postOptions, parseJSON } from 'apis/helpers'
import type { MeasurementUnit } from 'types/User/Session'
import 'types/User/Session'
// To Deal with Akamai we need to fetch SESSION from CLIENTSIDE
export const fetchSession = async (): Promise<PromisePayload> => {
  const response = await fetch(`/easel_api/session`, getOptions)
  const json = await parseJSON(response, 'fetchSession')
  return json
}
// TODO - Stubbing API
export async function updateUserMeasurementUnit(
  measurementUnit: MeasurementUnit
): Promise<PromisePayload> {
  const response = await fetch(`/easel_api/my-preferred-measurement`, {
    ...postOptions,
    body: JSON.stringify(measurementUnit),
  })
  const json = await parseJSON(response, 'updateUserMeasurementUnit')
  return json
}