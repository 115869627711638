import React from 'react'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import { LocaleCurrency } from 'components/FormatPrice'
import ArtworkInfo from './Artwork'
import ArtistInfo from './Artist'
import { getArtworkProps, getArtistProps } from './helpers'
import { Item } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (
  props: Record<string, any> & GeoLocationProps
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    shouldOpenNewDesktopPdpTab,
    hasLimitedEditions,
    hasOpenEditions,
    minPrintPrice,
    originalStatus,
    listPrice,
  } = props
  const artworkProps = getArtworkProps(props)
  const artistProps = getArtistProps(props)
  return (
    <Item data-type='print'>
      <div data-style='row' data-type='info'>
        <ArtworkInfo {...artworkProps} shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab} />
      </div>

      <div data-style='row' data-type='info'>
        <ArtistInfo {...artistProps} />

        <div data-style='column' data-type='prices'>
          {hasOpenEditions && (
            <SATypography data-type='main' truncateText variant={SATypographyVariant.H6}>
              Prints from <LocaleCurrency price={convertFromCentsToDollars(minPrintPrice)} />
            </SATypography>
          )}
          {hasLimitedEditions && (
            <SATypography data-type='main' truncateText variant={SATypographyVariant.H6}>
              Prints from <LocaleCurrency price={convertFromCentsToDollars(minPrintPrice)} />
            </SATypography>
          )}

          {originalStatus !== 'unavail' && (
            <SATypography data-type='sub' truncateText variant={SATypographyVariant.X_SMALL}>
              Original <LocaleCurrency price={convertFromCentsToDollars(listPrice)} />
            </SATypography>
          )}
        </div>
      </div>
    </Item>
  )
}

export default Component