import React from 'react'
import SALink from 'components/SALink'
import TriangleLeft from 'svg/triangle-left.svg'
import type { ConnectorProps as HeaderProps } from '../../connectors/Header'
import HeaderConnector from '../../connectors/Header'
import Actions from './Actions'
import { ArtistInfoSection } from './styles'
export default HeaderConnector(
  (props: HeaderProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { avatarUrl, artistName, artistId } = props
    return (
      <ArtistInfoSection>
        <div data-type='artist-row'>
          <SALink href={`/account/profile/${artistId}`} title={`View profile of ${artistName}`}>
            <img
              data-type='artist-avatar'
              src={
                avatarUrl ||
                '//d3t95n9c6zzriw.cloudfront.net/common/profile/avatar_medium_square.jpg'
              }
              alt={artistName}
            />
          </SALink>
          <div data-type='artist-column'>
            <h1 data-type='artist-name'>{artistName} Artworks</h1>
            <SALink data-type='profile-link' href={`/account/profile/${artistId}`}>
              <TriangleLeft />
              Back to profile
            </SALink>
          </div>
          <Actions />
        </div>
      </ArtistInfoSection>
    )
  }
)