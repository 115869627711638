import { observerEpicHelper } from 'epics/helpers'
import { fetchCartCount } from 'apis/cart'
import { Actions, Constants } from 'actions/user/cart'
export const fetchCartCountEpic = observerEpicHelper(
  [Constants.GET_CART],
  async ({ action, observer, store }): Promise<void> => {
    const count = await fetchCartCount()
    observer.next(Actions.SET_CART(count))
    observer.complete()
  }
)
export default [fetchCartCountEpic]