import React from 'react'
import Select from 'components/Select'
import type { ConnectorProps as AlgoliaConnectorProps } from '../connectors/Algolia'
import AlgoliaConnector from '../connectors/Algolia'
const options = [25, 50, 100]
export default AlgoliaConnector(
  (props: AlgoliaConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { hitsPerPage, updateHitsPerPage } = props
    return (
      <Select
        onChange={(value: number) => {
          updateHitsPerPage(value)
        }}
        value={hitsPerPage}
        aria-label='Results per page'
      >
        {options.map((num) => (
          <option key={num} value={num}>
            {num} Results Per Page
          </option>
        ))}
      </Select>
    )
  }
)