import { createSelector } from 'reselect'
import { getPageData, getPageParams } from 'selectors/page'
import { getSort, getHitsPerPage, getPage, getSearch } from 'selectors/algolia2/url'
import {
  getAlgoliaDataPaginationCurrent,
  getAlgoliaDataPaginationTotal,
} from 'selectors/algolia2/data'
import {
  getIsAdmin,
  getUserID,
  isAuthenticated as getIsAuthenticated,
} from 'selectors/user/session'
import { getArtistName, getAvatarUrl } from './header'
import { getHitsAvailableForSaleCount } from './algolia'
// This is the order of params from Express Router
// Example: `:category/:subject/:styles`
export const getPathParams = createSelector(
  getPageData,
  (pageData): Array<string> => {
    return pageData.pathParams || []
  }
)
// Special Query Fields we will USE
export const getDimensionQuery = createSelector(
  [getPageParams],
  ({ width, height }): Record<string, any> => ({
    ...(width
      ? {
          width,
        }
      : {}),
    ...(height
      ? {
          height,
        }
      : {}),
  })
)
export const getPageParamsQuery = createSelector(
  [getSort, getHitsPerPage, getPage, getSearch, getDimensionQuery],
  (sort, hitsPerPage, page, query, diminsions): Record<string, any> => ({
    ...(sort !== 'default'
      ? {
          sort,
        }
      : {}),
    ...(hitsPerPage !== 25
      ? {
          hitsPerPage,
        }
      : {}),
    ...(page !== 0
      ? {
          page: page + 1,
        }
      : {}),
    ...(query
      ? {
          query,
        }
      : {
          query: '',
        }),
    ...diminsions,
  })
)
export { getIsAdmin } from 'selectors/user/session'
export { getPageIsReady, getPageData, getPageParams, getPagePath } from 'selectors/page'
export const getArtistId = createSelector([getPageData], (pageData): number => pageData.userId || 0)
export const getArtistCountry = createSelector(
  [getPageData],
  (pageData): number => pageData.country || ''
)

/* export const getSearch = createSelector(
  getPageParams,
  (pageParams): Object => pageParams.search || ''
) */
export const areFiltersAvailable = createSelector(
  [getPageData],
  (pageData): boolean => pageData.filtersAvailable || false
)
export const getIsUsersCollection = createSelector(
  [getPageParams, getUserID, getIsAuthenticated],
  (params, userId, isAuthenticated): boolean =>
    (isAuthenticated && Number(userId) === Number(params.ownerId || 0)) || false
)
export const getIsUsersArtworksAccount = createSelector(
  [getPageParams, getUserID, getIsAuthenticated],
  (params, userId, isAuthenticated): boolean =>
    (isAuthenticated && Number(userId) === Number(params.userId || 0)) || false
)
// Private Collection, we do NOT return algolia results from SSR
export const getHasInitialLoad = createSelector(
  [getPageData],
  (pageData): boolean => pageData.algoliaResults || false
)
export const getHasValidPermissions = createSelector(
  [getIsUsersArtworksAccount, getIsAdmin],
  (isUsersArtworksAccount, isAdmin): boolean => isUsersArtworksAccount || isAdmin || false
)
export const getBreadcrumbSchema = createSelector([], () => {
  return {
    '@context': 'http://schema.org',
    type: 'BreadcrumbList',
    itemListElement: [
      {
        type: 'ListItem',
        position: 1,
        item: {
          '@id': 'https://www.saatchiart.com/all',
          name: 'All Artworks',
        },
      },
      {
        type: 'ListItem',
        position: 2,
        item: {
          '@id': 'https://www.qa.saatchiart.com/account-artworks',
          name: 'Account/Artworks',
        },
      },
    ],
  }
})
// {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[{"type":"ListItem","position":1,"item":{"@id":"https://www.qa.saatchiart.com","name":"Home"}},{"type":"ListItem","position":2,"item":{"@id":"https://www.qa.saatchiart.com/account-artworks","name":"Account/Artworks"}}]}
export const getMetaData = createSelector(
  [
    getArtistCountry,
    getArtistName,
    getBreadcrumbSchema,
    getHitsAvailableForSaleCount,
    getAvatarUrl,
    getAlgoliaDataPaginationCurrent,
    getAlgoliaDataPaginationTotal,
  ],
  (
    artistCountry,
    artistName,
    breadcrumb,
    availableForSaleCount,
    avatarURL,
    currentPage,
    totalPages
  ): Record<string, any> => {
    const description = `View ${artistName}'s artworks on Saatchi Art. Find original art for sale at great prices, including Paintings, Sculptures, Photography, Drawings, and Art Prints from emerging artists like ${artistName}`
    const title = `${artistName} Artworks | Saatchi Art`
    return {
      description,
      title,
      og: {
        description,
        image: avatarURL,
        title,
        type: 'article',
      },
      page: {
        current: currentPage,
        prev: currentPage === 2 || currentPage > 2,
        next: currentPage !== totalPages,
      },
      preloadImages: [],
      robots: availableForSaleCount,
      // if PRODUCTS for Sale
      schema: {
        breadcrumb,
        webpage: {
          description,
          headline: title,
        },
      },
    }
  }
)