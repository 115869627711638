import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { space } from 'styled-system'
import { desaturate } from 'polished'
import SALink from 'components/SALink'
import { Collapsable } from 'components/Animations/styles'
import { InputWithPlaceholder, Input as InputInPlaceholder } from 'components/Input/styles'
import { Search } from 'components/Search/styles'
import Tick from 'svg/tick.svg'
import { Column, Row } from '../account'
import { CloseIcon, PlusIcon, MinusIcon } from './icons'
export { CloseIcon, PlusIcon, MinusIcon }
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.default};
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  min-width: 100px;
  min-height: 46px;
  padding: 5px 15px;
  cursor: pointer;
  transition: 0.2s ease color, 0.2s ease-in-out background;
  outline: none;
  &:active {
    outline: none;
  }
`
// Bug: `fieldset` will not work as FLEX container in CHROME
export const Field = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  ${InputWithPlaceholder} {
  }
  ${InputInPlaceholder} {
  }
`
export const Input = styled.input`
  width: 85px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: ${(props) => `${props.theme.space[2]}px ${props.theme.space[2]}px`};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.borderBlue};
    ${(props) => props.error && `border-color: ${props.theme.colors.red}`};
  }
`
export const PriceInputs = styled(Row)`
  margin-bottom: 15px;
  width: 190px;
  ${InputWithPlaceholder} {
    width: 85px;
    display: inline-block;
    margin-right: 5px;
    input {
      width: 85px;
      height: 50px;
    }
    + ${InputWithPlaceholder} {
      margin-left: 5px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
    justify-content: flex-start;
  }
`
export const SizeInputs = styled(Column)`
  margin-top: 15px;
  width: 230px;
  ${InputWithPlaceholder} {
    width: 85px;
    display: inline-block;
    margin-right: 5px;
    input {
      width: 85px;
      height: 50px;
    }
    + ${InputWithPlaceholder} {
      margin-left: 5px;
    }
  }
  ${Field} {
    margin-bottom: 10px;
  }
`
export const FacetNameHeader = styled(Row)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.default};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 16px;
    background: #f3f3f3;
    margin-bottom: 15px;
  }
`
export const FacetLink = styled(SALink)`
  cursor: pointer;
  margin: 0 0 5px 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.default};
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  /* Checkbox */
  label {
      outline: none;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 5px 0;
    }
    input + span + p {
      margin: 0 0 0 10px;
    }
  }
  &:focus {
    outline: none;
  }
  ${(props) => props.isHidden && `display: none`};
  &[data-type='price'] {
    p {
      color: ${(props) => props.theme.colors.lightGray};
    }
    &[data-selected] {
      p {
        color: ${(props) => props.theme.colors.default};
      }
    }
  }
`
const Link = styled(SALink)`
  color: ${(props) => props.theme.colors.lightGray};
  padding: 10px 0;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  will-change: color;
  cursor: pointer;
  font-style: normal;
  transition: color 0.2s ease 0s;
  text-decoration: underline;
  ${space};
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`
export const ShowLink = Link
export const ViewToggle = styled(Link)`
  order: 2;
  margin: 50px 0 100px;
  text-transform: initial;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.5em;
  font-weight: normal;
  text-decoration: none;
  svg {
    margin-left: 5px;
    path {
      transition: stroke 0.2s ease 0s;
    }
  }
  &:hover {
    svg path {
      stroke: ${(props) => props.theme.colors.black};
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
    display: flex;
    padding: 0 15px;
    border-top: none;
    align-items: center;
    svg path {
      stroke: ${(props) => props.theme.colors.black};
    }
  }
`
export const SeeArtSection = styled(Column)`
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
  padding: 0 15px;
  margin-bottom: 100px;
  /*

   -- Cant Resolve - HIDE STYLING for STICKY MOBILE --
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  box-shadow: rgba(0, 0, 0, 0.2) 0px -5px 15px;
  background: ${(props) => props.theme.colors.white};
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(100vh);
  transition: transform 0.35s ease 0s, opacity 0.1s ease 0s;
  ${(props) =>
    props.isVisible &&
    `
    display: block;
    transform: translateY(0px);
    margin-left: 0;
    transform: translateY(calc(100vh - 80px));
  `};
  */

  /* Button */
  p {
    font-size: 16px;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.default};
    border: none;
    &:hover {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.black};
      border: none;
    }
  }
`
export const FacetActions = styled(Row)`
  order: 1;
  ${CloseIcon} {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
    [data-type='clearAll'] {
      flex: 1;
    }
    ${CloseIcon} {
      display: block;
      margin: 0 0 0 20px;
    }
  }
`
export const FacetsLayout = styled(Column)`
  width: 25%;
  min-width: 250px;
  max-width: 250px;
  margin-right: 30px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};

  hr {
    margin-top: 5px;
    background: ${(props) => props.theme.colors.borderLight};
  }

  ${Search} {
    margin-bottom: 15px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      padding: 10px;
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    position: fixed;
    z-index: 10;
    background: ${(props) => props.theme.colors.white};
    width: 100%;
    height: 100%;
    max-height: calc(100vh - ${(props) => props.offset}px);
    top: ${(props) => props.offset}px;
    left: 0px;
    padding: 0;
    max-width: initial;
    overflow-y: auto;
    transform: translateY(100vh);
    transition: transform 0.35s ease 0s, opacity 0.1s ease 0s;
    ${(props) =>
      props.isVisible &&
      `
      display: block;
      transform: translateY(0px);
    `};
    ${Collapsable} > div {
      padding: 0 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      > ${FacetLink} {
        &:nth-child(even) {
          padding-left: 8px;
        }
        &:nth-child(odd) {
          padding-right: 8px;
        }
      }
    }
    ${FacetLink} {
      width: 50%;
      label {
        padding: 15px;
        border: 1px solid ${(props) => props.theme.colors.borderLight};
        color: ${(props) => props.theme.colors.lightGray};
        justify-content: center;
        span {
          display: none;
        }
        p {
          color: ${(props) => props.theme.colors.lightGray};
          text-align: center;
        }
      }
      &[data-selected] {
        label {
          border: 1px solid ${(props) => props.theme.colors.lightGray};
          color: ${(props) => props.theme.colors.default};
          p {
            color: ${(props) => props.theme.colors.default};
          }
        }
      }
      &[data-type='price'] {
        p {
          padding: 15px;
          border: 1px solid ${(props) => props.theme.colors.borderLight};
          color: ${(props) => props.theme.colors.lightGray};
          text-align: center;
        }
        &[data-selected] p {
          color: ${(props) => props.theme.colors.default};
          border: 1px solid ${(props) => props.theme.colors.lightGray};
        }
      }
    }
    ${ShowLink} {
      width: 100%;
    }
  }
  &[disabled] {
    pointer-events: none;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${(props) => props.theme.colors.white};
      opacity: 0.7;
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      position: relative;
    }
  }
`
export const ColorsRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
export const ColorsColumn = styled(Column)`
  justify-content: space-between;
  align-items: center;
`
export const Color = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  ${space};
  border-radius: 100%;
  border: 1px solid ${(props) => (props.available ? props.value : 'transparent')};
  background: ${(props) => (props.available ? props.value : desaturate(0.8, props.value))};
  pointer-events: ${(props) => (props.available ? 'inherit' : 'none')};
`
export const ColorDesktop = styled(Color)`
  margin: 0 2px 2px 0;
  transition: transform 0.2s, border 0.2s;
  &:hover {
    transform: scale(3);
    border: 1px solid ${(props) => props.theme.colors.white};
  }
`
export const ColorIcon = styled(Tick)`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 0.2s easeIn opacity;
  font-size: 8px;
  width: 8px;
  height: 8px;
  line-height: 1;
  fill: ${(props) => (props.dark ? props.theme.colors.lightGray : props.theme.colors.white)};
`
export const ToafList = styled(Column)`
  margin: 7px 0 0 7px;
  a {
    margin: 0;
  }
`
export const NoOptions = styled.p`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  padding: 0;
  margin: 0;
`