import { createSelector } from 'reselect'
import { getSharedFacetNames } from 'selectors/algolia2/data'
import { getAlgoliaResults } from './algolia'
import { getFirstFacetValue } from '../helpers/index'
import { getPathParams, getPageParamsQuery } from './app'

/*
AllFacets - Algolia non filtered facet results ( all possible facets )
AlgoliaFacets - Algolia facets available after a query
SelectedFacets - Current Facets selected, used for building URL and Tracking changes
*/
export const getAlgolia = (state: Record<string, any>): Record<string, any> => state.algolia || {}
export const getState = createSelector(
  (state: Record<string, any>) => ({ ...state }),
  (state): Record<string, any> => state
)
export const getAllFacets = createSelector(
  getAlgolia,
  (pageData): Record<string, any> => pageData.allFacets || {}
)
export const getFacetNames = createSelector(
  getAllFacets,
  (facets): Array<string> => Object.keys(facets) || []
)
export const getSlugifyDictionary = createSelector(
  getAlgolia,
  (pageData): Record<string, any> => pageData.slugifyDictionary || {}
)
export const getAlgoliaFacets = createSelector(
  getAlgoliaResults,
  (algoliaResults): Record<string, any> => algoliaResults.facets || {}
)
// We will keep track of what Algolia Facets we had previously
export const getPreviousAlgoliaFacets = createSelector(
  getAlgolia,
  (pageData): Record<string, any> => pageData.previousAlgoliaFacets || {}
)
export const getPreviousAlgoliaFacetsAttributes = (facetName: string) =>
  createSelector(getPreviousAlgoliaFacets, (facets): Record<string, any> => facets[facetName] || {})
export const getPreviousAlgoliaFacetsAttributesList = (facetName: string) =>
  createSelector(
    [getPreviousAlgoliaFacetsAttributes(facetName)],
    (attributes): Array<string> => Object.keys(attributes) || []
  )
export const isFacetNameAvailable = (facetName: string) =>
  createSelector(getFacetNames, (facetNames): boolean => facetNames.includes(facetName))
export const getFacetNameAttributes = (facetName: string) =>
  createSelector(getAlgoliaFacets, (facets): Record<string, any> => facets[facetName] || {})
export const getFacetNameAttributesList = (facetName: string) =>
  createSelector(
    [getFacetNameAttributes(facetName)],
    (attributes): Array<string> => Object.keys(attributes) || []
  )
export const getAvailableFacetNameAttributesList = (facetName: string) =>
  createSelector(
    [getFacetNameAttributesList(facetName), getPreviousAlgoliaFacetsAttributesList(facetName)],
    (attributes, previous): Array<string> => {
      // merging current and previous facets, using `set` to remove duplicates
      const facetArray = [...attributes, ...previous]
      return Array.from(new Set(facetArray)) || []
    }
  )
export const getSelectedFacets = createSelector(
  getAlgolia,
  (pageData): Record<string, any> => {
    return pageData.selectedFacets || {}
  }
)
export const getSelectedFacetsSizeMinMax = createSelector(
  [getSelectedFacets],
  (selectedFacets): Record<string, any> => {
    const [widthMin, widthMax] = (selectedFacets.width || '').split('-')
    const [heightMin, heightMax] = (selectedFacets.height || '').split('-')
    return {
      widthMin: widthMax ? Number(widthMin || '') : '',
      widthMax: Number(widthMax || ''),
      heightMin: heightMax ? Number(heightMin || '') : '',
      heightMax: Number(heightMax || ''),
    }
  }
)
export const getResetSelectedFacets = createSelector(
  getSelectedFacets,
  (selectedFacets): Record<string, any> =>
    Object.keys(selectedFacets).reduce((data, facetName) => {
      data[facetName] = []
      return data
    }, {})
)
export const getSelectedFacetsCount = createSelector(
  getSelectedFacets,
  (selectedFacets): number =>
    Object.entries(selectedFacets).reduce((count, [facetName, attributes]) => {
      // Only track count for these FACETS
      if (facetName.search(/category|original_status|size|price|orientation/) > -1) {
        let itemCount = 0

        if (Array.isArray(attributes)) {
          itemCount = attributes.length
        } else if (attributes) {
          itemCount = 1
        }

        return count + itemCount
      }

      return count
    }, 0) || 0
)
export const getSelectedFacetNameAttributes = (facetName: string) =>
  createSelector(
    getSelectedFacets,
    (selectedFacets): Array<string> => {
      const currentValues = selectedFacets[facetName] || []
      // We always want to work with an ARRAY to FILTER
      return Array.isArray(currentValues) ? currentValues : [currentValues]
    }
  )
export const getSelectedFacetsCountCountByFacetName = (facetName: string) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName || '')],
    (facetAttributes): number => facetAttributes.length || 0
  )
// We want to know onLoad if selected FACET so we can open up sections
export const isSelectedFacetName = ({ facetName }: Record<string, any>) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName || '')],
    (facetAttributes): boolean => facetAttributes.length || false
  )
export const isSelectedFacetValue = ({ facetName, attributeName }: Record<string, any>) =>
  createSelector([getSelectedFacetNameAttributes(facetName || '')], (facetAttributes): boolean => {
    return facetAttributes.includes(attributeName || '') || false
  })
export const getSelectedFacetNameFirstAttribute = (facetName: string) =>
  createSelector([getSelectedFacetNameAttributes(facetName)], (facetAttributes): string =>
    getFirstFacetValue(facetName, facetAttributes)
  )
export const getSelectedFacetsPrice = createSelector(
  getSelectedFacets,
  (selectedFacets): string => selectedFacets.price || ''
)
// Used to Know whether a Facet should be added or removed
export const doesSelectedFacetNameAttributeExist = (facetName: string, attributeValue: string) =>
  createSelector(
    getSelectedFacetNameAttributes(facetName),
    (facetAttributes): boolean => facetAttributes.includes(attributeValue) || false
  )
export const updateFacetValues = (facetName: string, attributeValue: string) =>
  createSelector(
    getSelectedFacetNameAttributes(facetName),
    doesSelectedFacetNameAttributeExist(facetName, attributeValue),
    (selectedFacetNameValues, facetNameAttributeExist): Array<string> =>
      facetNameAttributeExist && Array.isArray(selectedFacetNameValues)
        ? [...selectedFacetNameValues.filter((attr) => attr !== attributeValue)] // REMOVE AttributeValue
        : [...selectedFacetNameValues, attributeValue] // ADD AttributeValue}
  )
// We gather all DATA needed to create the URL using the HELPER => generateLocationUrl({ queryParams, pathParams, sharedFacetNames })
export const getParamsToGenerateURL = createSelector(
  [getSelectedFacets, getPageParamsQuery, getPathParams, getSharedFacetNames],
  (selectedFacets, pageParams, pathParams, sharedFacetNames): Record<string, any> => {
    return {
      queryParams: { ...selectedFacets, ...pageParams },
      pathParams,
      sharedFacetNames,
    }
  }
)
export const hasPrintsSelected = createSelector(
  [getSelectedFacetNameFirstAttribute('has_prints')],
  (hasPrints): boolean => !!hasPrints
)