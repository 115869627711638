import React from 'react'
import Checkbox from 'components/Form/Checkbox'
import { FacetLink } from 'styles/algolia/facets'
import type { ConnectorProps as CheckboxItemProps } from '../../connectors/CheckboxItem'
import CheckboxItemConnector from '../../connectors/CheckboxItem'
type Props = {
  title?: string
  // Override Option for VALUE( attributeName )
  facetName: string
  attributeName: string
  isHidden: boolean
} & CheckboxItemProps
export default CheckboxItemConnector(
  (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
    const {
      attributeName,
      facetName,
      isHidden,
      isSelected,
      title,
      urlWithParams,
      updateFacetValues,
      updateUrl,
    } = props
    const href = urlWithParams({
      [facetName]: updateFacetValues(facetName, attributeName),
      page: '', // Reset Page
    })
    return (
      <FacetLink
        role='button'
        tabIndex='0'
        href={href}
        onClick={(ev) => {
          ev.preventDefault()
          updateUrl(href)
        }}
        {...(isHidden
          ? {
              isHidden,
            }
          : {})}
        {...(isSelected
          ? {
              'data-selected': isSelected,
            }
          : {})}
        data-gtm-event='filter'
        data-gtm-info={`${facetName} - ${attributeName}`}
      >
        <Checkbox checked={isSelected}>
          <p>{title || attributeName}</p>
        </Checkbox>
      </FacetLink>
    )
  }
)