import { observerEpicHelper } from 'epics/helpers'
import { Constants } from 'actions/shared/instana'
import { getSession } from 'selectors/user/session'
declare let ineum: (...args: Array<any>) => any
export const instanaUserSetupEpic = observerEpicHelper(
  [Constants.INSTANA_USER_SETUP],
  async ({ action, observer, store }): Promise<void> => {
    if (typeof window === 'undefined' || !window.ineum) {
      return observer.complete()
    }

    const { email, isAuthenticated, userId, guestId, firstName, lastName } = getSession(store.value)

    if (isAuthenticated) {
      window.ineum('user', `'${userId}', '${firstName} ${lastName}', '${email}'`)
    } else {
      window.ineum('user', `'${guestId}', 'Anonymous', 'Anonymous'`)
    }

    return observer.complete()
  }
)
export default [instanaUserSetupEpic]