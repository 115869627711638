//@ts-nocheck
import { createSelector } from 'reselect'
import {
  getAlgolia,
  getAlgoliaDataFacets,
  getAlgoliaDataHitsResults,
  getAllFacets,
  getSelectedFacets,
} from './data'
import { getFirstFacetValue } from './helpers'

/*
 *AllFacets - Algolia non filtered facet results ( all possible facets )
 *AlgoliaDataFacets - Algolia facets available after a query
 *SelectedFacets - Current Facets selected, used for building URL and Tracking changes
 */
export { getAllFacets, getAlgoliaDataFacets, getSelectedFacets }
export const getAlgoliaFacets = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.facets || {}
)
// We will keep track of what Facets have changed
export const getLastChangedFacets = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.changedFacets || {}
)
// We will keep track of what Algolia Facets we had previously
export const getPreviousAlgoliaFacets = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.previousAlgoliaFacets || {}
)
export const getPreviousAlgoliaFacetsAttributes = (facetName: string) =>
  createSelector(
    [getPreviousAlgoliaFacets],
    (facets): Record<string, any> => facets[facetName] || {}
  )
export const getPreviousAlgoliaFacetsAttributesList = (facetName: string) =>
  createSelector(
    [getPreviousAlgoliaFacetsAttributes(facetName)],
    (attributes): Array<string> => Object.keys(attributes) || []
  )
// This is the order of params from Express Router
// Example: `:category/:subject/:styles`
export const getUrlPathParams = createSelector(
  [getAlgolia],
  (algolia): Array<string> => algolia.pathParams || []
)
export const getAlgoliaDataFacetsNames = createSelector(
  [getAlgoliaDataFacets],
  (facets): Array<string> => Object.keys(facets) || []
)
export const getAlgoliaDataFacetsNamesReset = createSelector(
  [getAlgoliaDataFacetsNames],
  (facetNames): Record<string, any> =>
    facetNames.reduce((data, facetName) => ({ ...data, [facetName]: [] }), {})
)
export const getSharedFacetNames = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.sharedFacetNames || {}
)
export const getSlugifyDictionary = createSelector(
  [getAlgolia],
  (algolia): Record<string, any> => algolia.slugifyDictionary || {}
)
export const getAlgoliaDataHitsResultsFacets = createSelector(
  [getAlgoliaDataHitsResults],
  (algoliaResults): Record<string, any> => algoliaResults.facets || {}
)
export const isFacetNameAvailable = (facetName: string) =>
  createSelector([getAlgoliaDataFacetsNames], (facetNames): boolean =>
    facetNames.includes(facetName)
  )
export const getFacetNameAttributes = (facetName: string) =>
  createSelector([getAlgoliaDataFacets], (facets): Record<string, any> => facets[facetName] || {})
export const getFacetNameAttributesList = (facetName: string) =>
  createSelector(
    [getFacetNameAttributes(facetName)],
    (attributes): Array<string> => Object.keys(attributes) || []
  )
export const getAvailableFacetNameAttributesList = (facetName: string) =>
  createSelector(
    [getFacetNameAttributesList(facetName), getPreviousAlgoliaFacetsAttributesList(facetName)],
    (attributes, previous): Array<string> => {
      // merging current and previous facets, using `set` to remove duplicates
      const facetArray = [...attributes, ...previous]
      return Array.from(new Set(facetArray)) || []
    }
  )
export const getSelectedFacetsCount = createSelector(
  [getSelectedFacets],
  (selectedFacets): number =>
    Object.entries(selectedFacets).reduce(
      (count, [facetName, attributes]) =>
        count +
        (Array.isArray(attributes) && facetName.search(/has_prints/i) === -1
          ? attributes.length
          : 0),
      0
    ) || 0
)
export const getSelectedFacetNameAttributes = (facetName: string) =>
  createSelector(
    [getSelectedFacets],
    (selectedFacets): Array<string> => {
      const currentValues = selectedFacets[facetName] || []
      // We always want to work with an ARRAY to FILTER
      return Array.isArray(currentValues) ? currentValues : [currentValues]
    }
  )
// We want to know onLoad if selected FACET so we can open up sections
export const isSelectedFacetName = ({ facetName }: Record<string, any>) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName || '')],
    (facetAttributes): boolean => facetAttributes.length || false
  )
export const isSelectedFacetValue = ({ facetName, attributeName }: Record<string, any>) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName || '')],
    (facetAttributes): boolean => facetAttributes.includes(attributeName || '') || false
  )
export const getSelectedFacetNameFirstAttribute = (facetName: string) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName), getSharedFacetNames],
    (facetAttributes, sharedFacetNames): string =>
      getFirstFacetValue(facetName, facetAttributes, {
        sharedFacetNames,
      })
  )
// Used to Know whether a Facet should be added or removed
export const doesSelectedFacetNameAttributeExist = (facetName: string, attributeValue: string) =>
  createSelector(
    [getSelectedFacetNameAttributes(facetName)],
    (facetAttributes): boolean => facetAttributes.includes(attributeValue) || false
  )
export const updateFacetValues = (facetName: string, attributeValue: string) =>
  createSelector(
    [
      getSelectedFacetNameAttributes(facetName),
      doesSelectedFacetNameAttributeExist(facetName, attributeValue),
    ],
    (selectedFacetNameValues, facetNameAttributeExist): Array<string> =>
      facetNameAttributeExist && Array.isArray(selectedFacetNameValues)
        ? [...selectedFacetNameValues.filter((attr) => attr !== attributeValue)] // REMOVE AttributeValue
        : [...selectedFacetNameValues, attributeValue] // ADD AttributeValue}
  )
