import { capitalizeString } from 'lib/helpers'
import * as Constants from './constants'
export const getFirstFacetValue = (
  facetName: string,
  facetValues: Array<string>,
  options: Record<string, any>
): string => {
  const [facetValue] = facetValues || [] // Get First Item/Value

  if (facetName === 'category') {
    return facetValue || 'All'
  }

  return facetValue || ''
}
// Remove Excess Whitespace
// ' Abstract   Art From United States    For Sale  ' => 'Abstract Art From United States For Sale'
export const stringCleanup = (value: string): string => value.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
export const prettyCategory = (category: string | null | undefined): string => {
  if (!category) {
    return 'Art'
  }

  return category
    .replace(/^Mixed Media$/i, 'Mixed Media Art')
    .replace(/^Printmaking$/i, 'Art Prints')
    .replace(/^Digital$/i, 'Digital Art')
    .replace(/^Installation$/i, 'Art Installations')
    .replace(/^all$/i, 'Art')
}
export const updateDataLayer = (gtmEventData: Record<string, any>): void => {
  window.dataLayer.push({ ...gtmEventData })
}
export default { ...Constants, stringCleanup, prettyCategory, capitalizeString }