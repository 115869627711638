import { observerEpicHelper, checkAuthorization } from 'epics/helpers'
import {
  fetchFollowApi, // updateFollowApi,
  updateUserFollowApi,
} from 'apis/follow'
import { Constants, Actions } from 'actions/user/follow'
export const fetchFollowEpic = observerEpicHelper(
  [Constants.FETCH],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value

    if (checkAuthorization(state)) {
      observer.next(Actions.FETCH_COMPLETE(await fetchFollowApi()))
    }

    observer.complete()
  }
)
export const updateFollowEpic = observerEpicHelper(
  [Constants.UPDATE],
  async ({ action, observer, store }): Promise<void> => {
    const state = store.value
    window.dataLayer.push({
      event: action.payload.following ? 'follow' : 'unfollow',
    })

    if (checkAuthorization(state)) {
      updateUserFollowApi({ ...action.payload }).then((resp, err) => {
        window.dataLayer.push({
          event: action.payload.following ? 'follow success' : 'unfollow success',
        })
        observer.complete()
      })
    }
  }
)
export default [fetchFollowEpic, updateFollowEpic]